import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./Configuration.scss";
import MaterialTable from "material-table";
import { tableIcons } from "../../Utilities/utility";
import { AddBox } from "@material-ui/icons";
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useTheme,
    TextField,
    IconButton,
    Tooltip,
} from "@material-ui/core";
// import SystemInfeedForm from './SystemInfeedForm';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import axios from "axios";
import * as myConstClass from "../Formula/Constants";
import * as myFunctions from "../Formula/Formula";
import SnackbarCustom from "../Components/Snackbar/Snackbar";
import {
    dolTableDataAction,
    efficiencyTypeInputOptions,
    matTableDataAction,
    matTableDataToDisplayAction,
    motorRatingInputOptions,
    rdolTableDataAction,
    starDeltaTableDataAction,
} from "../../Redux/Actions/actions";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { InfoIconDialog } from "./InfoIconDialog";
import CloseIcon from "@material-ui/icons/Close";
import { Edit as EditIcon, Add as AddIcon } from "@material-ui/icons";
import Loading from "../../Pages/Components/Loading/Loading";

const useStyles = makeStyles(() => ({
    snackbar: {
        bottom: "100px",
    },
}));

export function Configuration() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    let role = userDetails.roleId;
    const officeNameval = useSelector(
        (state) => state.salesOfficeInputReducer.salesOffice
    );
    const createdDate = useSelector(
        (state) => state.salesOfficeInputReducer.date
    );
    const projectNameVal = useSelector(
        (state) => state.projectNameReducer.projectName
    );
    const custNameVal = useSelector(
        (state) => state.customerNameReducer.customerName
    );
    const endCustVal = useSelector(
        (state) => state.endCustomerReducer.endCustomer
    );
    // const voltageVal = useSelector((state) => state.voltageDropdownReducer.voltage);
    const frequencyVal = useSelector(
        (state) => state.frequencyDropdownReducer.frequency
    );
    const faultLevelVal = useSelector(
        (state) => state.faultLevelDropdownReducer.faultLevel
    );
    const canShowAdminInfo = useSelector((state) => state.showAdminInfoReducer);

    const draftActiveData = useSelector((state) => state.draftActiveStepReducer);

    // check
    var [mlfb, setMlfb] = useState(1); //for 3RV
    const api = axios.create({
        baseURL: "SystemInfeed",
    });

    const [loading, setLoading] = useState(false);
    //const [generatedSystemInfeedID, setGeneratedSystemInfeedID] = useState(0);
    var generatedSystemInfeedID = 0;
    const [enquiryType, setEnquiryType] = useState("");

    useEffect(() => {
        getMotorRating();
        getEfficiencyType();
        getStartingMethod();
        getTypeOfCoordination();
        getFuseType();
        getScpdFuseProduct();
        getScpdFuselessProduct();
        getContactor();
        getOverloadRelayProduct();

        console.log("tableDataVal", tableDataVal);
        console.log("userDetails", userDetails);
    }, []);

    // useEffect(() => {
    //   calculateDOL(motorFLC, motorLRR);
    // }, [enquiryType]);

    function motorRatingValueConverter(motorRatingData) {
        switch (motorRatingData) {
            case 1: {
                return "0.04";
            }
            case 2: {
                return "0.06";
            }
            case 3: {
                return "0.09";
            }
            case 4: {
                return "0.12";
            }
            case 5: {
                return "0.18";
            }
            case 6: {
                return "0.25";
            }
            case 7: {
                return "0.37";
            }
            case 8: {
                return "0.55";
            }
            case 9: {
                return "0.75";
            }
            case 10: {
                return "1.1";
            }
            case 11: {
                return "1.5";
            }
            case 12: {
                return "2.2";
            }
            case 13: {
                return "3";
            }
            case 14: {
                return "3.7";
            }
            case 15: {
                return "4";
            }
            case 16: {
                return "5.5";
            }
            case 17: {
                return "7.5";
            }
            case 18: {
                return "9.3";
            }
            case 19: {
                return "11";
            }
            case 20: {
                return "15";
            }
            case 21: {
                return "18.5";
            }
            case 22: {
                return "22";
            }
            case 23: {
                return "30";
            }
            case 24: {
                return "37";
            }
            case 25: {
                return "45";
            }
            case 26: {
                return "55";
            }
            case 27: {
                return "75";
            }
            case 28: {
                return "90";
            }
            case 29: {
                return "110";
            }
            case 30: {
                return "132";
            }
            case 31: {
                return "160";
            }
            case 32: {
                return "200";
            }
            case 33: {
                return "250";
            }
            default: {
                return 0;
            }
        }
    }
    function efficiencyClassValueConverter(efficiencyClassData) {
        switch (efficiencyClassData) {
            case 1: {
                return "IE2";
            }
            case 2: {
                return "IE3";
            }
            case 3: {
                return "IE4";
            }
            default: {
                return "";
            }
        }
    }
    function startingMethodValueConverter(startingMethodData) {
        switch (startingMethodData) {
            case 1: {
                return "DOL";
            }
            case 2: {
                return "RDOL";
            }
            case 3: {
                return "SD";
            }
            default: {
                return "";
            }
        }
    }
    function coordinationValueConverter(coordinationData) {
        switch (coordinationData) {
            case 1: {
                return "Type-1";
            }
            case 2: {
                return "Type-2";
            }
            default: {
                return "";
            }
        }
    }
    function fuseTypeValueConverter(fuseTypeData) {
        switch (fuseTypeData) {
            case 1: {
                return "Fuse";
            }
            case 2: {
                return "Fuseless";
            }
            default: {
                return "";
            }
        }
    }
    function scpdFuseValueConverter(scpdFuseTypeData) {
        if (scpdFuseTypeData == 1) {
            switch (scpdFuseTypeData) {
                case 1: {
                    return "3NA";
                }
                default: {
                    return "";
                }
            }
        } else {
            switch (scpdFuseTypeData) {
                case 1: {
                    return "3RV";
                }
                case 2: {
                    return "3VS";
                }
                case 3: {
                    return "3VA";
                }
                case 4: {
                    return "3VM";
                }
                default: {
                    return "";
                }
            }
        }
    }
    function contactorValueConverter(contactorData) {
        switch (contactorData) {
            case 1: {
                return "3RT";
            }
            case 2: {
                return "3TF";
            }
            case 3: {
                return "3TS";
            }
            default: {
                return "";
            }
        }
    }
    function overloadRelayValueConverter(overloadRelayData) {
        switch (overloadRelayData) {
            case 1: {
                return "3RU";
            }
            case 2: {
                return "3RB";
            }
            case 3: {
                return "3UA";
            }
            case 4: {
                return "3US";
            }
            default: {
                return "";
            }
        }
    }

    // recently added converters
    function voltageValueConverter(id) {
        switch (Number(id)) {
            case 1: {
                return 360;
            }
            case 2: {
                return 380;
            }
            case 3: {
                return 400;
            }
            case 4: {
                return 415;
            }
            case 5: {
                return 440;
            }
            case 6: {
                return 480;
            }
            case 7: {
                return 500;
            }
            case 8: {
                return 690;
            }
            default: {
                return "";
            }
        }
    }
    // function frequencyValueConverter(id) {
    //   switch (Number(id)) {
    //     case 1: {
    //       return "50";
    //     }
    //     case 2: {
    //       return "60";
    //     }
    //     default: {
    //       return "";
    //     }
    //   }
    // }
    // function faultLevelValueConverter(id) {
    //   switch (Number(id)) {
    //     case 1: {
    //       return "36";
    //     }
    //     case 2: {
    //       return "50";
    //     }
    //     case 3: {
    //       return "65";
    //     }
    //     case 4: {
    //       return "70";
    //     }
    //     case 5: {
    //       return "80";
    //     }
    //     case 6: {
    //       return "100";
    //     }
    //     case 7: {
    //       return "120";
    //     }
    //     case 8: {
    //       return "130";
    //     }
    //     case 9: {
    //       return "150";
    //     }
    //     default: {
    //       return "";
    //     }
    //   }
    // }

    const [selectedRowData, setSelectedRowData] = useState("");
    const [startMethod, setStartMethod] = useState("");
    const infoIconButton = (rowData) => {
        return (
            <IconButton
                aria-label="InfoOutlined"
                onClick={() => infoIconClick(rowData)}
            >
                <InfoOutlined />
            </IconButton>
        );
    };
    function infoIconClick(rowData) {
        setSelectedRowData(rowData);
        setStartMethod(rowData.startingMethod);
        setInfoDialogOpen(true);
    }

    function renderMotorRating(value) {
        const motRating = motorRating.find((x) => x.id === value)?.motorRating;
        return motRating;
    }
    function renderEfficiencyType(effValue) {
        const effVal = parseInt(effValue);
        const effType = efficiencyType.find((x) => x.id === effVal)?.efficiencyType;
        return effType;
    }
    function renderStartingMethod(startValue) {
        const startMeth = startingMethod.find((x) => x.id === startValue)?.method;
        return startMeth;
    }
    function renderCoordinationType(coorValue) {
        const coord = coordination.find(
            (x) => x.id === coorValue
        )?.coOrdinationType;
        return coord;
    }
    function renderFuseType(fuseValueee) {
        return fuseType.find((x) => x.id === fuseValueee)?.fuseType;
    }
    function renderSCPD(rowData) {
        if (rowData.types === 1)
            return scpdFuseType.find((x) => x.id === rowData.scpd)?.productType;
        else
            return scpdFuseLessType.find((x) => x.id === rowData.scpd)?.productType;
    }
    function renderContactor(contactorValue) {
        const contacValue = contactor.find(
            (x) => x.id === contactorValue
        )?.productType;
        return contacValue;
    }
    function renderOLR(OLR) {
        const olrValue = overloadRelayData.find((x) => x.id === OLR)?.productType;
        return olrValue;
    }
    const regexValidation = /^[1-9]\d*(\.\d+)?$/;
    function handlemotorFLCFieldChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        // setmotorFLC(value);
        setMotorFLCUI(value);
    }
    function handlemotorFLCFieldBlur(e) {
        console.log("On Blur", e);
        const tenPercentOfFLC = parseFloat(motorFLC) * 0.1;
        const expectedMinValueChange = parseFloat(motorFLC) - tenPercentOfFLC;
        const expectedMaxValueChange = parseFloat(motorFLC) + tenPercentOfFLC;
        const value = e.target.value;
        if (e.target.value === "" || regexValidation.test(Number(e.target.value))) {
            if (value >= expectedMinValueChange && value <= expectedMaxValueChange) {
                setmotorFLC(parseFloat(value).toFixed(2));
                setMotorFLCUI(parseFloat(value).toFixed(2));
            } else {
                setMotorFLCUI(parseFloat(motorFLC).toFixed(2));
                handleSnackOpen("Beyond Limits", "error");
            }
        } else {
            setMotorFLCUI(motorFLC);
            handleSnackOpen("Enter valid Motor FLC", "error");
        }
    }

    const [motorLRRUI, setMotorLRRUI] = useState("");
    function handlemotorLRRFieldChange(e) {
        const value = e.target.value;
        setMotorLRRUI(value);
    }

    function handlemotorLRRFieldBlur(e) {
        const value = e.target.value;
        if (e.target.value === "" || regexValidation.test(Number(e.target.value))) {
            if (value >= 1 && value <= 8.99) {
                setMotorLRRUI(parseFloat(value).toFixed(2));
                setMotorLRR(parseFloat(value).toFixed(2));
            } else {
                setMotorLRRUI(parseFloat(motorLRR).toFixed(2));
                handleSnackOpen("Beyond Limits", "error");
            }
        } else {
            setMotorLRRUI(motorLRR);
            handleSnackOpen("Enter valid Motor LRR", "error");
        }
    }

    const [motorEfficiencyUI, setMotorEfficiencyUI] = useState("");
    function handlemotorEfficiencyFieldChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        setMotorEfficiencyUI(value);
    }
    function handlemotorEfficiencyFieldChangeBlur(e) {
        const value = Number(e.target.value);
        const fieldName = e.target.name;
        // check value within range
        if (value !== "" || regexValidation.test(value)) {
            if (value >= 0.1 && value <= 0.99) {
                setMotorEfficiency(parseFloat(value).toFixed(2));
                setMotorEfficiencyUI(parseFloat(value).toFixed(2));
                if (value != "" && powerFactor != "") {
                    var SelectedMotorRating =
                        motorRatingValueConverter(motorRatingSelected);
                    var SelectedVoltage = draftActiveData.isDraftEnquiry
                        ? voltageValueConverter(voltageVal)
                        : voltageVal.selectedItemValue;
                    var calculateFlcvalue = 0;
                    calculateFlcvalue =
                        (parseFloat(SelectedMotorRating) * 1000) /
                        (parseFloat(SelectedVoltage) * 1.732 * value * powerFactor);
                    setmotorFLC(parseFloat(calculateFlcvalue).toFixed(2));
                    setMotorFLCUI(parseFloat(calculateFlcvalue).toFixed(2));
                }
            } else {
                setMotorEfficiencyUI(parseFloat(motorEfficiency).toFixed(2));
                handleSnackOpen("Beyond Limits", "error");
            }
        } else {
            setMotorFLCUI(motorEfficiency);
            handleSnackOpen("Enter valid Motor Efficiency", "error");
        }
    }

    const [powerFactorUI, setPowerFactorUI] = useState("");
    function handlePowerFactorChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        setPowerFactorUI(value);
    }
    function handlePowerFactorChangeBlur(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        if (value >= 0.1 && value <= 0.99) {
            setPowerFactor(parseFloat(value).toFixed(2));
            setPowerFactorUI(parseFloat(value).toFixed(2));
            if (
                e.target.value !== "" ||
                regexValidation.test(Number(e.target.value))
            ) {
                if (value != "" && motorEfficiency != "") {
                    //calculateFlc
                    var SelectedMotorRating =
                        motorRatingValueConverter(motorRatingSelected);
                    var SelectedVoltage = draftActiveData.isDraftEnquiry
                        ? voltageValueConverter(voltageVal)
                        : voltageVal.selectedItemValue;
                    var calculateFlcvalue = 0;
                    calculateFlcvalue =
                        (SelectedMotorRating * 1000) /
                        (SelectedVoltage * 1.732 * motorEfficiency * value);
                    setmotorFLC(parseFloat(calculateFlcvalue).toFixed(2));
                    setMotorFLCUI(parseFloat(calculateFlcvalue).toFixed(2));
                }
            } else {
                handleSnackOpen("Beyond Limits", "error");
                setPowerFactorUI(parseFloat(powerFactor).toFixed(2));
            }
        } else {
            setPowerFactorUI(powerFactor);
            handleSnackOpen("Enter valid Power Factor", "error");
        }
    }

    const tableColumns = [
        {
            title: "Actions",
            field: 1,
            hidden: true,
            width: "10%",
        },
        {
            title: "Motor Rating (kW)",
            field: "rating",
            width: "5%",
            render: (rowData) => renderMotorRating(rowData.motorRating),
            // render: (rowData) => motorRatingValueConverter(rowData.motorRating)
        },
        // {
        //   title: "Motor Eff.",
        //   field: "efficiency",
        //   width: "5%",
        // },
        {
            title: "FLC (A)",
            field: "motorFlc",
            width: "10%",
        },
        {
            title: "Eff. Class",
            field: "class",
            width: "10%",
            render: (rowData) => renderEfficiencyType(rowData.class),
        },
        {
            title: "Starting Method",
            field: "startingMethod",
            width: "10%",
            render: (rowData) => renderStartingMethod(rowData.startingMethod),
        },
        {
            title: "Coordination",
            field: "typesOfCoordination",
            width: "10%",
            render: (rowData) => renderCoordinationType(rowData.typesOfCoordination),
        },
        {
            title: "Feeder Type",
            field: "fuseType",
            width: "10%",
            render: (rowData) => renderFuseType(rowData.fuseType),
        },
        {
            title: "SCPD",
            field: "scpd",
            width: "10%",
            render: (rowData) => renderSCPD(rowData),
        },
        {
            title: "Contactor",
            field: "contactor",
            width: "10%",
            render: (rowData) => renderContactor(rowData.contactor),
        },
        {
            title: "OLPD",
            field: "overloadRelay",
            width: "10%",
            render: (rowData) => renderOLR(rowData.overloadRelay),
        },
        canShowAdminInfo
            ? {
                title: "Admin View",
                field: "adminView",
                width: "10%",
                hidden: role == 1 ? false : true,
                render: (rowData) => infoIconButton(rowData),
            }
            : {},
        // {
        //     title: 'Admin View',
        //     field: 'adminView',
        //     width: "10%",
        //     hidden: role == 1 ? false : true,
        //     render: (rowData) => infoIconButton(rowData)
        // }
        //checking for output screen
        //,
        //{
        //    title: 'SCPD MLFB',
        //    field: 'scpdmlfb',
        //    width: "10%", hidden: true
        //},
        //{
        //    title: 'SCPD Rating',
        //    field: 'scpdrating',
        //    width: "10%", hidden: true
        //},
        //{
        //    title: 'Contactor MLFB',
        //    field: 'contactormlfb',
        //    width: "10%", hidden: true
        //},
        //{
        //    title: 'Contactor Rating',
        //    field: 'contactorrating',
        //    width: "10%", hidden: true
        //},
        //{
        //    title: 'OverloadRelay MLFB',
        //    field: 'overloadrelaymlfb',
        //    width: "10%", hidden: true
        //},
        //{
        //    title: 'OverloadRelay Rating',
        //    field: 'overloadrelayrating',
        //    width: "10%", hidden: true
        //}
    ];

    const tableDataToDisplayVal = useSelector(
        (state) => state.matTableDataDisplayReducer.matTableDataDisplay
    );
    const [tableDataToDisplay, setTableDataToDisplay] = useState(
        tableDataToDisplayVal || []
    );
    const tableDataVal = useSelector(
        (state) => state.matTableDataReducer.matTableData
    );
    const [matTableData, setMatTableData] = useState(tableDataVal || []);

    //
    const [tableDataFromAPI, setTableDataFromAPI] = useState([]);

    const [mlfbMasterData, setMlfbMasterData] = useState("");
    const [mlfbMasterRatingData, setMlfbMasterRatingData] = useState("");

    // const [siriusChartContactorData, setSiriusChartContactorData] = useState('');
    let siriusChartContactorData;
    // const [siriusChartContactorRatingData, setSiriusChartContactorRatingData] = useState('');
    let siriusChartContactorRatingData;

    const [olrMlfbData, setOLRMlfbData] = useState("");
    const [olrMlfbRatingData, setOLRMlfbRatingData] = useState("");

    //checking for output screen
    const [SCPDMLFB, setSCPDMLFB] = useState("");
    const [SCPDMLFBRating, setSCPDMLFBRating] = useState("");
    const [ContactorMLFB, setContactorMLFB] = useState("");
    const [ContactorMLFBRating, setContactorMLFBRating] = useState("");
    const [OverloadRelayMLFB, setOverloadRelayMLFB] = useState("");
    const [OverloadRelayMLFBRating, setOverloadRelayMLFBRating] = useState("");

    const tableHeaderStyle = {
        backgroundColor: "var(--light-sand)",
        color: "var(--dark-blue)",
    };
    const tableOptions = {
        headerStyle: tableHeaderStyle,
        showTitle: false,
        draggable: false,
        paginationPosition: "bottom",
        //paging: false,
        maxBodyHeight: "600px",
        toolbar: true,
        sorting: false,
        pageSize: 4,
        pageSizeOptions: [3, 10, 20, 30],
        // emptyRowsWhenPaging: false
    };

    // delete row
    const handleRowDelete = (oldTableData, resolve, reject) => {
        console.log("Delete");
        let paramMotors = {};
        paramMotors = {
            Id: oldTableData.id,
            SystemInfeedId: oldTableData.systemInfeedId,
        };
        api
            .post("/deleteConfigurationItem", paramMotors)
            .then(function (response) {
                console.log("Record after Deleted", response.data);
                //const dataDelete = [...tableDataToDisplay];
                //const index = oldTableData.tableData.id;
                //dataDelete.splice(index, 1);
                //setData([...dataDelete]);
                ////resolve();
                ////reject();
                handleSnackOpen("Motor Item deleted successfully.", "success");

                // const dataDelete = [...matTableData];
                // const index = dataDelete.findIndex(x => x.id === oldTableData.id);

                // dataDelete.splice(index, 1);
                // setMatTableData([...dataDelete]);
                // console.log(matTableData);
            })
            .catch((error) => {
                //resolve();
                reject();
            });

        const dataDelete = [...matTableData];
        const index = dataDelete.findIndex((x) => x.id === oldTableData.id);
        dataDelete.splice(index, 1);
        setMatTableData([...dataDelete]);
        dispatch(matTableDataAction(dataDelete));
        console.log("matTableData", matTableData);

        //delete records for output(DOL)
        const deletedolRowDataToAdd = [...dolTableData];
        const indexdol = deletedolRowDataToAdd.findIndex(
            (x) => x.id === oldTableData.id
        );
        if (indexdol >= 0) {
            deletedolRowDataToAdd.splice(indexdol, 1);
            setDolTableData([...deletedolRowDataToAdd]);
            dispatch(dolTableDataAction(deletedolRowDataToAdd));
            console.log("DOL Delete Table", dolTableData);
        }
        //delete records for output(RDOL)
        const deleteRdolRowDataToAdd = [...rdolTableData];
        const indexRdol = deleteRdolRowDataToAdd.findIndex(
            (x) => x.id === oldTableData.id
        );
        if (indexRdol >= 0) {
            deleteRdolRowDataToAdd.splice(indexRdol, 1);
            setRdolTableData([...deleteRdolRowDataToAdd]);
            dispatch(rdolTableDataAction(deleteRdolRowDataToAdd));
            console.log("RDOL Delete Table", deleteRdolRowDataToAdd);
        }
        //delete records for output(SD)
        const deleteSDRowDataToAdd = [...starDeltaTableData];
        const indexSD = deleteSDRowDataToAdd.findIndex(
            (x) => x.id === oldTableData.id
        );
        if (indexSD >= 0) {
            deleteSDRowDataToAdd.splice(indexSD, 1);
            setStarDeltaTableData([...deleteSDRowDataToAdd]);
            dispatch(starDeltaTableDataAction(deleteSDRowDataToAdd));
            console.log("SD Delete Table", deleteSDRowDataToAdd);
        }

        reject();
    };

    const handleRowUpdate = (oldTableData, resolve, reject) => {
        console.log("Update");
        reject();
    };

    // Form Dialog Part
    const [open, setOpen] = useState(false);
    const [editRowData, setEditRowData] = useState([]);
    const [dialogTitleText, setDialogTitleText] = useState(
        "Enter Motor & Feeder Details"
    );

    const handleClickOpen = () => {
        console.log("System Infeed", generatedSystemInfeedID);
        // place values in useState
        if (matTableData.length != 0) {
            const lastRowIndex = matTableData.length - 1;
            setMotorRatingSelected(matTableData[lastRowIndex].motorRating);
            setEfficiencyTypeSelected(matTableData[lastRowIndex].class);
            setmotorFLC(parseFloat(matTableData[lastRowIndex].motorFlc).toFixed(2));
            setMotorFLCUI(parseFloat(matTableData[lastRowIndex].motorFlc).toFixed(2));
            setMotorLRR(parseFloat(matTableData[lastRowIndex].motorLrr).toFixed(2));
            setMotorLRRUI(parseFloat(matTableData[lastRowIndex].motorLrr).toFixed(2));
            setMotorEfficiency(
                parseFloat(matTableData[lastRowIndex].efficiency).toFixed(2)
            );
            setMotorEfficiencyUI(
                parseFloat(matTableData[lastRowIndex].efficiency).toFixed(2)
            );
            setPowerFactor(
                parseFloat(matTableData[lastRowIndex].powerFactor).toFixed(2)
            );
            setPowerFactorUI(
                parseFloat(matTableData[lastRowIndex].powerFactor).toFixed(2)
            );
            setStartingMethodSelected(matTableData[lastRowIndex].startingMethod);
            setCoordinationSelected(matTableData[lastRowIndex].typesOfCoordination);
            setFuseTypeSelected(matTableData[lastRowIndex].fuseType);
            setScpdFuseTypeSelected(matTableData[lastRowIndex].scpd);
            setContactorSelected(matTableData[lastRowIndex].contactor);
            setOverloadRelaySelected(matTableData[lastRowIndex].overloadRelay);
            setCoolingFanMotorCurrent(
                matTableData[lastRowIndex].coolingFanMotorCurrent
            );
            setServiceFactor(
                parseFloat(matTableData[lastRowIndex].serviceFactor).toFixed(2)
            );
            setServiceFactorUI(
                parseFloat(matTableData[lastRowIndex].serviceFactor).toFixed(2)
            );
            setMotorStartingTimeVal(
                parseFloat(matTableData[lastRowIndex].motorStartingTime).toFixed(2)
            );
            setMotorStartingTimeValUI(
                parseFloat(matTableData[lastRowIndex].motorStartingTime).toFixed(2)
            );
            setEnquiryType(matTableData[lastRowIndex].adminEnquiryType);

            const value = matTableData[lastRowIndex].motorStartingTime;
            if (value >= 4 && value <= 10) {
                setStartingMethodClass(myConstClass.C10);
            } else if (value >= 11 && value <= 20) {
                setStartingMethodClass(myConstClass.C20);
            } else if (value >= 21 && value <= 30) {
                setStartingMethodClass(myConstClass.C30);
            } else if (value >= 31 && value <= 40) {
                setStartingMethodClass(myConstClass.C40);
            } else {
                setStartingMethodClass(myConstClass.C10);
            }
        }
        setOpen(true);
    };

    const handleDraftClickOpen = () => {
        // tableDataVal
        const lastRowIndex = tableDataVal.length - 1;
        setMotorRatingSelected(tableDataVal[lastRowIndex].motorRating);
        setEfficiencyTypeSelected(tableDataVal[lastRowIndex].class);
        setmotorFLC(parseFloat(tableDataVal[lastRowIndex].motorFlc).toFixed(2));
        setMotorFLCUI(parseFloat(tableDataVal[lastRowIndex].motorFlc).toFixed(2));
        setMotorLRR(parseFloat(tableDataVal[lastRowIndex].motorLrr).toFixed(2));
        setMotorLRRUI(parseFloat(tableDataVal[lastRowIndex].motorLrr).toFixed(2));
        setMotorEfficiency(
            parseFloat(tableDataVal[lastRowIndex].efficiency).toFixed(2)
        );
        setMotorEfficiencyUI(
            parseFloat(tableDataVal[lastRowIndex].efficiency).toFixed(2)
        );
        setPowerFactor(
            parseFloat(tableDataVal[lastRowIndex].powerFactor).toFixed(2)
        );
        setPowerFactorUI(
            parseFloat(tableDataVal[lastRowIndex].powerFactor).toFixed(2)
        );
        setStartingMethodSelected(tableDataVal[lastRowIndex].startingMethod);
        setCoordinationSelected(tableDataVal[lastRowIndex].typesOfCoordination);
        setFuseTypeSelected(tableDataVal[lastRowIndex].fuseType);
        setScpdFuseTypeSelected(tableDataVal[lastRowIndex].scpd);
        setContactorSelected(tableDataVal[lastRowIndex].contactor);
        setOverloadRelaySelected(tableDataVal[lastRowIndex].overloadRelay);
        setCoolingFanMotorCurrent(
            tableDataVal[lastRowIndex].coolingFanMotorCurrent
        );
        setServiceFactor(
            parseFloat(tableDataVal[lastRowIndex].serviceFactor).toFixed(2)
        );
        setServiceFactorUI(
            parseFloat(tableDataVal[lastRowIndex].serviceFactor).toFixed(2)
        );
        setMotorStartingTimeVal(
            parseFloat(tableDataVal[lastRowIndex].motorStartingTime).toFixed(2)
        );
        setMotorStartingTimeValUI(
            parseFloat(tableDataVal[lastRowIndex].motorStartingTime).toFixed(2)
        );
        setEnquiryType(tableDataVal[lastRowIndex].adminEnquiryType);

        const value = tableDataVal[lastRowIndex].motorStartingTime;
        if (value >= 4 && value <= 10) {
            setStartingMethodClass(myConstClass.C10);
        } else if (value >= 11 && value <= 20) {
            setStartingMethodClass(myConstClass.C20);
        } else if (value >= 21 && value <= 30) {
            setStartingMethodClass(myConstClass.C30);
        } else if (value >= 31 && value <= 40) {
            setStartingMethodClass(myConstClass.C40);
        } else {
            setStartingMethodClass(myConstClass.C10);
        }

        setOpen(true);
    };

    const handleEditDialogOpen = (rowData) => {
        setDialogTitleText("Edit Motor & Feeder Details");
        // fetchtSelectedRowID(rowData);
        setEditRowData(rowData);
        //setEditOpen(true);

        setMotorRatingSelected(rowData.motorRating);
        setEfficiencyTypeSelected(rowData.class);
        setmotorFLC(rowData.motorFlc);
        setMotorFLCUI(rowData.motorFlc);
        setMotorLRR(rowData.motorLrr); // lrr for new Edit
        setMotorLRRUI(rowData.motorLrr);
        setMotorEfficiency(rowData.efficiency);
        setMotorEfficiencyUI(rowData.efficiency);
        setPowerFactor(rowData.powerFactor);
        setPowerFactorUI(rowData.powerFactor);
        setStartingMethodSelected(rowData.startingMethod);
        setCoordinationSelected(rowData.typesOfCoordination);
        setFuseTypeSelected(rowData.fuseType);
        setScpdFuseTypeSelected(rowData.scpd);
        setContactorSelected(rowData.contactor);
        setOverloadRelaySelected(rowData.overloadRelay);
        setCoolingFanMotorCurrent(rowData.coolingFanMotorCurrent);
        setServiceFactor(rowData.serviceFactor);
        setServiceFactorUI(rowData.serviceFactor);
        setMotorStartingTimeVal(rowData.motorStartingTime);
        setMotorStartingTimeValUI(rowData.motorStartingTime);
        setEnquiryType(rowData.adminEnquiryType);

        const value = rowData.motorStartingTime;
        if (value >= 4 && value <= 10) {
            setStartingMethodClass(myConstClass.C10);
        } else if (value >= 11 && value <= 20) {
            setStartingMethodClass(myConstClass.C20);
        } else if (value >= 21 && value <= 30) {
            setStartingMethodClass(myConstClass.C30);
        } else if (value >= 31 && value <= 40) {
            setStartingMethodClass(myConstClass.C40);
        } else {
            setStartingMethodClass(myConstClass.C10);
        }

        setOpen(true);
    };

    const handleClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        handleClearDetails();
        setEditRowData([]);
        setOpen(false);
        // setEditOpen(false);
        setLoading(false);
    };

    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const handleInfoDialogClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setSelectedRowData("");
        setInfoDialogOpen(false);
    };

    //Snackbar
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setSnackOpen(true);
    };
    const handleSnackClose = () => {
        setSnackOpen(false);
    };

    // Infeed Form related
    const voltageVal = useSelector(
        (state) => state.voltageDropdownReducer.voltage
    );

    const [motorRating, setMotorRating] = useState([]);
    const [motorRatingSelected, setMotorRatingSelected] = useState("");
    const [efficiencyType, setEfficiencyType] = useState([]);
    const [efficiencyTypeSelected, setEfficiencyTypeSelected] = useState("");
    const [startingMethod, setStartingMethod] = useState([]);
    const [startingMethodSelected, setStartingMethodSelected] = useState("");
    const [coordination, setCoordination] = useState([]);
    const [coordinationSelected, setCoordinationSelected] = useState("");
    const [fuseType, setFuseType] = useState([]);
    const [fuseTypeSelected, setFuseTypeSelected] = useState("");
    const [scpdFuseType, setScpdFuseType] = useState([]);
    const [scpdFuseTypeSelected, setScpdFuseTypeSelected] = useState("");

    const [scpdFuseLessType, setScpdFuseLessType] = useState([]);

    const [contactor, setContactor] = useState([]);
    const [contactorSelected, setContactorSelected] = useState("");
    const [overloadRelay, setOverloadRelay] = useState([]);
    const [overloadRelayData, setOverloadRelayData] = useState([]);
    const [overloadRelaySelected, setOverloadRelaySelected] = useState("");

    //  to be removed later
    // const overloadRelayStatic = [
    //   {
    //     id: 1,
    //     productType: "Integrated with SCPD",
    //     createdDate: null,
    //     modifiedDate: null,
    //     isActive: null,
    //   },
    //   {
    //     id: 2,
    //     productType: "3RU",
    //     createdDate: null,
    //     modifiedDate: null,
    //     isActive: null,
    //   },
    //   {
    //     id: 3,
    //     productType: "3RB",
    //     createdDate: null,
    //     modifiedDate: null,
    //     isActive: null,
    //   },
    //   {
    //     id: 4,
    //     productType: "3UA",
    //     createdDate: null,
    //     modifiedDate: null,
    //     isActive: null,
    //   },
    //   {
    //     id: 5,
    //     productType: "3US",
    //     createdDate: null,
    //     modifiedDate: null,
    //     isActive: null,
    //   },
    // ];
    // const overloadRelayStaticSD = [
    //   {
    //     id: 2,
    //     productType: "3RU",
    //     createdDate: null,
    //     modifiedDate: null,
    //     isActive: null,
    //   },
    //   {
    //     id: 3,
    //     productType: "3RB",
    //     createdDate: null,
    //     modifiedDate: null,
    //     isActive: null,
    //   },
    //   {
    //     id: 4,
    //     productType: "3UA",
    //     createdDate: null,
    //     modifiedDate: null,
    //     isActive: null,
    //   },
    //   {
    //     id: 5,
    //     productType: "3US",
    //     createdDate: null,
    //     modifiedDate: null,
    //     isActive: null,
    //   },
    // ];
    // const [overloadRelayStaticData, setOverloadRelayStaticData] =
    //   useState(overloadRelayStatic);
    const [canShowIntWithSCPD, setCanShowIntWithSCPD] = useState(true);

    // for auto generated values
    const [motorEfficiency, setMotorEfficiency] = useState("");
    const [motorLRR, setMotorLRR] = useState("");
    const [motorFLC, setmotorFLC] = useState("");
    const [motorFLCUI, setMotorFLCUI] = useState("");
    const [powerFactor, setPowerFactor] = useState("");
    const [startingMethodClass, setStartingMethodClass] = useState(
        myConstClass.C10
    );

    // for text fields
    const [serviceFactor, setServiceFactor] = useState(1);
    // const motorStartingTime = 6;
    const motorStartingTimeConstant = 6;
    const [motorStartingTimeVal, setMotorStartingTimeVal] = useState(6);
    const [coolingFanMotorCurrentUI, setCoolingFanMotorCurrentUI] = useState(0);
    const [coolingFanMotorCurrent, setCoolingFanMotorCurrent] = useState(0);

    // for admin
    const enquiryTypeData = [
        {
            id: 1,
            value: "Tested",
        },
        {
            id: 2,
            value: "Recommended",
        },
    ];
    const [showBothEnqType, setShowBothEnqType] = useState(true);
    const [showOnlyTested, setShowOnlyTested] = useState(false);
    const [showOnlyRecommended, setShowOnlyRecommended] = useState(false);

    let enquiryTypeVar = "";
    // const [enquiryType, setEnquiryType] = useState("");
    // const [enquiryTypeValue, setEnquiryTypeValue] = useState('Tested');

    function handleMotorRatingChange(e, valuee) {
        const selectedValue = e.target.value;
        const fieldName = e.target.name;
        setMotorRatingSelected(selectedValue);
        if (
            efficiencyTypeSelected !== "" &&
            selectedValue !== "" &&
            voltageVal !== undefined
        ) {
            getMotorDetails(selectedValue, efficiencyTypeSelected);
        }
    }
    function handleEfficiencyFieldChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        setEfficiencyTypeSelected(value);
        setContactorSelected("");
        if (
            value !== "" &&
            motorRatingSelected !== "" &&
            voltageVal !== undefined
        ) {
            getMotorDetails(motorRatingSelected, value);
        }
    }
    async function handleStartingMethodFieldChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        setStartingMethodSelected(value);

        if (value === 1 || value === 2) {
            if (startingMethodClass === myConstClass.C10) {
                // setOverloadRelayStaticData(overloadRelayStatic);
                setCanShowIntWithSCPD(true);
                setOverloadRelaySelected("");
            } else {
                // setOverloadRelayStaticData(overloadRelayStaticSD);
                setCanShowIntWithSCPD(false);
                setOverloadRelaySelected("");
            }
            if (motorFLC !== "" && motorLRR !== "")
                await calculateDOL(motorFLC, motorLRR);
        }
        if (value === 3) {
            // setOverloadRelayStaticData(overloadRelayStaticSD);
            setCanShowIntWithSCPD(false);
            setOverloadRelaySelected("");

            if (motorFLC !== "" && motorLRR !== "")
                await calculateSD(motorFLC, motorLRR, enquiryTypeVar);
        }
    }
    function handleCoordinationFieldChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        setCoordinationSelected(value);
    }
    function handleFuseTypeFieldChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        setFuseTypeSelected(value);
        setScpdFuseTypeSelected("");
    }
    function handleScpdFuseTypeFieldChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        setScpdFuseTypeSelected(value);
        setEnquiryType("");
    }
    const OLRForContactor1 = [
        {
            id: 1,
            productType: "Integrated with SCPD",
            createdDate: null,
            modifiedDate: null,
            isActive: null,
        },
        {
            id: 2,
            productType: "3RU",
            createdDate: null,
            modifiedDate: null,
            isActive: null,
        },
        {
            id: 3,
            productType: "3RB",
            createdDate: null,
            modifiedDate: null,
            isActive: null,
        },
    ];
    const OLRForContactor2 = [
        {
            id: 1,
            productType: "Integrated with SCPD",
            createdDate: null,
            modifiedDate: null,
            isActive: null,
        },
        {
            id: 4,
            productType: "3UA",
            createdDate: null,
            modifiedDate: null,
            isActive: null,
        },
    ];
    const OLRForContactor3 = [
        {
            id: 1,
            productType: "Integrated with SCPD",
            createdDate: null,
            modifiedDate: null,
            isActive: null,
        },
        {
            id: 5,
            productType: "3US",
            createdDate: null,
            modifiedDate: null,
            isActive: null,
        },
    ];
    function handleContactorFieldChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        setContactorSelected(value);

        setEnquiryType("");
        if (
            value == 1 &&
            (overloadRelaySelected == 1 ||
                overloadRelaySelected == 2 ||
                overloadRelaySelected == 3)
        ) {
            setShowBothEnqType(true);
            setShowOnlyTested(false);
            setShowOnlyRecommended(false);
        }
        if (
            value == 2 &&
            (overloadRelaySelected == 1 || overloadRelaySelected == 4)
        ) {
            setShowBothEnqType(false);
            setShowOnlyTested(false);
            setShowOnlyRecommended(true);
        }
        if (value == 3) {
            setShowBothEnqType(false);
            setShowOnlyTested(false);
            setShowOnlyRecommended(true);
        }
        switch (value) {
            case 1:
                setOverloadRelay(OLRForContactor1);
                return;
            case 2:
                setOverloadRelay(OLRForContactor2);
                return;
            case 3:
                setOverloadRelay(OLRForContactor3);
                return;
            default:
                return;
        }
    }
    function handleOverloadRelayFieldChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        setOverloadRelaySelected(value);
        setEnquiryType("");
        if (efficiencyTypeSelected == 3 && contactorSelected == 2) {
            setShowBothEnqType(false);
            setShowOnlyTested(false);
            setShowOnlyRecommended(true);
        }
        if (value == 1 || value == 2 || value == 3) {
            if (contactorSelected == 1) {
                setShowBothEnqType(true);
                setShowOnlyTested(false);
                setShowOnlyRecommended(false);
            }
        }
        if (value == 4) {
            if (contactorSelected == 2) {
                setShowBothEnqType(false);
                setShowOnlyTested(false);
                setShowOnlyRecommended(true);
            }
        }
        if (value == 5) {
            if (contactorSelected == 3) {
                setShowBothEnqType(false);
                setShowOnlyTested(false);
                setShowOnlyRecommended(true);
            }
        }
    }

    const [serviceFactorUI, setServiceFactorUI] = useState(1);
    function serviceFactorChange(e) {
        const value = e.target.value;
        setServiceFactorUI(value);
    }
    function serviceFactorChangeBlur(e) {
        const value = e.target.value;
        if (value >= 1 && value <= 1.3) {
            setServiceFactorUI(parseFloat(value).toFixed(2));
            setServiceFactor(parseFloat(value).toFixed(2));
        } else {
            handleSnackOpen("Beyond Limits", "error");
            setServiceFactorUI(parseFloat(serviceFactor).toFixed(2));
        }
    }

    const [motorStartingTimeValUI, setMotorStartingTimeValUI] = useState(6);
    const [motorStartingTimeOldValue, setMotorStartingTimeOldValue] = useState(6);
    function motorStartingTimeChange(e) {
        const value = e.target.value;
        setMotorStartingTimeValUI(value);
    }

    const class20Contactor = [
        {
            id: 1,
            productType: "3RT",
            createdDate: null,
            modifiedDate: null,
            isActive: null,
        },
        {
            id: 2,
            productType: "3TF",
            createdDate: null,
            modifiedDate: null,
            isActive: null,
        },
    ];
    const salesEngineerContactor = [
        {
            id: 1,
            productType: "3RT",
            createdDate: null,
            modifiedDate: null,
            isActive: null,
        },
    ];
    const class20OLR = [
        {
            id: 1,
            productType: "Integrated with SCPD",
            createdDate: null,
            modifiedDate: null,
            isActive: null,
        },
        {
            id: 3,
            productType: "3RB",
            createdDate: null,
            modifiedDate: null,
            isActive: null,
        },
    ];
    const SDClass20OLR = [
        {
            id: 3,
            productType: "3RB",
            createdDate: null,
            modifiedDate: null,
            isActive: null,
        },
    ];

    function motorStartingTimeChangeBlur(e) {
        const value = e.target.value;
        const fieldName = e.target.name;

        if (value >= 4 && value <= 40) {
            setMotorStartingTimeVal(parseFloat(value).toFixed(2));
            setMotorStartingTimeValUI(parseFloat(value).toFixed(2));
            setMotorStartingTimeOldValue(parseFloat(value).toFixed(2));
            setFixedMotorStartingTime(6);
            if (value >= 4 && value <= 10) {
                setStartingMethodClass(myConstClass.C10);
                if (startingMethodSelected !== 3) {
                    // setOverloadRelayStaticData(overloadRelayStatic);
                    setCanShowIntWithSCPD(true);
                    // setOverloadRelaySelected("");
                }
            } else if (value >= 11 && value <= 20) {
                setStartingMethodClass(myConstClass.C20);
                // setOverloadRelayStaticData(overloadRelayStaticSD);
                setCanShowIntWithSCPD(false);
                // setOverloadRelaySelected("");
            } else if (value >= 21 && value <= 30) {
                setStartingMethodClass(myConstClass.C30);
                // setOverloadRelayStaticData(overloadRelayStaticSD);
                setCanShowIntWithSCPD(false);
                // setOverloadRelaySelected("");
            } else if (value >= 31 && value <= 40) {
                setStartingMethodClass(myConstClass.C40);
                // setOverloadRelayStaticData(overloadRelayStaticSD);
                setCanShowIntWithSCPD(false);
                // setOverloadRelaySelected("");
            } else {
                setStartingMethodClass(myConstClass.C10);
                // setOverloadRelayStaticData(overloadRelayStaticSD);
                setCanShowIntWithSCPD(false);
                // setOverloadRelaySelected("");
            }
        } else {
            setMotorStartingTimeValUI(motorStartingTimeOldValue);
            handleSnackOpen("Beyond Limits", "error");
        }
    }
    function coolingFanMotorCurrentChangeBlur(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        if (value != "") {
            setCoolingFanMotorCurrent(parseFloat(value).toFixed(2));
            setCoolingFanMotorCurrentUI(parseFloat(value).toFixed(2));
        }
    }
    function coolingFanMotorCurrentChange(e) {
        const value = e.target.value;
        setCoolingFanMotorCurrentUI(value);
    }
    function handleEnquiryTypeFieldChange(e) {
        const value = e.target.value;
        const fieldName = e.target.name;
        setEnquiryType(value);
    }

    const getMotorDetails = (motorRatingVal, efficiencyVal) => {
        let paramMotors = {};
        paramMotors = {
            MotorRating: motorRatingVal, //id of motorRating ddl selected value
            Efficiency: efficiencyVal, //id of efficiency ddl selected value
            Voltage: draftActiveData.isDraftEnquiry
                ? voltageVal
                : voltageVal.selectedItemID, //id of voltage ddl selected value from previous page
        };

        api
            .post("/getMotorDetails", paramMotors)
            .then(function (response) {
                console.log("Motor Details", response.data);
                setMotorEfficiency(
                    parseFloat(response.data.motorEfficiency).toFixed(2)
                );
                setMotorEfficiencyUI(
                    parseFloat(response.data.motorEfficiency).toFixed(2)
                );
                setMotorLRR(parseFloat(response.data.motorLrr).toFixed(2));
                setMotorLRRUI(parseFloat(response.data.motorLrr).toFixed(2));
                setmotorFLC(parseFloat(response.data.motorFlc).toFixed(2));
                setMotorFLCUI(parseFloat(response.data.motorFlc).toFixed(2));
                setPowerFactor(parseFloat(response.data.powerFactor).toFixed(2));
                setPowerFactorUI(parseFloat(response.data.powerFactor).toFixed(2));

                if (startingMethodSelected == 1 || startingMethodSelected == 2)
                    calculateDOL(response.data.motorFlc, response.data.motorLrr);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    /* ------ Start Star Delta Calculation -------*/
    //var serviceFactor = 1; // get from textbox
    // var fanMotorFLC = 0;  // get from textbox
    var SDflaLine = 0;
    var SDflaphase = 0;
    var SDmotorStartingTime = 0;
    var SDlradol = 0;
    var SDlracontactor = 0;
    var SDLOlChar = 0;
    var SDStarCurrent = 0;
    var SCPDSDvalue = 0;
    var selectedSDMlfbMagOnlyMaster,
        scpdSDKaMaster,
        scpdSDSizeMaster,
        scpdSDRatingMaster;
    var scSDSetting = 0,
        scSDSettingLRA = 0,
        scSDSettingLRALB = 0,
        scSDSettingLRAUB = 0;
    var letThroughOfSDSCPD,
        letThroughOfSDSCPDVal = 0;
    var isSDSCPDhighercurrent = false,
        isSDSCLowerBand = false;
    var selectedSDbMasterData;

    // for op
    let sdScpdMLFB,
        sdScpdRating,
        sdContactorMLFB,
        sdContactorMLFBRating,
        sdStarContactorMLFB,
        sdStarContactorRating,
        sdOverloadRelayMLFB,
        sdOverloadRelayRating;

    const calculateSD = async (motorFLC, motorLrr, enquiryTypeVar) => {
        //calculate flaline
        SDflaLine = myFunctions.getFLALine(
            motorFLC,
            serviceFactor,
            coolingFanMotorCurrent
        );
        //calculate FLAphase
        SDflaphase = myFunctions.getSDFLAPhase(motorFLC, serviceFactor);
        //calculate StartingTime
        SDmotorStartingTime = myFunctions.getMotorStartingTime(
            motorStartingTimeConstant
        );
        setMotorStartingTimeVal(parseFloat(SDmotorStartingTime).toFixed(2));
        // setMotorStartingTimeValUI(SDmotorStartingTime);
        //calculate LRA-DOL
        SDlradol = myFunctions.getSDLRADOL(motorFLC, motorLrr);
        //LRA-SD (For contactor selection
        SDlracontactor = myFunctions.getlracontactor(SDlradol);
        //LRA (For OL char. selection)
        SDLOlChar = myFunctions.getOlChar(motorFLC, motorLrr);
        //calculate star current
        SDStarCurrent = myFunctions.getSDStarcurrent(motorFLC, serviceFactor);
        /* ------ Calculation for SCPD Selection-------*/

        SCPDSDvalue = myFunctions.getSCPDSDvalue(SDflaLine, motorRatingSelected);
        //selectedSDbMasterData = await getselectedSDMlfbMasterData(SCPDSDvalue); //get nearest rating data from mstMLFB table

        if (
            startingMethodSelected != "" &&
            startingMethodSelected != undefined &&
            coordinationSelected != "" &&
            coordinationSelected != undefined &&
            scpdFuseTypeSelected != "" &&
            scpdFuseTypeSelected != undefined &&
            contactorSelected != "" &&
            contactorSelected != undefined &&
            overloadRelaySelected != "" &&
            overloadRelaySelected != undefined &&
            startingMethodClass != "" &&
            startingMethodClass != undefined &&
            ((enquiryType != "" && enquiryType != undefined && role !== 2) ||
                (enquiryTypeVar != "" && enquiryTypeVar != undefined && role == 2))
        ) {
            if (enquiryType == 1 || enquiryTypeVar == 1) {
                var categorytype = "Tested";
                var chartid = await getSiriusChartDOLId();

                var siriusChartSDMPCBSize = await getSiriusChartSDMPCBMaster(
                    SCPDSDvalue,
                    chartid
                );

                var selectedSDbMasterData = await getselectedTestedSDMlfbMasterData(
                    SCPDSDvalue,
                    siriusChartSDMPCBSize
                );
                selectedSDbMasterData = selectedSDbMasterData.find(
                    ({ category }) => category === categorytype
                );
            }
            if (enquiryType == 2 || enquiryTypeVar == 2) {
                var categorytype = "Recommended";
                //setDOLMaxRange(scpdDOLMaxRange);
                //scpd rating

                var selectedSDbMasterData = await getselectedSDMlfbMasterData(
                    SCPDSDvalue
                );
                //selectedSDbMasterData = selectedSDbMasterData.find(
                //  ({ category }) => category === "Tested"
                //);
            }
            if (selectedSDbMasterData != undefined) {
                scpdSDRatingMaster = selectedSDbMasterData.rating; //get rating from mstMLFB table
                //setRatingMaster(scpdRatingMaster);
                selectedSDMlfbMagOnlyMaster = selectedSDbMasterData.magOnly; //get mlfbMagOnly from mstMLFB table, for admin it will get 2 and for other user it will get 1 value
                scpdSDKaMaster = selectedSDbMasterData.voltKa; //get ka from mstMLFB table
                scpdSDSizeMaster = selectedSDbMasterData.size; //get size from mstMLFB table

                //OL tripping zone time on LRA
                var allMlfbProductData = await getMlfbProductData(
                    selectedSDMlfbMagOnlyMaster,
                    SDflaLine
                ); //get all 3poleLoadFactor values and its ta from mstMLFBProductDetail
                if (allMlfbProductData != undefined && allMlfbProductData.length > 0) {
                    //calculate SD SC zone based on LRA
                    //SC setting  things[0].event
                    scSDSetting = allMlfbProductData[0].shortCircuitReleaseFactor; //take always highest value
                    scSDSettingLRA = myFunctions.getSDScSettingLRA(
                        scpdSDRatingMaster,
                        scSDSetting
                    );
                    //calculate Lower bound and upper bound of SC zone based on LRA
                    scSDSettingLRALB = myFunctions.getSDScSettingLRALB(scSDSettingLRA);
                    scSDSettingLRAUB = myFunctions.getSDScSettingLRAUB(scSDSettingLRA);
                    //Let through of SD SCPD required S/C (sr. no. 6)
                    letThroughOfSDSCPD = allMlfbProductData.sort(function (a, b) {
                        return (
                            Math.abs(a._400vI2tCharactersticIk - scpdSDKaMaster) -
                            Math.abs(b._400vI2tCharactersticIk - scpdSDKaMaster)
                        );
                    })[0];
                    if (letThroughOfSDSCPD != undefined) {
                        letThroughOfSDSCPDVal = letThroughOfSDSCPD._400vI2tCharactersticI2t;
                    }
                }
            }
        }
        //condition to verify
        // if (scpdSDRatingMaster >= SCPDSDvalue) isSDSCPDhighercurrent = true;
        // new changes
        if (scpdSDRatingMaster >= SDflaLine) isSDSCPDhighercurrent = true;

        //SC lower band > 10 % of LRA
        var CalLRA =
            parseFloat(SDlracontactor) +
            parseFloat(SDlracontactor) *
            parseFloat(myConstClass.scSDZoneLowerBandLRAFactor);
        if (scSDSettingLRALB >= CalLRA) isSDSCLowerBand = true;
        if (isSDSCPDhighercurrent == true && isSDSCLowerBand == true) {
            sdScpdMLFB = selectedSDMlfbMagOnlyMaster;
            sdContactorMLFBRating = scpdSDRatingMaster;
        } else {
            sdScpdMLFB = "Failed";
            sdContactorMLFBRating = "Failed";
        }
        var contactorSDSizeMaster = "";
        var contactorSD1SizeMaster = "";

        if (contactorSelected != null || contactorSelected != undefined) {
            contactorSD1SizeMaster = await calculateContactorLineDelta(
                scpdSDRatingMaster,
                SDflaphase,
                letThroughOfSDSCPDVal,
                enquiryTypeVar
            );
            contactorSDSizeMaster = await calculateContactorStar(
                scpdSDRatingMaster,
                SDStarCurrent,
                SDlracontactor,
                enquiryTypeVar
            );
        }
        /****** ----- Overload Relay selection ----- ******/
        if (overloadRelaySelected != null || overloadRelaySelected != undefined)
            await calculateSDOLR(
                SDflaphase,
                SDLOlChar,
                SDmotorStartingTime,
                contactorSD1SizeMaster,
                enquiryTypeVar
            );
    };
    var contactorSDSiriusMBCPRating,
        contactorSiriusLineDeltaRating,
        sdSelectedContactormlfb,
        contactorLineDeltaSizeMaster;
    var SDthermalRatingMaster = 0;
    var letThroughOfSDContactor = 0;
    var letThroughOfSDContactorSafetyFac = 0;
    var isContactorSDRatedcurrent = false;
    var isSDContactor10sRating = false;
    var isSDContactorletthrough = false;
    var isSDContactorLineDeltaCorrect = false;

    const calculateContactorLineDelta = async (
        scpdSDRatingMaster,
        SDflaphase,
        letThroughOfSDSCPDVal,
        enquiryTypeVar
    ) => {
        // var contactorSDSiriusMBCPRating, contactorSiriusLineDeltaRating, sdSelectedContactormlfb, contactorLineDeltaSizeMaster;
        // var SDthermalRatingMaster;
        // var letThroughOfSDContactor, letThroughOfSDContactorSafetyFac;
        // var isContactorSDRatedcurrent = false;
        // var isSDContactor10sRating = false;
        // var isSDContactorletthrough = false;
        // var isSDContactorLineDeltaCorrect = false;
        // // var isContactorLetThrough = false;

        //get data from Sirius chart by flaline
        if (enquiryType == 1 || enquiryTypeVar == 1) {
            var chartid = await getSiriusChartDOLId();
            if (motorRatingSelected == 26) {
                scpdSDRatingMaster = 97;
            }
            var siriusChartContactorLDMasterData = await getSDSiriusChartContactor(
                scpdSDRatingMaster,
                chartid
            );
            if (siriusChartContactorLDMasterData != undefined) {
                contactorSDSiriusMBCPRating =
                    siriusChartContactorLDMasterData.mpcbrating; // get contactorRating from Sirius chart table where ContactorRating > FLA (flaline variable)
                contactorSiriusLineDeltaRating =
                    siriusChartContactorLDMasterData.lineDeltaContactorRating; // get contactorRating from Sirius chart table where ContactorRating > FLA (flaline variable)
                sdSelectedContactormlfb =
                    siriusChartContactorLDMasterData.lineDeltaContactorMlfb; // get contactor from Sirius chart
                contactorLineDeltaSizeMaster =
                    siriusChartContactorLDMasterData.lineDeltaContactorSize; // get size from Sirius chart

                sdContactorMLFB = sdSelectedContactormlfb;
                sdContactorMLFBRating = contactorSiriusLineDeltaRating;
            }
        }
        if (enquiryType == 2 || enquiryTypeVar == 2) {
            //var siriusChartContactorLDMasterData = await getSDSiriusChartContactor(
            //  scpdSDRatingMaster
            //);
            //if (siriusChartContactorLDMasterData != undefined) {
            contactorSDSiriusMBCPRating = scpdSDRatingMaster; // get contactorRating from Sirius chart table where ContactorRating > FLA (flaline variable)
            //contactorSiriusLineDeltaRating =
            //    siriusChartContactorLDMasterData.lineDeltaContactorRating; // get contactorRating from Sirius chart table where ContactorRating > FLA (flaline variable)
            //sdSelectedContactormlfb =
            //  siriusChartContactorLDMasterData.lineDeltaContactorMlfb; // get contactor from Sirius chart
            //contactorLineDeltaSizeMaster =
            //    siriusChartContactorLDMasterData.lineDeltaContactorSize; // get size from Sirius chart

            //get Thermal Rating from ContactorMaster by Contactormlfb
            var contactorRatingMasterData = await getContactorSDMlfb(
                //sdSelectedContactormlfb,
                scpdSDRatingMaster,
                startingMethodClass
            );
            for (var i = 0; i < contactorRatingMasterData.length; i++) {
                if (contactorRatingMasterData != undefined) {
                    sdSelectedContactormlfb = contactorRatingMasterData[i].type;
                    contactorSiriusLineDeltaRating =
                        contactorRatingMasterData[i].ratingAc3;
                    contactorLineDeltaSizeMaster = contactorRatingMasterData[i].size;
                    //SDthermalRatingMaster = contactorRatingMasterData.thermalLoading10s; //get 10S (as per req voltage) column value from contactorMaster
                    SDthermalRatingMaster = contactorRatingMasterData[i].thermalLoading;
                    letThroughOfSDContactor =
                        (SDthermalRatingMaster *
                            SDthermalRatingMaster *
                            myConstClass.letThroughOfSDContactorThermalRatingFactor) /
                        myConstClass.letThroughOfSDContactorFactor;
                    letThroughOfSDContactorSafetyFac =
                        myFunctions.geLetThroughOfSDContactorSafetyFac(
                            letThroughOfSDContactor
                        );
                }
                //}
                if (contactorSDSiriusMBCPRating > SDflaphase)
                    isContactorSDRatedcurrent = true;
                var SDlraContactor =
                    parseFloat(SDlracontactor) +
                    parseFloat(SDlracontactor * myConstClass.SDContactorLRAFactor);
                // if (SDthermalRatingMaster > SDlraContactor) isSDContactor10sRating = true;
                // new changes
                if (SDthermalRatingMaster > SDlraContactor)
                    isSDContactor10sRating = true;

                //if (letThroughOfSDContactorSafetyFac > letThroughOfSDSCPDVal)
                //  isSDContactorletthrough = true;
                // new changes -- make it as NA
                if (
                    coordinationSelected == 2 &&
                    letThroughOfSDContactorSafetyFac > letThroughOfSDSCPDVal
                )
                    isSDContactorletthrough = true;
                else isSDContactorletthrough = false;

                if (coordinationSelected == 1) {
                    //2
                    if (isSDContactor10sRating == true) {
                        isSDContactorLineDeltaCorrect = true;
                        sdContactorMLFB = sdSelectedContactormlfb;
                        sdContactorMLFBRating = contactorSiriusLineDeltaRating;
                        console.log("SDLineDeltaContactor", isSDContactorLineDeltaCorrect);
                        break;
                    } else {
                        sdContactorMLFB = "Failed";
                        sdContactorMLFBRating = "Failed";
                    }
                }
                //confusion//3
                if (coordinationSelected == 2) {
                    if (
                        isSDContactor10sRating == true &&
                        isSDContactorletthrough == true
                    ) {
                        isSDContactorLineDeltaCorrect = true;
                        sdContactorMLFB = sdSelectedContactormlfb;
                        sdContactorMLFBRating = contactorSiriusLineDeltaRating;
                        console.log("SDLineDeltaContactor", isSDContactorLineDeltaCorrect);
                        break;
                    } else {
                        sdContactorMLFB = "Failed";
                        sdContactorMLFBRating = "Failed";
                    }
                }
            }
        }
        return contactorLineDeltaSizeMaster;
    };
    var contactorStarSiriusMBCPRating,
        contactorSiriusStarRating,
        selectedContactorStarmlfb,
        contactorStarSizeMaster;
    var StarthermalRatingMaster;
    var letThroughOfContactorStar, letThroughOfContactorStarSafetyFac;
    var isContactorStarRatedcurrent = false;
    var isSDContactorStar10sRating = false;
    var isSDContactorStarCorrect = false;

    const calculateContactorStar = async (
        scpdSDRatingMaster,
        SDStarCurrent,
        SDlracontactor,
        enquiryTypeVar
    ) => {
        // var contactorStarSiriusMBCPRating, contactorSiriusStarRating, selectedContactorStarmlfb, contactorStarSizeMaster;
        // var StarthermalRatingMaster;
        // var letThroughOfContactorStar, letThroughOfContactorStarSafetyFac;
        // var isContactorStarRatedcurrent = false;;
        // var isSDContactorStar10sRating = false;
        // var isSDContactorStarCorrect = false;
        // var isContactorLetThrough = false;
        //get data from Sirius chart by flaline
        if (enquiryType == 1 || enquiryTypeVar == 1) {
            var chartid = await getSiriusChartDOLId();
            if (motorRatingSelected == 26) {
                scpdSDRatingMaster = 97;
            }
            var siriusChartContactorStartMasterData = await getSDSiriusChartContactor(
                scpdSDRatingMaster,
                chartid
            );

            if (siriusChartContactorStartMasterData != undefined) {
                contactorStarSiriusMBCPRating =
                    siriusChartContactorStartMasterData.mpcbrating; // get contactorRating from Sirius chart table where ContactorRating > FLA (flaline variable)
                contactorSiriusStarRating =
                    siriusChartContactorStartMasterData.starContactorRating; // get contactorRating from Sirius chart table where ContactorRating > FLA (flaline variable)
                selectedContactorStarmlfb =
                    siriusChartContactorStartMasterData.starContactorMlfb; // get contactor from Sirius chart
                contactorStarSizeMaster =
                    siriusChartContactorStartMasterData.starContactorSize; // get size from Sirius chart

                //sdContactorMLFB = sdSelectedContactormlfb;
                //sdContactorMLFBRating = contactorSiriusLineDeltaRating;

                sdStarContactorMLFB = selectedContactorStarmlfb;
                sdStarContactorRating = contactorSiriusStarRating;
            }
        }

        if (enquiryType == 2 || enquiryTypeVar == 2) {
            //var siriusChartContactorStartMasterData = await getSDSiriusChartContactor(
            //  scpdSDRatingMaster
            //);

            //if (siriusChartContactorStartMasterData != undefined) {
            // contactorStarSiriusMBCPRating = scpdSDRatingMaster; // get contactorRating from Sirius chart table where ContactorRating > FLA (flaline variable)
            contactorStarSiriusMBCPRating = SDStarCurrent;
            //selectedContactorStarmlfb =
            //  siriusChartContactorStartMasterData.starContactorMlfb; // get contactor from Sirius chart

            //get Thermal Rating from ContactorMaster by Contactormlfb

            {
                /*var contactorRatingMasterData = await getContactorSDMlfb(
                      //selectedContactorStarmlfb,
                      scpdSDRatingMaster,
                      startingMethodClass
                  );*/
            }
            var contactorRatingMasterData = await getContactorSDStar(
                //selectedContactorStarmlfb,
                SDStarCurrent,
                startingMethodClass
            );

            for (var i = 0; i < contactorRatingMasterData.length; i++) {
                if (contactorRatingMasterData != undefined) {
                    selectedContactorStarmlfb = contactorRatingMasterData[i].type; // get contactor from Sirius chart
                    contactorSiriusStarRating = contactorRatingMasterData[i].ratingAc3;
                    contactorStarSizeMaster = contactorRatingMasterData[i].size; // get size from Sirius chart
                    //StarthermalRatingMaster = contactorRatingMasterData.thermalLoading10s; //get 10S (as per req voltage) column value from contactorMaster
                    StarthermalRatingMaster = contactorRatingMasterData[i].thermalLoading;
                }
                // }
                //condition to verify
                if (contactorSiriusStarRating > SDStarCurrent)
                    isContactorStarRatedcurrent = true;

                // var SDlracontactorrating =
                // parseFloat(SDlracontactor) +
                // parseFloat(SDlracontactor * myConstClass.SDContactorStarLRAFactor);

                // new changes
                var SDlracontactorrating =
                    coordinationSelected == 1
                        ? parseFloat(SDlracontactor) +
                        parseFloat(
                            SDlracontactor * myConstClass.newSDContactorStarLRAFactor
                        )
                        : coordinationSelected == 2
                            ? parseFloat(SDlracontactor) +
                            parseFloat(SDlracontactor * myConstClass.SDContactorStarLRAFactor)
                            : "";

                // if (StarthermalRatingMaster > SDlracontactorrating)
                //   isSDContactorStar10sRating = true;
                // new changes
                if (StarthermalRatingMaster > SDlracontactorrating)
                    isSDContactorStar10sRating = true;
                if (coordinationSelected == 1) {
                    if (
                        isContactorStarRatedcurrent == true &&
                        isSDContactorStar10sRating == true
                    ) {
                        isSDContactorStarCorrect = true;
                        sdStarContactorMLFB = selectedContactorStarmlfb;
                        sdStarContactorRating = contactorSiriusStarRating;
                        break;
                    } else {
                        sdStarContactorMLFB = "Failed";
                        sdStarContactorRating = "Failed";
                    }
                }
                if (coordinationSelected == 2) {
                    if (
                        isContactorStarRatedcurrent == true &&
                        isSDContactorStar10sRating == true
                    ) {
                        isSDContactorStarCorrect = true;
                        sdStarContactorMLFB = selectedContactorStarmlfb;
                        sdStarContactorRating = contactorSiriusStarRating;
                        break;
                    } else {
                        sdStarContactorMLFB = "Failed";
                        sdStarContactorRating = "Failed";
                    }
                }
            }
        }

        return contactorStarSizeMaster;
    };

    var SDOLRMinRange = 0,
        SDOLRMaxRange = 0;
    var SDOLRRatingMaster,
        SDOLRMinRangeMaster = 0,
        SDOLRMaxRangeMaster = 0,
        selectedSDOLRMlfbMaster;
    var SDolTrippingZTLRAOLR = 0,
        SDolTrippingZTLRALBOLR = 0,
        SDolTrippingZTLRAUBOLR = 0;
    var isSDOLRhighercurrent = false;
    var isSDOLRTZTLRAhigher = false;
    var isSDOLRCorrect = false;

    const calculateSDOLR = async (
        SDflaphase,
        SDLOlChar,
        SDmotorStartingTime,
        contactorSD1SizeMaster,
        enquiryTypeVar
    ) => {
        // var SDOLRMinRange, SDOLRMaxRange;
        // var SDOLRRatingMaster, SDOLRMinRangeMaster, SDOLRMaxRangeMaster, selectedSDOLRMlfbMaster;
        // var SDolTrippingZTLRAOLR = 0, SDolTrippingZTLRALBOLR, SDolTrippingZTLRAUBOLR;
        // var isSDOLRhighercurrent = false;
        // var isSDOLRTZTLRAhigher = false;
        // var isSDOLRCorrect = false;

        //Condition for OLR
        SDOLRMinRange = myFunctions.getSDOLRMinRange(
            SDflaphase,
            motorRatingSelected
        );
        SDOLRMaxRange = myFunctions.getSDOLRMaxRange(
            SDflaphase,
            motorRatingSelected
        );
        //get relay size from Sirius chart
        if (
            startingMethodSelected != "" &&
            startingMethodSelected != undefined &&
            coordinationSelected != "" &&
            coordinationSelected != undefined &&
            scpdFuseTypeSelected != "" &&
            scpdFuseTypeSelected != undefined &&
            contactorSelected != "" &&
            contactorSelected != undefined &&
            overloadRelaySelected != "" &&
            overloadRelaySelected != undefined &&
            startingMethodClass != "" &&
            startingMethodClass != undefined &&
            ((enquiryType != "" && enquiryType != undefined && role !== 2) ||
                (enquiryTypeVar != "" && enquiryTypeVar != undefined && role == 2))
        ) {
            var selectedSDOLRMlfbMasterData = "";
            if (enquiryType == 1 || enquiryTypeVar == 1) {
                var chartid = await getSiriusChartDOLId();
                ////get relay size from Sirius chart
                //get above sirius chart size data of selected range from overload relay master
                var siriusChartSDOLRSize = await getSDSiriusChartOLRMaster(
                    SDOLRMinRange,
                    SDOLRMaxRange,
                    chartid
                );

                selectedSDOLRMlfbMasterData = await getSDSelectedOLRMlfbMaster(
                    SDOLRMinRange,
                    SDOLRMaxRange,
                    siriusChartSDOLRSize
                );
                selectedSDOLRMlfbMasterData = selectedSDOLRMlfbMasterData.find(
                    ({ size }) => size === siriusChartSDOLRSize
                );
            }
            if (enquiryType == 2 || enquiryTypeVar == 2) {
                selectedSDOLRMlfbMasterData = await getSDSelectedOLRMlfbMaster(
                    SDOLRMinRange,
                    SDOLRMaxRange,
                    contactorSD1SizeMaster
                ); //doubt size
                selectedSDOLRMlfbMasterData = selectedSDOLRMlfbMasterData.find(
                    ({ size }) => size === contactorSD1SizeMaster
                );
            }

            //var siriusChartSDOLRSize = await getSDSiriusChartOLRMaster(
            //    SDOLRMinRange,
            //    SDOLRMaxRange
            //);

            //get above sirius chart size data of selected range from overload relay master
            //var selectedSDOLRMlfbMasterData = await getSelectedOLRMlfbMaster(
            //    SDOLRMinRange,
            //    SDOLRMaxRange,
            //    siriusChartSDOLRSize
            //);
            //var selectedSDOLRMlfbMasterData = await getSDSelectedOLRMlfbMaster(
            //    SDOLRMinRange,
            //    SDOLRMaxRange
            //);

            //selectedSDOLRMlfbMasterData = selectedSDOLRMlfbMasterData.find(
            //  ({ size }) => size === siriusChartOLRSize
            //);
            if (selectedSDOLRMlfbMasterData != undefined) {
                SDOLRMinRangeMaster = selectedSDOLRMlfbMasterData.minRange; //get min range based on above row get nearest range from 3RURelay table
                SDOLRMaxRangeMaster = selectedSDOLRMlfbMasterData.maxRange; //get max range based on above row get nearest range from 3RURelay table
                selectedSDOLRMlfbMaster = selectedSDOLRMlfbMasterData.mlfb; //get mlfb from 3RURelay table
                SDOLRRatingMaster = selectedSDOLRMlfbMasterData.rating; //get rating from 3RURelay master table
                //OL tripping zone time on LRA
                var getSD3PoleLFOLRData = await getOverloadRelayProductDetail(
                    selectedSDOLRMlfbMaster,
                    SDflaphase
                ); //get all (3poleLoadFactor *flaphase) values and its ta from mstRURelay master table

                var newSDPoleLFOLR = getSD3PoleLFOLRData.find(
                    ({ threePoleFactor }) => threePoleFactor >= SDLOlChar
                );

                if (newSDPoleLFOLR != undefined)
                    SDolTrippingZTLRAOLR = newSDPoleLFOLR.threePoleTa; // get values greater than lraOverload and get its ta
                //calculate Lower bound and upper bound of OL tripping zone time on LRA
                SDolTrippingZTLRALBOLR =
                    myFunctions.getSDOlTrippingZTLRALBOLR(SDolTrippingZTLRAOLR);
                SDolTrippingZTLRAUBOLR =
                    myFunctions.getSDOlTrippingZTLRAUBOLR(SDolTrippingZTLRAOLR);
            }

            //Condition to verify
            // if (SDOLRMaxRangeMaster >= SDOLRMaxRange)
            //     isSDOLRhighercurrent = true;

            // if (SDOLRMaxRangeMaster >= SDOLRMaxRange) isSDOLRhighercurrent = true;
            // new changes
            if (SDOLRMaxRangeMaster >= SDflaphase) isSDOLRhighercurrent = true;

            if (SDolTrippingZTLRALBOLR >= SDmotorStartingTime)
                isSDOLRTZTLRAhigher = true;
            if (isSDOLRhighercurrent == true && isSDOLRTZTLRAhigher == true) {
                isSDOLRCorrect = true;
                sdOverloadRelayMLFB = selectedSDOLRMlfbMaster;
                sdOverloadRelayRating = SDOLRRatingMaster;
                console.log("SDOverloadRelay", isSDOLRCorrect);
            } else {
                sdOverloadRelayMLFB = "Failed";
                sdOverloadRelayRating = "Failed";
            }

            //setSCPDOLRcondition1(isOlrhighercurrent);
            //setSCPDOLRcondition2(isOlrtztlrahigher);
            //if (isOlrhighercurrent == true && isOlrtztlrahigher == true) {
            //    isOLRCorrect = true;
            //    console.log('OverloadRelay', isOLRCorrect);
            //    console.log('OverloadRelayMasterData', selectedOLRMlfbMasterData);//for UI purpose
            //    setOLRMlfbData(selectedOLRMlfbMasterData.mlfb); // padmaraj
            //    setOLRMlfbRatingData(selectedOLRMlfbMasterData.rating);
            //}
            //else {
            //    console.log('OverloadRelayMasterData', 'No Data Found');//for UI purpose
            //}

            //checking for output screen
            //setOverloadRelayMLFB(selectedOLRMlfbMasterData.mlfb);
            //setOverloadRelayMLFBRating(selectedOLRMlfbMasterData.rating);
        }
    };
    /* ------ End Star Delta calculation -------*/

    /* ------ background calculation -------*/

    //var serviceFactor = 1; // get from textbox
    // var fanMotorFLC = 0;  // get from textbox
    var flaline;
    // var motorStartingTimeVal = 6; // get from textbox
    const [fixedMotorStartingTime, setFixedMotorStartingTime] = useState(6);
    var motorStartingTime = 0;
    var lraContactor, lraOverload;

    var scpdDOLMinRange, scpdDOLMaxRange;
    var scpdRatingMaster, scpdRatingMinRangeMaster, scpdRatingMaxRangeMaster;
    var selectedMlfbMaster,
        selectedMlfbMagOnlyMaster,
        scpdKaMaster,
        scpdSizeMaster;

    var olTrippingZTLRA,
        olTrippingZTLRAMLFB = 0;
    var olTrippingZTLRALB, olTrippingZTLRAUB;

    var scSetting,
        scSettingLRA,
        scSettingLRALB = 0,
        scSettingLRAUB = 0;
    var letThroughOfSCPD,
        letThroughOfSCPDVal = 0;

    var isScpdhighercurrent = false;
    var isOltztlrahigher = false;
    var isSclowerBand = false;
    var isMLFBCorrect = false;

    var mlfbMasterDataOP,
        mlfbMasterRatingOP,
        OLRMlfbRatingOP,
        OLRMLFBDataOP,
        LRAOLTrippingTolerenceBand;

    const [SCPDflaLine, setFlaLine] = useState("");
    const [SCPDlraContactor, setLraContactor] = useState("");
    const [SCPDlraOverload, setLraOverload] = useState("");
    const [SCPDDOLMinRange, setDOLMinRange] = useState("");
    const [SCPDDOLMaxRange, setDOLMaxRange] = useState("");
    const [SCPDRatingMaster, setRatingMaster] = useState("");
    const [SCPDKaMaster, setKaMaster] = useState("");
    const [SCPDLRAOLTrippingTime, setLRAOLTrippingTime] = useState("");
    const [SCPDLRAOLTrippingTolerenceBand, setLRAOLTrippingTolerenceBand] =
        useState("");
    const [SCPDScfactor, setScfactor] = useState("");
    const [SCPDSCLRATolerenceBand, setSCLRATolerenceBand] = useState("");
    const [SCPDscSettingLRA, setscSettingLRA] = useState("");
    const [SCPDletThroughOfSCPD, setletThroughOfSCPD] = useState("");
    const [SCPDstandardrelease, setSCPDMlfb] = useState("");
    const [SCPDSize, setSCPDSize] = useState("");
    const [SCPDCheckcCondition1, setSCPDCheckcCondition1] = useState("");
    const [SCPDCheckcCondition2, setSCPDCheckcCondition2] = useState("");
    const [SCPDCheckcCondition3, setSCPDCheckcCondition3] = useState("");

    const calculateDOL = async (motorFLC, motorLrr, enquiryTypeVar) => {
        /***** Parameter calculation for switchgear selection B.1 *****/
        //calculate FLA
        flaline = myFunctions.getFLALine(
            motorFLC,
            serviceFactor,
            coolingFanMotorCurrent
        );
        //setFlaLine(flaline);

        //calculate StartingTime
        //motorStartingTime = myFunctions.getMotorStartingTime(motorStartingTimeConstant);
        motorStartingTime = myFunctions.getMotorStartingTime(
            //fixedMotorStartingTime
            motorStartingTimeValUI
        );
        setMotorStartingTimeVal(parseFloat(motorStartingTime).toFixed(2));
        // setMotorStartingTimeValUI(motorStartingTime);

        //calculate LRA (for contactor selection)
        lraContactor = myFunctions.getLraContactor(motorFLC, motorLrr);
        //setLraContactor(lraContactor);
        lraOverload = myFunctions.getLraOverload(motorFLC, motorLrr);
        //setLraOverload(lraOverload);

        /***** SCPD selection B.2 *****/
        //calculate SCPD - DOL min max range
        scpdDOLMinRange = myFunctions.getScpdDOLMinRange(
            flaline,
            motorRatingSelected
        );
        //setDOLMinRange(scpdDOLMinRange);

        scpdDOLMaxRange = myFunctions.getScpdDOLMaxRange(
            flaline,
            motorRatingSelected
        );

        if (
            startingMethodSelected != "" &&
            startingMethodSelected != undefined &&
            coordinationSelected != "" &&
            coordinationSelected != undefined &&
            scpdFuseTypeSelected != "" &&
            scpdFuseTypeSelected != undefined &&
            contactorSelected != "" &&
            contactorSelected != undefined &&
            overloadRelaySelected != "" &&
            overloadRelaySelected != undefined &&
            startingMethodClass != "" &&
            startingMethodClass != undefined &&
            ((enquiryType != "" && enquiryType != undefined && role !== 2) ||
                (enquiryTypeVar != "" && enquiryTypeVar != undefined && role == 2))
        ) {
            if (enquiryType == 1 || enquiryTypeVar == 1) {
                var categorytype = "Tested";
                var chartid = await getSiriusChartDOLId();
                if (
                    overloadRelaySelected == 1 &&
                    startingMethodClass == myConstClass.C20 &&
                    motorRatingSelected <= 15
                ) {
                    scpdDOLMaxRange = 101;
                }

                var siriusChartMPCBSize = await getSiriusChartMPCBMaster(
                    scpdDOLMinRange,
                    scpdDOLMaxRange,
                    chartid
                );
                var selectedMlfbMasterData = await getselectedTestedMlfbMasterData(
                    scpdDOLMinRange,
                    scpdDOLMaxRange,
                    siriusChartMPCBSize
                );
                selectedMlfbMasterData = selectedMlfbMasterData.find(
                    ({ category }) => category === categorytype
                );
            }
            if (enquiryType == 2 || enquiryTypeVar == 2) {
                var categorytype = "Recommended";
                //setDOLMaxRange(scpdDOLMaxRange);
                //scpd rating
                if (
                    overloadRelaySelected == 1 &&
                    startingMethodClass == myConstClass.C20 &&
                    motorRatingSelected <= 15
                ) {
                    scpdDOLMaxRange = 101;
                }
                var selectedMlfbMasterData = await getselectedMlfbMasterData(
                    scpdDOLMinRange,
                    scpdDOLMaxRange
                ); //get nearest rating data from mstMLFB table
                //selectedMlfbMasterData = selectedMlfbMasterData.find(
                //  ({ category }) => category === "Tested"
                //);
            }

            if (selectedMlfbMasterData != undefined) {
                //if (role != 1) {
                //  //other than admin
                //  var categorytype = "Tested";
                //} else {
                //  if (enquiryType == 1) var categorytype = "Tested";
                //  if (enquiryType == 2) var categorytype = "Recommended";
                //}

                //selectedMlfbMasterData = selectedMlfbMasterData.find(
                //({ category }) => category === categorytype
                //);
                if (selectedMlfbMasterData != undefined) {
                    scpdRatingMaster = selectedMlfbMasterData.rating; //get rating from mstMLFB table
                    //setRatingMaster(scpdRatingMaster);
                    scpdRatingMinRangeMaster = selectedMlfbMasterData.minRange; //get min range based on above row get nearest range from mstMLFB table

                    scpdRatingMaxRangeMaster = selectedMlfbMasterData.maxRange;
                    //get max range based on above row get nearest range from mstMLFB table
                    //if integrated withscpd then standard release else magonly
                    if (overloadRelaySelected == 1) {
                        selectedMlfbMaster = selectedMlfbMasterData.standardRelease; //get std release mlfb from mstMLFB table, for admin it will get 2 and for other user it will get 1 value
                    } else {
                        selectedMlfbMaster = selectedMlfbMasterData.magOnly; //get mlfbMagOnly from mstMLFB table, for admin it will get 2 and for other user it will get 1 value
                    }
                    //selectedMlfbMagOnlyMaster = selectedMlfbMasterData.magOnly;//get mlfbMagOnly from mstMLFB table, for admin it will get 2 and for other user it will get 1 value
                    // setSCPDMlfb(selectedMlfbMaster);
                    scpdKaMaster = selectedMlfbMasterData.voltKa; //get ka from mstMLFB table
                    //setKaMaster(scpdKaMaster);
                    scpdSizeMaster = selectedMlfbMasterData.size; //get size from mstMLFB table
                    // setSCPDSize(scpdSizeMaster);
                    //var MotorRatingtype = motorRatingValueConverter(motorRatingSelected);
                    //Voltagelowertolerance = (parseFloat(MotorRatingtype) * 1000) / (parseFloat(scpdRatingMinRangeMaster) * 1.732 * parseFloat(motorEfficiency) * parseFloat(powerFactor));
                    //Voltagehighertolerance = (parseFloat(MotorRatingtype) * 1000) / (parseFloat(scpdRatingMaxRangeMaster) * 1.732 * parseFloat(motorEfficiency) * parseFloat(powerFactor));
                    //Voltagelowertolerancepercentage = 1 - (parseFloat(voltageVal.selectedItemValue) / parseFloat(Voltagelowertolerance));
                    //Voltagehighertolerancepercentage = 1 - (parseFloat(voltageVal.selectedItemValue) / parseFloat(Voltagehighertolerance));

                    //OL tripping zone time on LRA
                    var allMlfbProductData = await getMlfbProductData(
                        selectedMlfbMaster,
                        flaline
                    ); //get all 3poleLoadFactor values and its ta from mstMLFBProductDetail
                    if (
                        allMlfbProductData != undefined &&
                        allMlfbProductData.length > 0
                    ) {
                        var olTrippingZTLRA = allMlfbProductData.find(
                            ({ threePoleFactor }) => threePoleFactor >= lraOverload
                        );

                        if (olTrippingZTLRA != undefined)
                            olTrippingZTLRAMLFB = olTrippingZTLRA.threePoleTa; // get its ta
                        //setLRAOLTrippingTime(olTrippingZTLRAMLFB);
                        //calculate Lower bound and upper bound of OL tripping zone time on LRA
                        olTrippingZTLRALB =
                            myFunctions.getOlTrippingZTLRALB(olTrippingZTLRAMLFB);
                        olTrippingZTLRAUB =
                            myFunctions.getOlTrippingZTLRAUB(olTrippingZTLRAMLFB);
                        // setLRAOLTrippingTolerenceBand(olTrippingZTLRALB + " - " + olTrippingZTLRAUB);
                        LRAOLTrippingTolerenceBand =
                            olTrippingZTLRALB + " - " + olTrippingZTLRAUB;

                        //calculate SC zone based on LRA
                        //SC setting  things[0].event
                        scSetting = allMlfbProductData[0].shortCircuitReleaseFactor; //take always highest value
                        //setScfactor(scSetting);
                        scSettingLRA = myFunctions.getScSettingLRA(
                            scpdRatingMaxRangeMaster,
                            scSetting
                        );
                        //setscSettingLRA(scSettingLRA);
                        //calculate Lower bound and upper bound of SC zone based on LRA
                        scSettingLRALB = myFunctions.getScSettingLRALB(scSettingLRA);
                        scSettingLRAUB = myFunctions.getScSettingLRAUB(scSettingLRA);
                        //setSCLRATolerenceBand(scSettingLRALB + " - " + scSettingLRAUB);
                        //Let through of SCPD required S/C (sr. no. 6)
                        var voltagevalue = draftActiveData.isDraftEnquiry
                            ? voltageValueConverter(voltageVal)
                            : voltageVal.selectedItemValue;
                        if (voltagevalue <= 440) {
                            letThroughOfSCPD = allMlfbProductData.sort(function (a, b) {
                                return (
                                    Math.abs(a._400vI2tCharactersticIk - scpdKaMaster) -
                                    Math.abs(b._400vI2tCharactersticIk - scpdKaMaster)
                                );
                            })[0];
                            if (letThroughOfSCPD != undefined) {
                                letThroughOfSCPDVal = letThroughOfSCPD._400vI2tCharactersticI2t;
                                //setletThroughOfSCPD(letThroughOfSCPDVal);
                            }
                        }
                        if (voltagevalue >= 360 && voltagevalue <= 440) {
                            letThroughOfSCPD = allMlfbProductData.sort(function (a, b) {
                                return (
                                    Math.abs(a._400vI2tCharactersticIk - scpdKaMaster) -
                                    Math.abs(b._400vI2tCharactersticIk - scpdKaMaster)
                                );
                            })[0];
                            if (letThroughOfSCPD != undefined) {
                                letThroughOfSCPDVal = letThroughOfSCPD._400vI2tCharactersticI2t;
                                //setletThroughOfSCPD(letThroughOfSCPDVal);
                            }
                        }
                        if (voltagevalue > 440 && voltagevalue <= 500) {
                            letThroughOfSCPD = allMlfbProductData.sort(function (a, b) {
                                return (
                                    Math.abs(a._500vI2tCharactersticIk - scpdKaMaster) -
                                    Math.abs(b._500vI2tCharactersticIk - scpdKaMaster)
                                );
                            })[0];
                            if (letThroughOfSCPD != undefined) {
                                letThroughOfSCPDVal = letThroughOfSCPD._500vI2tCharactersticI2t;
                                //setletThroughOfSCPD(letThroughOfSCPDVal);
                            }
                        }
                        if (voltagevalue > 500 && voltagevalue <= 690) {
                            letThroughOfSCPD = allMlfbProductData.sort(function (a, b) {
                                return (
                                    Math.abs(a._690vI2tCharactersticIk - scpdKaMaster) -
                                    Math.abs(b._690vI2tCharactersticIk - scpdKaMaster)
                                );
                            })[0];
                            if (letThroughOfSCPD != undefined) {
                                letThroughOfSCPDVal = letThroughOfSCPD._690vI2tCharactersticI2t;
                                //setletThroughOfSCPD(letThroughOfSCPDVal);
                            }
                        }
                    }
                }
            }

            //Condition to verify DOL
            // if (scpdRatingMaxRangeMaster >= scpdDOLMaxRange) isScpdhighercurrent = true;
            // new changes
            if (scpdRatingMaxRangeMaster >= flaline) isScpdhighercurrent = true;

            // if (olTrippingZTLRALB >= motorStartingTime)
            //     isOltztlrahigher = true;
            var b = parseInt(motorStartingTimeValUI);

            if (olTrippingZTLRALB >= motorStartingTime) {
                isOltztlrahigher = true;
            } else {
                if (isOltztlrahigher == false) {
                    //Check if class 10
                    if (b <= 10) {
                        if (motorStartingTime <= 8) isOltztlrahigher = true;
                        else isOltztlrahigher = false;
                    }
                    //Check if class 20

                    if (b >= 10 && b <= 20) {
                        if (motorStartingTime <= 16) {
                            isOltztlrahigher = true;
                        }
                        else isOltztlrahigher = false;
                    }
                }
            }

            if (scSettingLRALB > myConstClass.scLowerBandFactor * lraContactor)
                isSclowerBand = true;

            if (
                isScpdhighercurrent == true &&
                isOltztlrahigher == true &&
                isSclowerBand == true
            ) {
                isMLFBCorrect = true;
                console.log("Mlfb", isMLFBCorrect);
                console.log("selectedMlfbMasterData", selectedMlfbMaster); //for UI Purpose
                mlfbMasterDataOP = selectedMlfbMaster;
                //setMlfbMasterData(selectedMlfbMasterData.standardRelease);
                // setMlfbMasterData(selectedMlfbMasterData.magRelease); // to be checked later
                //setMlfbMasterRatingData(selectedMlfbMasterData.rating);
                mlfbMasterRatingOP = selectedMlfbMasterData.rating;
            } else {
                mlfbMasterDataOP = "Failed";
                mlfbMasterRatingOP = "Failed";
                console.log("No Data found.");
            }
        }
        //if (motorRatingSelected == 26) {
        //    scpdRatingMaxRangeMaster = 97;
        //}

        //setSCPDCheckcCondition1(isScpdhighercurrent);
        //setSCPDCheckcCondition2(isOltztlrahigher);
        //setSCPDCheckcCondition3(isSclowerBand);

        /***** Contactor selection B.3  *****/
        //if (contactor != null || contactor != undefined)//command it for getting contactor as array value
        var contactorSelectedSize = "";
        if (contactorSelected != null || contactorSelected != undefined) {
            contactorSelectedSize = await calculateContactor(
                scpdRatingMaxRangeMaster,
                lraContactor,
                letThroughOfSCPDVal
            );
        }

        /****** ----- Overload Relay selection ----- ******/
        //if (overloadRelaySelected != null || overloadRelaySelected != undefined || overloadRelaySelected != "")
        //    await calculateOLR(flaline, motorStartingTime, lraOverload);
        if (
            overloadRelaySelected != null &&
            overloadRelaySelected != undefined &&
            overloadRelaySelected != ""
        )
            await calculateOLR(
                flaline,
                motorStartingTime,
                lraOverload,
                contactorSelectedSize,
                enquiryTypeVar
            );
    };

    var contactorRatingMaster = "",
        contactorSiriusRatingMaster = "",
        selectedContactorMaster = "",
        contactorSizeMaster = "";
    var thermalRatingMaster = "";
    var letThroughOfContactor = 0,
        letThroughOfContactorSafetyFac = 0;
    var isContactorRatedcurrent = false;
    var isContactor10sRating = false;
    var isContactorLetThrough = false;
    const [SCPDContactorSiriusRatingMaster, setSCPDContactorSiriusRatingMaster] =
        useState("");
    const [SCPDContactorRatingMaster, setSCPDContactorRatingMaster] =
        useState("");
    const [SCPDContactorMlfb, setSCPDContactorMlfb] = useState("");
    const [SCPDContactorSize, setSCPDContactorSize] = useState("");
    const [SCPDContactorthermalrating, setSCPDContactorthermalrating] =
        useState("");
    const [SCPDContactorLetthrough, setSCPDContactorLetthrough] = useState("");
    const [SCPDContactorsafetyfactor, setSCPDContactorsafetyfactor] =
        useState("");
    const [SCPDContactorcondition1, setSCPDContactorcondition1] = useState("");
    const [SCPDContactorcondition2, setSCPDContactorcondition2] = useState("");
    const [SCPDContactorcondition3, setSCPDContactorcondition3] = useState("");

    /***** Contactor selection B.3  *****/
    async function calculateContactor(
        scpdRatingMaxRangeMaster,
        lraContactor,
        letThroughOfSCPDVal
    ) {
        //var contactorRatingMaster, contactorSiriusRatingMaster, selectedContactorMaster, contactorSizeMaster;
        // var thermalRatingMaster;
        // var letThroughOfContactor, letThroughOfContactorSafetyFac;
        // var isContactorRatedcurrent = false;
        // var isContactor10sRating = false;
        // var isContactorLetThrough = false;

        //get data from Siius chart by flalinevar
        if (enquiryType == 1) {
            var chartid = await getSiriusChartDOLId();
            if (motorRatingSelected == 26) {
                scpdRatingMaxRangeMaster = 97;
            }
            if (
                overloadRelaySelected == 1 &&
                startingMethodClass == myConstClass.C20 &&
                motorRatingSelected <= 15
            ) {
                scpdRatingMaxRangeMaster = 100;
            }
            var siriusChartContactorMasterData = await getSiriusChartContactorMaster(
                scpdRatingMaxRangeMaster,
                chartid
            );
            //siriusChartContactorMasterData = siriusChartContactorMasterData.find(
            //  ({ mpcbrating }) => mpcbrating >= scpdRatingMaxRangeMaster
            //);
            //new changes
            if (siriusChartContactorMasterData != undefined) {
                contactorSiriusRatingMaster = siriusChartContactorMasterData.mpcbrating; // get contactorRating from Sirius chart table where ContactorRating > FLA (flaline variable)
                contactorRatingMaster = siriusChartContactorMasterData.contactorRating; // get contactorRating from Sirius chart table where ContactorRating > FLA (flaline variable)
                selectedContactorMaster = siriusChartContactorMasterData.mlfb; // get contactor from Sirius chart
                contactorSizeMaster = siriusChartContactorMasterData.contactorSize;
                //for output
                siriusChartContactorData = siriusChartContactorMasterData.mlfb;
                siriusChartContactorRatingData =
                    siriusChartContactorMasterData.contactorRating;
            }
        } else {
            //var siriusChartContactorMasterData = await getSiriusChartContactorMaster(
            //  scpdRatingMaxRangeMaster
            //);
            contactorSiriusRatingMaster = scpdRatingMaxRangeMaster;
            if (
                overloadRelaySelected == 1 &&
                startingMethodClass == myConstClass.C20 &&
                motorRatingSelected <= 15
            ) {
                scpdRatingMaxRangeMaster = 830;
            }
            var contactorRatingMasterData = await getContactorRatingMaster(
                scpdRatingMaxRangeMaster,
                startingMethodClass
            );

            for (var i = 0; i < contactorRatingMasterData.length; i++) {
                //if (siriusChartContactorMasterData != undefined) {
                // get contactorRating from Sirius chart table where ContactorRating > FLA (flaline variable)
                //contactorRatingMaster = siriusChartContactorMasterData[i].contactorRating; // get contactorRating from Sirius chart table where ContactorRating > FLA (flaline variable)
                //contactorSizeMaster = siriusChartContactorMasterData[i].contactorSize; // get size from Sirius chart
                //setSCPDContactorSiriusRatingMaster(contactorSiriusRatingMaster);
                //setSCPDContactorRatingMaster(contactorRatingMaster);
                //setSCPDContactorMlfb(selectedContactorMaster);
                //setSCPDContactorSize(contactorSizeMaster);
                //get Thermal Rating from ContactorMaster by contactorRatingMaster variable value
                //var contactorRatingMasterData = await getContactorRatingMaster(contactorRatingMaster);
                //Based on Type
                //var contactorRatingMasterData = await getContactorRatingMaster(
                //  selectedContactorMaster,
                //  startingMethodClass
                //);
                //contactorRatingMasterData = contactorRatingMasterData.find(
                //    ({ type }) => type === contactorRatingMasterData[i].type);
                if (contactorRatingMasterData != undefined) {
                    //contactorSiriusRatingMaster =
                    // siriusChartContactorMasterData[i].mpcbrating;
                    selectedContactorMaster = contactorRatingMasterData[i].type; // get contactor from Sirius chart
                    contactorRatingMaster = contactorRatingMasterData[i].ratingAc3; // get contactorRating from Sirius chart table where ContactorRating > FLA (flaline variable)
                    contactorSizeMaster = contactorRatingMasterData[i].size;
                    thermalRatingMaster = contactorRatingMasterData[i].thermalLoading;
                    //thermalRatingMaster = contactorRatingMasterData.thermalLoading10s; //get 10S (as per req voltage) column value from contactorMaster
                    //setSCPDContactorthermalrating(thermalRatingMaster);
                    letThroughOfContactor =
                        (thermalRatingMaster *
                            thermalRatingMaster *
                            myConstClass.letThroughOfContactorThermalRatingFactor) /
                        myConstClass.letThroughOfContactorFactor;
                    //setSCPDContactorLetthrough(letThroughOfContactor);
                    letThroughOfContactorSafetyFac =
                        myFunctions.geLetThroughOfContactorSafetyFac(letThroughOfContactor);
                    //setSCPDContactorsafetyfactor(letThroughOfContactorSafetyFac);
                }
                //}
                //10% tolerance added in lraContactor
                var LRAContactorTolerance = myFunctions.getlracontactortolerance(
                    lraContactor,
                    coordinationSelected
                );
                //Condition to verify
                if (contactorRatingMaster > flaline) isContactorRatedcurrent = true;

                // if (thermalRatingMaster > LRAContactorTolerance)
                //   isContactor10sRating = true;
                // new changes -- verify it with function
                if (thermalRatingMaster > LRAContactorTolerance)
                    isContactor10sRating = true;

                if (letThroughOfContactorSafetyFac > letThroughOfSCPDVal)
                    isContactorLetThrough = true;
                // new changes
                // if (
                //   coordinationSelected == 2 &&
                //   letThroughOfContactorSafetyFac > letThroughOfSCPDVal
                // )
                //   isContactorLetThrough = true;
                // if (coordinationSelected == 1) isContactorLetThrough = 'NA';

                //setSCPDContactorcondition1(isContactorRatedcurrent);
                //setSCPDContactorcondition2(isContactor10sRating);
                //setSCPDContactorcondition3(isContactorLetThrough);

                // check with sneha
                if (coordinationSelected == 1) {
                    if (isContactorRatedcurrent == true && isContactor10sRating == true) {
                        console.log("Contactor", isContactorLetThrough);
                        console.log(
                            "ContactorMasterData",
                            contactorRatingMasterData[i]
                            //siriusChartContactorMasterData[i]
                        ); //for UI purpose
                        // setSiriusChartContactorData(siriusChartContactorMasterData.mlfb); // padmaraj
                        siriusChartContactorData = contactorRatingMasterData[i].type;
                        console.log(
                            "siriusChartContactorMasterData PDMJ",
                            siriusChartContactorData
                        );
                        // setSiriusChartContactorRatingData(siriusChartContactorMasterData.contactorRating);
                        siriusChartContactorRatingData =
                            contactorRatingMasterData[i].ratingAc3;
                        console.log(
                            "siriusChartContactorMasterData PDMJ",
                            contactorRatingMasterData[i]
                        );
                        break;
                    } else {
                        siriusChartContactorData = "Failed";

                        siriusChartContactorRatingData = "Failed";
                        console.log("ContactorMasterData", "No Data Found"); //for UI purpose
                    }
                }

                if (coordinationSelected == 2) {
                    if (
                        isContactorRatedcurrent == true &&
                        isContactor10sRating == true &&
                        isContactorLetThrough == true
                    ) {
                        console.log("Contactor", isContactorLetThrough);
                        console.log("ContactorMasterData", contactorRatingMasterData[i]); //for UI purpose
                        // setSiriusChartContactorData(siriusChartContactorMasterData.mlfb); // padmaraj
                        siriusChartContactorData = contactorRatingMasterData[i].type;
                        console.log(
                            "siriusChartContactorMasterData PDMJ",
                            siriusChartContactorData
                        );
                        // setSiriusChartContactorRatingData(siriusChartContactorMasterData.contactorRating);
                        siriusChartContactorRatingData =
                            contactorRatingMasterData[i].ratingAc3;
                        console.log(
                            "siriusChartContactorMasterData PDMJ",
                            contactorRatingMasterData[i]
                        );
                        break;
                    } else {
                        siriusChartContactorData = "Failed";

                        siriusChartContactorRatingData = "Failed";
                        console.log("ContactorMasterData", "No Data Found"); //for UI purpose
                    }
                }
            }
        }
        return contactorSizeMaster;

        //checking for output screen
        //setContactorMLFB(siriusChartContactorMasterData.mlfb);
        //setContactorMLFBRating(siriusChartContactorMasterData.contactorRating);
    }

    /****** ----- Overload Relay selection ----- ******/
    var OLRMinRange, OLRMaxRange;
    var OLRRatingMaster,
        OLRMinRangeMaster = 0,
        OLRMaxRangeMaster = 0,
        selectedOLRMlfbMaster;
    var olTrippingZTLRAOLR = 0,
        olTrippingZTLRALBOLR = 0,
        olTrippingZTLRAUBOLR = 0;
    var isOlrhighercurrent = false;
    var isOlrtztlrahigher = false;
    var isOLRCorrect = false;
    const [SCPDConditionforOLR, setSCPDConditionforOLR] = useState("");
    const [SCPDOLRrating, setSCPDOLRrating] = useState("");
    const [SCPDOLRRage, setSCPDOLRRage] = useState("");
    const [SCPDOLRmlfb, setSCPDOLRmlfb] = useState("");
    const [SCPDOLRtrippinglra, setSCPDOLRtrippinglra] = useState("");
    const [SCPDOLRTollarance, setSCPDOLRTollarance] = useState("");
    const [SCPDOLRcondition1, setSCPDOLRcondition1] = useState("");
    const [SCPDOLRcondition2, setSCPDOLRcondition2] = useState("");

    async function calculateOLR(
        flaline,
        motorStartingTime,
        lraOverload,
        contactorSelectedSize,
        enquiryTypeVar
    ) {
        // var OLRMinRange, OLRMaxRange;
        // var OLRRatingMaster, OLRMinRangeMaster, OLRMaxRangeMaster, selectedOLRMlfbMaster;
        // var olTrippingZTLRAOLR = 0, olTrippingZTLRALBOLR, olTrippingZTLRAUBOLR;

        // var isOlrhighercurrent = false;
        // var isOlrtztlrahigher = false;
        // var isOLRCorrect = false;

        //Condition for OLR
        OLRMinRange = myFunctions.getOLRMinRange(flaline, motorRatingSelected);
        OLRMaxRange = myFunctions.getOLRMaxRange(flaline, motorRatingSelected);
        // setSCPDConditionforOLR(OLRMinRange + " - " + OLRMaxRange);
        ////get relay size from Sirius chart

        if (
            startingMethodSelected != "" &&
            startingMethodSelected != undefined &&
            coordinationSelected != "" &&
            coordinationSelected != undefined &&
            scpdFuseTypeSelected != "" &&
            scpdFuseTypeSelected != undefined &&
            contactorSelected != "" &&
            contactorSelected != undefined &&
            overloadRelaySelected != "" &&
            overloadRelaySelected != undefined &&
            startingMethodClass != "" &&
            startingMethodClass != undefined &&
            ((enquiryType != "" && enquiryType != undefined && role !== 2) ||
                (enquiryTypeVar != "" && enquiryTypeVar != undefined && role == 2))
        ) {
            var selectedOLRMlfbMasterData = "";
            if (enquiryType == 1 || enquiryTypeVar == 1) {
                var chartid = await getSiriusChartDOLId();
                ////get relay size from Sirius chart
                //get above sirius chart size data of selected range from overload relay master

                var siriusChartOLRSize = await getSiriusChartOLRMaster(
                    OLRMinRange,
                    OLRMaxRange,
                    chartid
                );
                if (
                    overloadRelaySelected == 1 &&
                    startingMethodClass == myConstClass.C20 &&
                    motorRatingSelected <= 15
                ) {
                    OLRMaxRange = 640;
                }
                selectedOLRMlfbMasterData = await getSelectedOLRMlfbMaster(
                    OLRMinRange,
                    OLRMaxRange,
                    siriusChartOLRSize
                );
                selectedOLRMlfbMasterData = selectedOLRMlfbMasterData.find(
                    ({ size }) => size === siriusChartOLRSize
                );
            }
            if (enquiryType == 2 || enquiryTypeVar == 2) {
                if (
                    overloadRelaySelected == 1 &&
                    startingMethodClass == myConstClass.C20 &&
                    motorRatingSelected <= 15
                ) {
                    OLRMaxRange = 640;
                }

                selectedOLRMlfbMasterData = await getSelectedOLRMlfbMaster(
                    OLRMinRange,
                    OLRMaxRange,
                    contactorSelectedSize
                );
                selectedOLRMlfbMasterData = selectedOLRMlfbMasterData.find(
                    ({ size }) => size === contactorSelectedSize
                );
            }

            //var selectedOLRMlfbMasterData = await getSelectedOLRMlfbMaster(
            //  OLRMinRange,
            //  OLRMaxRange
            //);

            if (selectedOLRMlfbMasterData != undefined) {
                OLRMinRangeMaster = selectedOLRMlfbMasterData.minRange; //get min range based on above row get nearest range from 3RURelay table
                OLRMaxRangeMaster = selectedOLRMlfbMasterData.maxRange; //get max range based on above row get nearest range from 3RURelay table
                selectedOLRMlfbMaster = selectedOLRMlfbMasterData.mlfb; //get mlfb from 3RURelay table
                OLRRatingMaster = selectedOLRMlfbMasterData.rating; //get rating from 3RURelay master table
                //setSCPDOLRrating(OLRRatingMaster);
                //setSCPDOLRRage(OLRMinRangeMaster + " - " + OLRMaxRangeMaster);
                //setSCPDOLRmlfb(selectedOLRMlfbMaster);
                //OL tripping zone time on LRA
                var get3PoleLFOLRData = await getOverloadRelayProductDetail(
                    selectedOLRMlfbMaster,
                    flaline
                ); //get all (3poleLoadFactor *flaline) values and its ta from mstRURelay master table

                var newPoleLFOLR = get3PoleLFOLRData.find(
                    ({ threePoleFactor }) => threePoleFactor >= lraOverload
                );

                if (newPoleLFOLR != undefined)
                    olTrippingZTLRAOLR = newPoleLFOLR.threePoleTa; // get values greater than lraOverload and get its ta
                //setSCPDOLRtrippinglra(olTrippingZTLRAOLR);
                //calculate Lower bound and upper bound of OL tripping zone time on LRA
                olTrippingZTLRALBOLR =
                    myFunctions.getOlTrippingZTLRALBOLR(olTrippingZTLRAOLR);
                olTrippingZTLRAUBOLR =
                    myFunctions.getOlTrippingZTLRAUBOLR(olTrippingZTLRAOLR);
                //setSCPDOLRTollarance(olTrippingZTLRALBOLR + " - " + olTrippingZTLRAUBOLR);
            }

            //Condition to verify
            // if (OLRMaxRangeMaster >= OLRMaxRange) isOlrhighercurrent = true;
            // new changes
            if (OLRMaxRangeMaster >= flaline) isOlrhighercurrent = true;

            // if (olTrippingZTLRALBOLR >= motorStartingTime)
            //     isOlrtztlrahigher = true;
            if (olTrippingZTLRALBOLR >= motorStartingTime) {
                isOlrtztlrahigher = true;
            } else {
                if (isOlrtztlrahigher == false) {
                    //Check for class 10
                    if (fixedMotorStartingTime <= 10) {
                        if (motorStartingTime <= 8) isOlrtztlrahigher = true;
                        else isOlrtztlrahigher = false;
                    }
                    //else {
                    //    if (olTrippingZTLRALBOLR >= 8)
                    //        isOlrtztlrahigher = true;
                    //    else
                    //        isOlrtztlrahigher = false;

                    //}
                }
            }

            //setSCPDOLRcondition1(isOlrhighercurrent);
            //setSCPDOLRcondition2(isOlrtztlrahigher);
            //1 denoted as integrated with scpd
            if (overloadRelaySelected == 1) {
                OLRMLFBDataOP = "Integrated with SCPD";
                OLRMlfbRatingOP = "Integrated with SCPD";
            } else {
                if (isOlrhighercurrent == true && isOlrtztlrahigher == true) {
                    isOLRCorrect = true;
                    console.log("OverloadRelay", isOLRCorrect);
                    console.log("OverloadRelayMasterData", selectedOLRMlfbMasterData); //for UI purpose
                    //setOLRMlfbData(selectedOLRMlfbMasterData.mlfb); // padmaraj
                    OLRMLFBDataOP = selectedOLRMlfbMasterData.mlfb;
                    console.log(
                        "selectedOLRMlfbMasterData PDMJ",
                        selectedOLRMlfbMasterData
                    );
                    //setOLRMlfbRatingData(selectedOLRMlfbMasterData.rating);
                    OLRMlfbRatingOP = selectedOLRMlfbMasterData.rating;
                    console.log(
                        "selectedOLRMlfbMasterData PDMJ",
                        selectedOLRMlfbMasterData
                    );
                } else {
                    OLRMLFBDataOP = "Failed";
                    OLRMlfbRatingOP = "Failed";
                    console.log("OverloadRelayMasterData", "No Data Found"); //for UI purpose
                }
            }
        }

        //checking for output screen
        //setOverloadRelayMLFB(selectedOLRMlfbMasterData.mlfb);
        //setOverloadRelayMLFBRating(selectedOLRMlfbMasterData.rating);
    }
    /**** ------- ****/

    /**** ------- ****/
    //get MLFB Master data
    const getselectedMlfbMasterData = async (
        scpdDOLMinRange,
        scpdDOLMaxRange
    ) => {
        let paramMlfb = {};
        paramMlfb = {
            MinRange: scpdDOLMinRange,
            MaxRange: scpdDOLMaxRange,
            ProductGroup: mlfb,
            FuseType: fuseTypeSelected,
            Voltage: draftActiveData.isDraftEnquiry
                ? voltageValueConverter(voltageVal)
                : voltageVal.selectedItemValue, // 3 integer
            Class: startingMethodClass,
            RelayId: overloadRelaySelected,
        };
        const res = await api.post("/getselectedMlfbMasterData", paramMlfb);
        return await res.data;
    };

    const getselectedTestedMlfbMasterData = async (
        scpdDOLMinRange,
        scpdDOLMaxRange,
        mpcbSize
    ) => {
        let paramMlfb = {};
        paramMlfb = {
            MinRange: scpdDOLMinRange,
            MaxRange: scpdDOLMaxRange,
            ProductGroup: mlfb,
            FuseType: fuseTypeSelected,
            Voltage: draftActiveData.isDraftEnquiry
                ? voltageValueConverter(voltageVal)
                : voltageVal.selectedItemValue, // 3 integer
            Size: mpcbSize,
            Class: startingMethodClass,
            RelayId: overloadRelaySelected,
        };
        const res = await api.post("/getselectedTestedMlfbMasterData", paramMlfb);
        return await res.data;
    };

    //get MLFB Product Master data
    const getMlfbProductData = async (selectedMlfbMaster, flaline) => {
        let paramMlfb = {};
        paramMlfb = {
            ProductName: selectedMlfbMaster,
            flaline: flaline,
        };
        const res = await api.post("/getMlfbProductData", paramMlfb);
        return await res.data;
    };
    //get chartid for sirius master if type=tested

    const getSiriusChartDOLId = async () => {
        let paramTest = {};
        paramTest = {
            StartingMethodId: startingMethodSelected,
            SCPDId: scpdFuseTypeSelected,
            ContactorId: contactorSelected,
            RelayId: overloadRelaySelected,
            SelectionTypeId: coordinationSelected,
            VoltageId: draftActiveData.isDraftEnquiry
                ? voltageValueConverter(voltageVal)
                : voltageVal.selectedItemValue,
            Class: startingMethodClass,
        };
        const res = await api.post("/getSiriusChartDOLId", paramTest);
        return await res.data;
    }; //get Overload Relay Master data
    const getSelectedOLRMlfbMaster = async (
        OLRMinRange,
        OLRMaxRange,
        siriusChartOLRSize
    ) => {
        let paramOLRMlfb = {};
        paramOLRMlfb = {
            MinRange: OLRMinRange,
            MaxRange: OLRMaxRange,
            ProductGroup: overloadRelaySelected,
            Size: siriusChartOLRSize,
            Class: startingMethodClass,
        };
        const res = await api.post("/getSelectedOLRMlfbMaster", paramOLRMlfb);
        return await res.data;
    };
    //get SD Overload Relay Master data
    const getSDSelectedOLRMlfbMaster = async (
        SDOLRMinRange,
        SDOLRMaxRange,
        siriusChartSDOLRSize
    ) => {
        let paramOLRMlfb = {};
        paramOLRMlfb = {
            MinRange: SDOLRMinRange,
            MaxRange: SDOLRMaxRange,
            ProductGroup: overloadRelaySelected,
            Size: siriusChartSDOLRSize,
            Class: startingMethodClass,
        };
        const res = await api.post("/getSDSelectedOLRMlfbMaster", paramOLRMlfb);
        return await res.data;
    };

    //get Overload Relay Product Master data
    const getOverloadRelayProductDetail = async (
        selectedOLRMlfbMaster,
        flaline
    ) => {
        let param3PoleLFOLR = {};
        param3PoleLFOLR = {
            ProductName: selectedOLRMlfbMaster,
            flaline: flaline,
        };
        const res = await api.post(
            "/getOverloadRelayProductDetail",
            param3PoleLFOLR
        );
        return await res.data;
    };
    const getSiriusChartOLRMaster = async (OLRMinRange, OLRMaxRange, chartid) => {
        let paramContactor = {};
        paramContactor = {
            MinRange: OLRMinRange,
            MaxRange: OLRMaxRange,
            Voltage: draftActiveData.isDraftEnquiry
                ? voltageValueConverter(voltageVal)
                : voltageVal.selectedItemValue, // integer voltage value, not id
            TypeOfCordination: coordinationSelected,
            ChartId: chartid,
            Flaline: flaline,
        };
        const res = await api.post("/getSiriusChartOLRMaster", paramContactor);
        return await res.data;
    };
    //const getSiriusChartOLRMaster = async (OLRMinRange, OLRMaxRange) => {
    //  let paramContactor = {};
    //  paramContactor = {
    //    MinRange: OLRMinRange,
    //    MaxRange: OLRMaxRange,
    //    Voltage: draftActiveData.isDraftEnquiry
    //      ? voltageValueConverter(voltageVal)
    //      : voltageVal.selectedItemValue, // integer voltage value, not id
    //    TypeOfCordination: coordinationSelected,
    //  };
    //  const res = await api.post("/getSiriusChartOLRMaster", paramContactor);
    //  return await res.data;
    //};
    const getSiriusChartMPCBMaster = async (
        scpdDOLMinRange,
        scpdDOLMaxRange,
        chartid
    ) => {
        let paramContactor = {};
        paramContactor = {
            MinRange: scpdDOLMinRange,
            MaxRange: scpdDOLMaxRange,
            Voltage: draftActiveData.isDraftEnquiry
                ? voltageValueConverter(voltageVal)
                : voltageVal.selectedItemValue, // integer voltage value, not id
            ChartId: chartid,
            flaline: flaline,
        };
        const res = await api.post("/getSiriusChartMPCBMaster", paramContactor);
        return await res.data;
    };

    const getSDSiriusChartOLRMaster = async (
        SDOLRMinRange,
        SDOLRMaxRange,
        chartid
    ) => {
        let paramContactor = {};
        paramContactor = {
            MinRange: SDOLRMinRange,
            MaxRange: SDOLRMaxRange,
            Voltage: draftActiveData.isDraftEnquiry
                ? voltageValueConverter(voltageVal)
                : voltageVal.selectedItemValue, // integer voltage value, not id
            TypeOfCordination: coordinationSelected,
            ChartId: chartid,
            flaline: SDflaLine,
        };
        const res = await api.post("/getSDSiriusChartOLRMaster", paramContactor);
        return await res.data;
    };
    //get sirius mpcb data for SD tested
    const getselectedTestedSDMlfbMasterData = async (scpdSDvalue, mpcbSize) => {
        let paramMlfb = {};
        paramMlfb = {
            MaxRange: scpdSDvalue,
            ProductGroup: mlfb,
            FuseType: fuseTypeSelected,
            Voltage: draftActiveData.isDraftEnquiry
                ? voltageValueConverter(voltageVal)
                : voltageVal.selectedItemValue, // 3 intege
            Size: mpcbSize,
            Class: startingMethodClass,
        };
        const res = await api.post("/getselectedTestedSDMlfbMasterData", paramMlfb);
        return await res.data;
    };

    const getSiriusChartSDMPCBMaster = async (scpdSDvalue, chartid) => {
        let paramSDMlfb = {};
        paramSDMlfb = {
            MaxRange: scpdSDvalue,

            Voltage: draftActiveData.isDraftEnquiry
                ? voltageValueConverter(voltageVal)
                : voltageVal.selectedItemValue, // 3 intege
            ChartId: chartid,
            flaline: SDflaLine,
        };
        const res = await api.post("/getSiriusChartSDMPCBMaster", paramSDMlfb);
        return await res.data;
    };

    //get Contactor Master data
    const getSiriusChartContactorMaster = async (
        scpdRatingMaxRangeMaster,
        chartid
    ) => {
        let paramContactor = {};
        paramContactor = {
            MaxRange: scpdRatingMaxRangeMaster,
            Voltage: draftActiveData.isDraftEnquiry
                ? voltageValueConverter(voltageVal)
                : voltageVal.selectedItemValue, // integer voltage value, not id
            TypeOfCordination: coordinationSelected,
            ChartId: chartid,
            Flaline: flaline,
        };
        const res = await api.post(
            "/getSiriusChartContactorMaster",
            paramContactor
        );
        return await res.data;
    };
    const getContactorRatingMaster = async (
        scpdRatingMaxRangeMaster,
        startingMethodClass
    ) => {
        let paramContactor = {};
        paramContactor = {
            ProductId: contactorSelected,
            Id: draftActiveData.isDraftEnquiry
                ? voltageVal
                : voltageVal.selectedItemID, // integer voltage id
            Voltage: draftActiveData.isDraftEnquiry
                ? voltageValueConverter(voltageVal)
                : voltageVal.selectedItemValue, // integer voltage value
            Rating: scpdRatingMaxRangeMaster,
            //ProductName: selectedContactorMaster,
            Class: startingMethodClass,
        };
        const res = await api.post("/getContactorRatingMaster", paramContactor);
        return await res.data;
    };

    //get MLFB Master data for SD calculation
    const getselectedSDMlfbMasterData = async (scpdSDvalue) => {
        let paramMlfb = {};
        paramMlfb = {
            MaxRange: scpdSDvalue,
            ProductGroup: mlfb,
            FuseType: fuseTypeSelected,
            Voltage: draftActiveData.isDraftEnquiry
                ? voltageValueConverter(voltageVal)
                : voltageVal.selectedItemValue, // 3 intege
            Class: startingMethodClass,
            RelayId: overloadRelaySelected,
        };
        const res = await api.post("/getselectedMlfbMasterData", paramMlfb);
        return await res.data;
    };
    //get Contactor Master data
    const getSDSiriusChartContactor = async (scpdSDRatingMaster, chartid) => {
        let paramContactor = {};
        paramContactor = {
            Rating: scpdSDRatingMaster,
            Voltage: draftActiveData.isDraftEnquiry
                ? voltageValueConverter(voltageVal)
                : voltageVal.selectedItemValue, // integer voltage value, not id
            TypeOfCordination: coordinationSelected,
            ChartId: chartid,
            flaline: SDflaLine,
        };
        const res = await api.post("/getSDSiriusChartContactor", paramContactor);
        return await res.data;
    };
    const getContactorSDMlfb = async (
        // sdSelectedContactormlfb,
        scpdSDRatingMaster,

        startingMethodClass
    ) => {
        let paramContactor = {};
        paramContactor = {
            ProductId: contactorSelected,
            Id: draftActiveData.isDraftEnquiry
                ? voltageVal
                : voltageVal.selectedItemID, // integer voltage  not id
            Voltage: draftActiveData.isDraftEnquiry
                ? voltageValueConverter(voltageVal)
                : voltageVal.selectedItemValue, // integer voltage value
            Rating: scpdSDRatingMaster,
            //ProductName: sdSelectedContactormlfb,
            Class: startingMethodClass,
        };
        const res = await api.post("/getContactorSDMlfb", paramContactor);
        return await res.data;
    };

    const getContactorSDStar = async (
        // sdSelectedContactormlfb,
        //scpdSDRatingMaster,
        SDStarCurrent,
        startingMethodClass
    ) => {
        let paramContactor = {};
        paramContactor = {
            ProductId: contactorSelected,
            Id: draftActiveData.isDraftEnquiry
                ? voltageVal
                : voltageVal.selectedItemID, // integer voltage  not id
            Voltage: draftActiveData.isDraftEnquiry
                ? voltageValueConverter(voltageVal)
                : voltageVal.selectedItemValue, // integer voltage value
            //Rating: scpdSDRatingMaster,
            Rating: SDStarCurrent,
            //ProductName: sdSelectedContactormlfb,
            Class: startingMethodClass,
        };
        const res = await api.post("/getContactorSDMlfb", paramContactor);
        return await res.data;
    };

    /******** ----------- *********/

    //Api for Getting Output
    const getSystemInfeedOutput = async (generatedSystemInfeedID) => {
        let paramdetails = {
            SystemInfeedId: generatedSystemInfeedID,
        };
        const res = await api.post("/GetSystemInfeedOutput", paramdetails);
        return res.data;
        //console.log('SystemInfeed Output Details', res.data);
    };

    const getMotorRating = () => {
        api
            .get("/getMotorRating")
            .then((res) => {
                setMotorRating(res.data);
                dispatch(motorRatingInputOptions(res.data));
                console.log("MotorRating", res.data);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
            });
    };

    const getEfficiencyType = () => {
        api
            .get("/getEfficiencyType")
            .then((res) => {
                setEfficiencyType(res.data);
                dispatch(efficiencyTypeInputOptions(res.data));
                console.log("EfficiencyType", res.data);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
            });
    };
    const getStartingMethod = () => {
        api
            .get("/getStartingMethod")
            .then((res) => {
                setStartingMethod(res.data);
                console.log("StartingMethod", res.data);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
            });
    };
    const getTypeOfCoordination = () => {
        api
            .get("/getTypeOfCoordination")
            .then((res) => {
                setCoordination(res.data);
                console.log("TypeOfCoordination", res.data);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
            });
    };
    const getFuseType = () => {
        api
            .get("/getFuseType")
            .then((res) => {
                setFuseType(res.data);
                console.log("FuseType", res.data);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
            });
    };
    const getScpdFuseProduct = () => {
        api
            .get("/getScpdFuseProduct")
            .then((res) => {
                setScpdFuseType(res.data);
                console.log("SCPDFuseProduct", res.data);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
            });
    };
    const getScpdFuselessProduct = () => {
        api
            .get("/getScpdFuselessProduct")
            .then((res) => {
                setScpdFuseLessType(res.data);
                console.log("ScpdFuselessProduct", res.data);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
            });
    };
    const getContactor = () => {
        api
            .get("/getContactor")
            .then((res) => {
                setContactor(res.data);
                console.log("Contactor", res.data);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
            });
    };
    // const contactorStatic = [
    //   {
    //     id: 1,
    //     productType: "3RT",
    //     createdDate: null,
    //     modifiedDate: null,
    //     isActive: null,
    //   },
    //   {
    //     id: 2,
    //     productType: "3TF",
    //     createdDate: null,
    //     modifiedDate: null,
    //     isActive: null,
    //   },
    // ];

    const getOverloadRelayProduct = () => {
        api
            .get("/getOverloadRelayProduct")
            .then((res) => {
                setOverloadRelay(res.data);
                setOverloadRelayData(res.data);
                console.log("OverloadRelayProduct", res.data);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
            });
    };

    const handleClearDetails = () => {
        setMotorRatingSelected("");
        setEfficiencyTypeSelected("");
        setmotorFLC("");
        setMotorFLCUI("");
        setMotorLRR("");
        setMotorLRRUI("");
        setMotorEfficiency("");
        setMotorEfficiencyUI("");
        setPowerFactor("");
        setPowerFactorUI("");
        setServiceFactor(1);
        setServiceFactorUI(1);
        setMotorStartingTimeVal(6);
        setMotorStartingTimeValUI(6);
        setStartingMethodSelected("");
        setCoordinationSelected("");
        setFuseTypeSelected("");
        setScpdFuseTypeSelected("");
        setContactorSelected("");
        setOverloadRelaySelected("");
        setCoolingFanMotorCurrent(0);
        setCoolingFanMotorCurrentUI(0);
        setEnquiryType("");
        setStartingMethodClass(myConstClass.C10);
    };

    // const [dolTableData, setDolTableData] = useState([]);
    const dolTableDataVal = useSelector(
        (state) => state.dolTableDataReducer.dolTableData
    );
    const rDolTableDataVal = useSelector(
        (state) => state.rdolTableDataReducer.rdolTableData
    );
    const starDeltTableDataVal = useSelector(
        (state) => state.starDeltaTableDataReducer.starDeltaTableData
    );
    const [dolTableData, setDolTableData] = useState(dolTableDataVal || []);
    const [rdolTableData, setRdolTableData] = useState(rDolTableDataVal || []);
    const [starDeltaTableData, setStarDeltaTableData] = useState(
        starDeltTableDataVal || []
    );

    // padmaraj
    let generatedRowID = "0";
    //  let generatedSystemInfeedID = '0';

    // const [generatedRowID, setGenerateRowID] = useState(0);

    async function handleSaveClick() {
        // mandatory fields check
        if (motorRatingSelected === "") {
            handleSnackOpen("Select Motor Rating", "error");
            return;
        }
        if (efficiencyTypeSelected === "") {
            handleSnackOpen("Select Efficiency Class", "error");
            return;
        }
        if (serviceFactor === "") {
            handleSnackOpen("Select Motor Service Factor", "error");
            return;
        }
        // if (motorStartingTime === '') {
        //     handleSnackOpen("Select Motor Starting Time", "error");
        //     return;
        // }
        if (startingMethodSelected === "") {
            handleSnackOpen("Select Starting Method", "error");
            return;
        }
        if (fuseTypeSelected === "") {
            handleSnackOpen("Select Feeder Type", "error");
            return;
        }
        if (coordinationSelected === "") {
            handleSnackOpen("Select Coordination Type", "error");
            return;
        }
        if (scpdFuseTypeSelected === "") {
            handleSnackOpen("Select SCPD Fuse", "error");
            return;
        }
        if (contactorSelected === "") {
            handleSnackOpen("Select Contactor", "error");
            return;
        }
        if (overloadRelaySelected === "") {
            handleSnackOpen("Select OLPD", "error");
            return;
        }
        if (motorFLC === "") {
            handleSnackOpen("Motor FLC can't be empty", "error");
            return;
        }
        if (motorLRR === "") {
            handleSnackOpen("Motor LRR can't be empty", "error");
            return;
        }
        if (motorEfficiency === "") {
            handleSnackOpen("Motor Efficiency can't be empty", "error");
            return;
        }
        if (powerFactor === "") {
            handleSnackOpen("Power Factor can't be empty", "error");
            return;
        }
        if (serviceFactor === "") {
            handleSnackOpen("Service Factor can't be empty", "error");
            return;
        }
        if (motorStartingTimeVal === "") {
            handleSnackOpen("Motor Starting Time can't be empty", "error");
            return;
        }
        if ((role == 1 || role == 5) && enquiryType === "") {
            handleSnackOpen("Selection Type can't be empty", "error");
            return;
        }

        // Admin Enquiry Type for Normal Users
        if (role == 2) {
            // Efficiency Class - IE2, IE3, IE4 : 1,2,3
            // SCPD - 3RV : 1
            // CONTACTOR - 3TF, 3TS : 2,3
            // OLR - Int, 3UA, 3US : 1, 4, 5
            if (
                (contactorSelected == 2 || contactorSelected == 3) &&
                (overloadRelaySelected == 1 ||
                    overloadRelaySelected == 4 ||
                    overloadRelaySelected == 5)
            ) {
                enquiryTypeVar = 2;
                setEnquiryType(2); // Recommended
            }

            // CONTACTOR - 3RT : 1
            // OLR - Int, 3RU, 3RB : 1, 2, 3
            if (
                contactorSelected == 1 &&
                (overloadRelaySelected == 1 ||
                    overloadRelaySelected == 2 ||
                    overloadRelaySelected == 3)
            ) {
                enquiryTypeVar = 1;
                setEnquiryType(1); // Tested
            }
        }
        // To be Tested By Vaishali
        console.log("Enquiry Type:", enquiryType);

        setLoading(true);
        // check condition
        // if ((startingMethodSelected === 1 || startingMethodSelected === 2) && motorFLC !== '' && motorLRR !== '')
        //     await calculateDOL(motorFLC, motorLRR);

        if (motorFLC !== "" && motorLRR !== "") {
            if (startingMethodSelected === 3)
                await calculateSD(motorFLC, motorLRR, enquiryTypeVar);
            else await calculateDOL(motorFLC, motorLRR, enquiryTypeVar);
        }

        // convert ids to values for display purpose
        const motorRatingValue = motorRatingValueConverter(motorRatingSelected);
        const efficiencyClassValue = efficiencyClassValueConverter(
            efficiencyTypeSelected
        );
        const startingMethodValue = startingMethodValueConverter(
            startingMethodSelected
        );
        const coordinationValue = coordinationValueConverter(coordinationSelected);
        const fuseTypeValue = fuseTypeValueConverter(fuseTypeSelected);
        const scpdFuseTypeValue = scpdFuseValueConverter(scpdFuseTypeSelected);
        const contactorValue = contactorValueConverter(contactorSelected);
        const overloadRelayValue = overloadRelayValueConverter(
            overloadRelaySelected
        );

        // Pawan
        let mstSystemInfeedDetail = {};
        let configurationRequest = {};
        configurationRequest = {
            MotorRating: motorRatingSelected,
            Efficiency: motorEfficiency,
            MotorFlc: motorFLC,
            MotorLrr: motorLRR,
            Class: efficiencyTypeSelected,
            StartingMethod: startingMethodSelected,
            TypesOfCoordination: coordinationSelected,
            FuseType: fuseTypeSelected,
            Scpd: scpdFuseTypeSelected,
            Contactor: contactorSelected,
            OverloadRelay: overloadRelaySelected,
            CoolingFanMotorCurrent: coolingFanMotorCurrent,
            PowerFactor: powerFactor,
            ServiceFactor: serviceFactor,
            AdminEnquiryType: role !== 2 ? enquiryType : enquiryTypeVar,
            //Outputdata
            MlfbMasterData:
                startingMethodSelected === 3 ? sdScpdMLFB : mlfbMasterDataOP,
            MlfbMasterRatingData:
                startingMethodSelected === 3 ? scpdSDRatingMaster : mlfbMasterRatingOP,
            SiriusChartContactorData:
                startingMethodSelected === 3
                    ? sdContactorMLFB
                    : siriusChartContactorData,
            SiriusChartContactorRatingData:
                startingMethodSelected === 3
                    ? sdContactorMLFBRating
                    : siriusChartContactorRatingData,
            OlrMlfbData:
                startingMethodSelected === 3 ? sdOverloadRelayMLFB : OLRMLFBDataOP,
            OlrMlfbRatingData:
                startingMethodSelected === 3 ? sdOverloadRelayRating : OLRMlfbRatingOP,
            StarMLFB: startingMethodSelected === 3 ? sdStarContactorMLFB : "",
            StarContactorRating:
                startingMethodSelected === 3 ? sdStarContactorRating : "",
            //Adminview
            flaline: startingMethodSelected === 3 ? SDflaLine : flaline, //karthika
            FLAPhase: startingMethodSelected === 3 ? SDflaphase : "",
            // MotorStartingTime:
            //   startingMethodSelected === 3 ? SDmotorStartingTime : motorStartingTime,
            motorStartingTime: motorStartingTimeValUI,
            lra: startingMethodSelected === 3 ? SDlradol : "",
            lraForContactor:
                startingMethodSelected === 3 ? SDlracontactor : lraContactor,
            lraForOlselection: startingMethodSelected === 3 ? SDLOlChar : lraOverload,
            starCurrent: startingMethodSelected === 3 ? SDStarCurrent : "",
            FLALinetolerance:
                startingMethodSelected === 3
                    ? SCPDSDvalue
                    : scpdDOLMinRange.toFixed(4) + " - " + scpdDOLMaxRange.toFixed(4),
            SCPDRatingCurrent:
                startingMethodSelected === 3 ? scpdSDRatingMaster : scpdRatingMaster,
            SCPDMLFB:
                startingMethodSelected === 3
                    ? selectedSDMlfbMagOnlyMaster
                    : selectedMlfbMaster,
            SCPDSize:
                startingMethodSelected === 3 ? scpdSDSizeMaster : scpdSizeMaster,
            SCPDKA: startingMethodSelected === 3 ? scpdSDKaMaster : scpdKaMaster,
            scfactor: startingMethodSelected === 3 ? scSDSetting : scSetting,
            lraSc: startingMethodSelected === 3 ? scSDSettingLRA : scSettingLRA,
            lraSctolerance:
                startingMethodSelected === 3
                    ? scSDSettingLRALB.toFixed(4) + "-" + scSDSettingLRAUB.toFixed(4)
                    : scSettingLRALB.toFixed(4) + " - " + scSettingLRAUB.toFixed(4),
            SCPDLetThrough:
                startingMethodSelected === 3
                    ? letThroughOfSDSCPDVal.toFixed(4)
                    : letThroughOfSCPDVal.toFixed(4),
            isScpdhighercurrent:
                startingMethodSelected === 3
                    ? isSDSCPDhighercurrent === true
                        ? 1
                        : 0
                    : isScpdhighercurrent === true
                        ? 1
                        : 0,
            isOltztlrahigher:
                startingMethodSelected === 3 ? "" : isOltztlrahigher === true ? 1 : 0,
            isSclowerBand:
                startingMethodSelected === 3
                    ? isSDSCLowerBand === true
                        ? 1
                        : 0
                    : isSclowerBand === true
                        ? 1
                        : 0,
            SCPDBothCondition:
                startingMethodSelected === 3
                    ? isSDSCPDhighercurrent && isSDSCLowerBand
                        ? myConstClass.displayCorrect
                        : myConstClass.displayIncorrect
                    : isScpdhighercurrent === true &&
                        isOltztlrahigher === true &&
                        isSclowerBand === true
                        ? myConstClass.displayCorrect
                        : myConstClass.displayIncorrect,
            ContactorSiriusRatingMaster:
                startingMethodSelected === 3
                    ? contactorSDSiriusMBCPRating
                    : contactorSiriusRatingMaster,
            ContactorMLFB:
                startingMethodSelected === 3
                    ? sdSelectedContactormlfb
                    : selectedContactorMaster,
            ContactorRating:
                startingMethodSelected === 3
                    ? contactorSiriusLineDeltaRating
                    : contactorRatingMaster,
            ContactorFrameSize:
                startingMethodSelected === 3
                    ? contactorLineDeltaSizeMaster
                    : contactorSizeMaster,
            ThermalRatingCurrent:
                startingMethodSelected === 3
                    ? SDthermalRatingMaster
                    : thermalRatingMaster,
            LetThrough:
                startingMethodSelected === 3
                    ? letThroughOfSDContactor
                    : letThroughOfContactor,
            SafetyFactorValue:
                startingMethodSelected === 3
                    ? letThroughOfSDContactorSafetyFac
                    : letThroughOfContactorSafetyFac,
            isContactorRatedcurrent:
                startingMethodSelected === 3
                    ? isContactorSDRatedcurrent === true
                        ? 1
                        : 0
                    : isContactorRatedcurrent === true
                        ? 1
                        : 0,
            isContactor10sRating:
                startingMethodSelected === 3
                    ? isSDContactor10sRating === true
                        ? 1
                        : 0
                    : isContactor10sRating == true
                        ? 1
                        : 0,
            isContactorLetThrough:
                startingMethodSelected === 3
                    ? isSDContactorletthrough === true
                        ? 1
                        : 0
                    : isContactorLetThrough == true
                        ? 1
                        : 0,

            ContactorSelectionMPCB:
                startingMethodSelected === 3 ? contactorStarSiriusMBCPRating : "",
            StarContactorFrameSize:
                startingMethodSelected === 3 ? contactorStarSizeMaster : "",
            starThermalRatingCurrent:
                startingMethodSelected === 3 ? StarthermalRatingMaster : "",
            isContactorGreater:
                startingMethodSelected === 3
                    ? isContactorStarRatedcurrent === true
                        ? 1
                        : 0
                    : "",
            isContactorGreaterLra:
                startingMethodSelected === 3
                    ? isSDContactorStar10sRating === true
                        ? 1
                        : 0
                    : "",
            OLRCondition:
                startingMethodSelected === 3
                    ? SDOLRMinRange.toFixed(4) + "-" + SDOLRMaxRange.toFixed(4)
                    : OLRMinRange.toFixed(4) + "-" + OLRMaxRange.toFixed(4),
            OLRRating:
                startingMethodSelected === 3 ? SDOLRRatingMaster : OLRRatingMaster, //done
            // olrRange:
            //   startingMethodSelected === 3
            //     ? SDOLRMinRangeMaster + "-" + SDOLRMaxRangeMaster
            //     : OLRMinRangeMaster + "-" + OLRMaxRangeMaster,
            // new changes
            olrRange:
                startingMethodSelected === 3
                    ? SDOLRMinRangeMaster + "-" + SDOLRMaxRangeMaster
                    : scpdRatingMinRangeMaster + "-" + scpdRatingMaxRangeMaster,
            OLRMLFB:
                startingMethodSelected === 3
                    ? selectedSDOLRMlfbMaster
                    : selectedOLRMlfbMaster, //done
            olTrippingZoneLra:
                startingMethodSelected === 3
                    ? SDolTrippingZTLRAOLR
                    : olTrippingZTLRAOLR,
            OLRTrippingTolerance:
                startingMethodSelected === 3
                    ? SDolTrippingZTLRALBOLR.toFixed(4) +
                    "-" +
                    SDolTrippingZTLRAUBOLR.toFixed(4)
                    : olTrippingZTLRALBOLR.toFixed(4) +
                    "-" +
                    olTrippingZTLRAUBOLR.toFixed(4),
            isOlrhighercurrent:
                startingMethodSelected === 3
                    ? isSDOLRhighercurrent === true
                        ? 1
                        : 0
                    : isOlrhighercurrent === true
                        ? 1
                        : 0,
            isOlrtztlrahigher:
                startingMethodSelected === 3
                    ? isSDOLRTZTLRAhigher === true
                        ? 1
                        : 0
                    : isOlrtztlrahigher === true
                        ? 1
                        : 0,
            OLRBothCondition:
                startingMethodSelected === 3
                    ? isSDOLRCorrect && isSDOLRTZTLRAhigher
                        ? myConstClass.displayCorrect
                        : myConstClass.displayIncorrect
                    : isOlrhighercurrent === true && isOlrtztlrahigher === true
                        ? myConstClass.displayCorrect
                        : myConstClass.displayIncorrect,
        };
        const infeedIddata = localStorage.getItem("LSSystemInfeedId");
        if (infeedIddata != null) {
            generatedSystemInfeedID = infeedIddata;
            //setGeneratedSystemInfeedID(data);
        }
        mstSystemInfeedDetail = {
            SystemInfeedId: parseInt(generatedSystemInfeedID),
            ProjectName: projectNameVal, //id of motorRating ddl selected value
            CustomerName: custNameVal, //id of efficiency ddl selected value
            EndCustomer: endCustVal, //id of voltage ddl selected value from previous page
            Voltage: draftActiveData.isDraftEnquiry
                ? voltageVal
                : voltageVal.selectedItemID,
            Frequency: frequencyVal.selectedItemID,
            FaultLevel: faultLevelVal.selectedItemID,
            UserId: userDetails.id,
            SaleOffice: officeNameval,
            CreatedDate: createdDate,
            ConfigurationRequestView: configurationRequest,
        };
        // api.post('/SystemInfeedSave', mstSystemInfeedDetail)
        //     .then(function (response) {
        //         console.log('Motor Details', response.data);
        //         setTableDataFromAPI(response.data.configurationRequests);
        //         // generatedRowID = response.data.configurationRequests[0].id;
        //         setGenerateRowID(response.data.configurationRequests[0].id);
        //         //setMotorEfficiency(response.data.motorEfficiency);
        //         //setMotorLRR(response.data.motorLrr);
        //         //setmotorFLC(response.data.motorFlc);
        //         //setPowerFactor(response.data.powerFactor);

        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });

        try {
            const response = await api.post(
                "/SystemInfeedSave",
                mstSystemInfeedDetail
            );
            console.log("Motor Details", response.data);
            setTableDataFromAPI(response.data.configurationRequests);
            //const Removedata = localStorage.removeItem("myData")
            const data = localStorage.getItem("LSSystemInfeedId");
            if (data == null) {
                if (response.data.configurationRequests[0].systemInfeedId != 0) {
                    //setGeneratedSystemInfeedID(response.data.configurationRequests[0].systemInfeedId);
                    generatedSystemInfeedID =
                        response.data.configurationRequests[0].systemInfeedId;
                    localStorage.setItem(
                        "LSSystemInfeedId",
                        JSON.stringify(
                            response.data.configurationRequests[0].systemInfeedId
                        )
                    );
                }
            }
            generatedRowID = response.data.configurationRequests[0].id;

            //const data = localStorage.getItem("myData");
            //const initialValue = JSON.parse(data);
            // setGenerateRowID(response.data.configurationRequests[0].id);
            //setMotorEfficiency(response.data.motorEfficiency);
            //setMotorLRR(response.data.motorLrr);
            //setmotorFLC(response.data.motorFlc);
            //setPowerFactor(response.data.powerFactor);
        } catch (error) {
            console.log(error);
        }
        var result = await getSystemInfeedOutput(parseInt(generatedSystemInfeedID));
        console.log("SystemInfeed Output Details", result);

        // var configresult = result.find(({ systemInfeedId }) => systemInfeedId >= parseInt(generatedSystemInfeedID));
        //let paramdetails = {
        //    "SystemInfeedId": parseInt(generatedSystemInfeedID),
        //};
        //const res = await api.post('/GetSystemInfeedOutput', paramdetails )
        ////return await res.data;
        //console.log('SystemInfeed Output Details', res.data);

        let newRowForDisplay = [...tableDataToDisplay];

        // change here
        const newlyCreatedRowDisplay = {
            id: generatedRowID,
            systemInfeedId: generatedSystemInfeedID,
            motorRating: motorRatingValue,
            efficiency: motorEfficiency,
            motorFlc: motorFLC,
            class: efficiencyClassValue,
            startingMethod: startingMethodValue,
            typesOfCoordination: coordinationValue,
            fuseType: fuseTypeValue,
            scpd: scpdFuseTypeValue,
            contactor: contactorValue,
            overloadRelay: overloadRelayValue,

            coolingFanMotorCurrent: coolingFanMotorCurrent,
            powerFactor: powerFactor,
            serviceFactor: serviceFactor,
            adminEnquiryType: role !== 2 ? enquiryType : enquiryTypeVar,

            mlfbMasterData:
                startingMethodSelected === 3 ? sdScpdMLFB : mlfbMasterDataOP,
            mlfbMasterRatingData:
                startingMethodSelected === 3 ? scpdSDRatingMaster : mlfbMasterRatingOP,
            siriusChartContactorData:
                startingMethodSelected === 3
                    ? sdContactorMLFB
                    : siriusChartContactorData,
            siriusChartContactorRatingData:
                startingMethodSelected === 3
                    ? sdContactorMLFBRating
                    : siriusChartContactorRatingData,
            olrMlfbData:
                startingMethodSelected === 3 ? sdOverloadRelayMLFB : OLRMLFBDataOP,
            olrMlfbRatingData:
                startingMethodSelected === 3 ? sdOverloadRelayRating : OLRMlfbRatingOP,

            // only for SD output
            // sdScpdMLFB: sdScpdMLFB,
            // sdScpdRating: scpdSDRatingMaster,
            // sdContactorMLFB: sdContactorMLFB,
            // sdContactorMLFBRating: sdContactorMLFBRating,
            starMlfb2: sdStarContactorMLFB,
            starContactorRating2: sdStarContactorRating,
            // sdOverloadRelayMLFB: sdOverloadRelayMLFB,
            // sdOverloadRelayRating: sdOverloadRelayRating,
            // selection: 'tested' -- check

            // for Info Display
            // SwitchGear Data
            flaline: startingMethodSelected === 3 ? SDflaLine : flaline,
            flaphase: SDflaphase, // only for SD

            // Show calculated motor time only when user not edited the motor starting time field
            // motorStartingTime: startingMethodSelected === 3 ? (SDmotorStartingTime) : (motorStartingTime),
            // motorStartingTime:
            //   fixedMotorStartingTime == 6
            //     ? startingMethodSelected === 3
            //       ? SDmotorStartingTime.toFixed(4)
            //       : motorStartingTime.toFixed(4)
            //     : fixedMotorStartingTime.toFixed(4),
            motorStartingTime: motorStartingTimeValUI,
            lra: startingMethodSelected === 3 ? SDlradol.toFixed(4) : "",
            lraForContactor:
                startingMethodSelected === 3
                    ? SDlracontactor.toFixed(4)
                    : lraContactor.toFixed(4),
            lraForOlselection:
                startingMethodSelected === 3
                    ? SDLOlChar.toFixed(4)
                    : lraOverload.toFixed(4),
            starCurrent: startingMethodSelected === 3 ? SDStarCurrent.toFixed(4) : "",

            //SCPD Selection
            flalinetolerance:
                startingMethodSelected === 3
                    ? SCPDSDvalue.toFixed(4)
                    : scpdDOLMinRange.toFixed(4) + " - " + scpdDOLMaxRange.toFixed(4),
            scpdratingCurrent:
                startingMethodSelected === 3 ? scpdSDRatingMaster : scpdRatingMaster,
            scpdmlfb:
                startingMethodSelected === 3
                    ? selectedSDMlfbMagOnlyMaster
                    : selectedMlfbMaster,
            scpdsize:
                startingMethodSelected === 3 ? scpdSDSizeMaster : scpdSizeMaster,
            scpdka: startingMethodSelected === 3 ? scpdSDKaMaster : scpdKaMaster,
            scfactor: startingMethodSelected === 3 ? scSDSetting : scSetting,
            lraSc: startingMethodSelected === 3 ? scSDSettingLRA : scSettingLRA,
            // lraSctolerance: {scSettingLRAUB - scSettingLRAUB},
            lraSctolerance:
                startingMethodSelected === 3
                    ? scSDSettingLRALB.toFixed(4) + "-" + scSDSettingLRAUB.toFixed(4)
                    : scSettingLRALB.toFixed(4) + " - " + scSettingLRAUB.toFixed(4),
            scpdletThrough:
                startingMethodSelected === 3
                    ? letThroughOfSDSCPDVal.toFixed(4)
                    : letThroughOfSCPDVal.toFixed(4),
            isScpdhighercurrent:
                startingMethodSelected === 3
                    ? isSDSCPDhighercurrent === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo
                    : isScpdhighercurrent === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo,
            isOltztlrahigher:
                startingMethodSelected === 3
                    ? ""
                    : isOltztlrahigher === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo,
            isSclowerBand:
                startingMethodSelected === 3
                    ? isSDSCLowerBand === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo
                    : isSclowerBand === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo,
            scpdbothCondition:
                startingMethodSelected === 3
                    ? isSDSCPDhighercurrent && isSDSCLowerBand
                        ? myConstClass.displayCorrect
                        : myConstClass.displayIncorrect
                    : isScpdhighercurrent === true &&
                        isOltztlrahigher === true &&
                        isSclowerBand === true
                        ? myConstClass.displayCorrect
                        : myConstClass.displayIncorrect,

            // Contactor Line/Delta Selection
            contactorSiriusRatingMaster:
                startingMethodSelected === 3
                    ? contactorSDSiriusMBCPRating
                    : contactorSiriusRatingMaster,
            contactorMlfb:
                startingMethodSelected === 3
                    ? sdSelectedContactormlfb
                    : selectedContactorMaster,
            contactorRating:
                startingMethodSelected === 3
                    ? contactorSiriusLineDeltaRating
                    : contactorRatingMaster,
            contactorFrameSize:
                startingMethodSelected === 3
                    ? contactorLineDeltaSizeMaster
                    : contactorSizeMaster,
            thermalRatingCurrent:
                startingMethodSelected === 3
                    ? SDthermalRatingMaster
                    : thermalRatingMaster,
            letThrough:
                startingMethodSelected === 3
                    ? letThroughOfSDContactor.toFixed(4)
                    : letThroughOfContactor.toFixed(4),
            safetyFactorValue:
                startingMethodSelected === 3
                    ? letThroughOfSDContactorSafetyFac.toFixed(4)
                    : letThroughOfContactorSafetyFac.toFixed(4),
            isContactorRatedcurrent:
                startingMethodSelected === 3
                    ? isContactorSDRatedcurrent === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo
                    : isContactorRatedcurrent === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo,
            isContactor10sRating:
                startingMethodSelected === 3
                    ? isSDContactor10sRating === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo
                    : isContactor10sRating == true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo,
            isContactorLetThrough:
                startingMethodSelected === 3
                    ? isSDContactorletthrough === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo
                    : isContactorLetThrough == true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo,

            // Contactor Star Selection
            contactorSelectionMpcb:
                startingMethodSelected === 3 ? contactorStarSiriusMBCPRating : "",
            starMlfb3: startingMethodSelected === 3 ? selectedContactorStarmlfb : "",
            starContactorRating3:
                startingMethodSelected === 3 ? contactorSiriusStarRating : "",
            starContactorFrameSize:
                startingMethodSelected === 3 ? contactorStarSizeMaster : "",
            starThermalRatingCurrent:
                startingMethodSelected === 3 ? StarthermalRatingMaster : "",
            isContactorGreater:
                startingMethodSelected === 3
                    ? isContactorStarRatedcurrent === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo
                    : "",
            isContactorGreaterLra:
                startingMethodSelected === 3
                    ? isSDContactorStar10sRating === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo
                    : "",

            // Overload Realy Selection
            olrcondition:
                startingMethodSelected === 3
                    ? SDOLRMinRange.toFixed(4) + "-" + SDOLRMaxRange.toFixed(4)
                    : OLRMinRange.toFixed(4) + "-" + OLRMaxRange.toFixed(4),
            olrrating:
                startingMethodSelected === 3 ? SDOLRRatingMaster : OLRRatingMaster,
            olrRange:
                startingMethodSelected === 3
                    ? SDOLRMinRangeMaster.toFixed(4) +
                    "-" +
                    SDOLRMaxRangeMaster.toFixed(4)
                    : OLRMinRangeMaster.toFixed(4) + "-" + OLRMaxRangeMaster.toFixed(4),
            olrmlfb:
                startingMethodSelected === 3
                    ? selectedSDOLRMlfbMaster
                    : selectedOLRMlfbMaster,
            olTrippingZoneLra:
                startingMethodSelected === 3
                    ? SDolTrippingZTLRAOLR.toFixed(4)
                    : olTrippingZTLRAOLR.toFixed(4),
            olrtrippingTolerance:
                startingMethodSelected === 3
                    ? SDolTrippingZTLRALBOLR.toFixed(4) +
                    "-" +
                    SDolTrippingZTLRAUBOLR.toFixed(4)
                    : olTrippingZTLRALBOLR.toFixed(4) +
                    "-" +
                    olTrippingZTLRAUBOLR.toFixed(4),
            isOlrhighercurrent:
                startingMethodSelected === 3
                    ? isSDOLRhighercurrent === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo
                    : isOlrhighercurrent === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo,
            isOlrtztlrahigher:
                startingMethodSelected === 3
                    ? isSDOLRTZTLRAhigher === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo
                    : isOlrtztlrahigher === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo,
            olrbothCondition:
                startingMethodSelected === 3
                    ? isSDOLRCorrect && isSDOLRTZTLRAhigher
                        ? myConstClass.displayCorrect
                        : myConstClass.displayIncorrect
                    : isOlrhighercurrent === true && isOlrtztlrahigher === true
                        ? myConstClass.displayCorrect
                        : myConstClass.displayIncorrect,
        };

        newRowForDisplay.push(newlyCreatedRowDisplay);

        setTableDataToDisplay(newRowForDisplay);
        dispatch(matTableDataToDisplayAction(newRowForDisplay));

        // change here
        // storing this object in backend
        const newlyCreatedRow = {
            id: generatedRowID,
            systemInfeedId: generatedSystemInfeedID,
            motorRating: motorRatingSelected,
            efficiency: motorEfficiency,
            motorFlc: motorFLC,
            motorLrr: motorLRR,
            class: efficiencyTypeSelected,
            startingMethod: startingMethodSelected,
            typesOfCoordination: coordinationSelected,
            fuseType: fuseTypeSelected,
            scpd: scpdFuseTypeSelected,
            contactor: contactorSelected,
            overloadRelay: overloadRelaySelected,
            coolingFanMotorCurrent: coolingFanMotorCurrent,
            powerFactor: powerFactor,
            serviceFactor: serviceFactor,
            adminEnquiryType: role !== 2 ? enquiryType : enquiryTypeVar,
            mlfbMasterData:
                startingMethodSelected === 3 ? sdScpdMLFB : mlfbMasterDataOP,
            mlfbMasterRatingData:
                startingMethodSelected === 3 ? scpdSDRatingMaster : mlfbMasterRatingOP,
            siriusChartContactorData:
                startingMethodSelected === 3
                    ? sdContactorMLFB
                    : siriusChartContactorData,
            siriusChartContactorRatingData:
                startingMethodSelected === 3
                    ? sdContactorMLFBRating
                    : siriusChartContactorRatingData,
            olrMlfbData:
                startingMethodSelected === 3 ? sdOverloadRelayMLFB : OLRMLFBDataOP,
            olrMlfbRatingData:
                startingMethodSelected === 3 ? sdOverloadRelayRating : OLRMlfbRatingOP,
            starMlfb4: startingMethodSelected === 3 ? sdStarContactorMLFB : "",
            starContactorRating4:
                startingMethodSelected === 3 ? sdStarContactorRating : "",

            // new Fields to be added
            // salesOffice: officeNameval,
            // projectName: projectNameVal,
            // customerName: custNameVal,
            // endCustomer: endCustVal,
            // voltage: voltageVal,
            // frequency: frequencyVal,
            // faultLevel: faultLevelVal,

            // only for SD output
            // sdScpdMLFB: sdScpdMLFB,
            // sdScpdRating: scpdSDRatingMaster,
            // sdContactorMLFB: sdContactorMLFB,
            // sdContactorMLFBRating: sdContactorMLFBRating,
            //   starMlfb: sdStarContactorMLFB,
            //   starContactorRating: sdStarContactorRating,
            // sdOverloadRelayMLFB: sdOverloadRelayMLFB,
            // sdOverloadRelayRating: sdOverloadRelayRating,
            // selection: 'tested' -- check

            // for Info Display
            // SwitchGear Data
            flaline: startingMethodSelected === 3 ? SDflaLine : flaline,
            flaphase: SDflaphase, // only for SD

            // Show calculated motor time only when user not edited the motor starting time field
            // motorStartingTime: startingMethodSelected === 3 ? (SDmotorStartingTime) : (motorStartingTime),
            // motorStartingTime:
            //   fixedMotorStartingTime == 6
            //     ? startingMethodSelected === 3
            //       ? SDmotorStartingTime.toFixed(4)
            //       : motorStartingTime.toFixed(4)
            //     : fixedMotorStartingTime.toFixed(4),
            motorStartingTime: motorStartingTimeValUI,
            lra: startingMethodSelected === 3 ? SDlradol.toFixed(4) : "",
            lraForContactor:
                startingMethodSelected === 3
                    ? SDlracontactor.toFixed(4)
                    : lraContactor.toFixed(4),
            lraForOlselection:
                startingMethodSelected === 3
                    ? SDLOlChar.toFixed(4)
                    : lraOverload.toFixed(4),
            starCurrent: startingMethodSelected === 3 ? SDStarCurrent.toFixed(4) : "",

            //SCPD Selection
            flalinetolerance:
                startingMethodSelected === 3
                    ? SCPDSDvalue.toFixed(4)
                    : scpdDOLMinRange.toFixed(4) + " - " + scpdDOLMaxRange.toFixed(4),
            scpdratingCurrent:
                startingMethodSelected === 3 ? scpdSDRatingMaster : scpdRatingMaster,
            scpdmlfb:
                startingMethodSelected === 3
                    ? selectedSDMlfbMagOnlyMaster
                    : selectedMlfbMaster,
            scpdsize:
                startingMethodSelected === 3 ? scpdSDSizeMaster : scpdSizeMaster,
            scpdka: startingMethodSelected === 3 ? scpdSDKaMaster : scpdKaMaster,
            scfactor: startingMethodSelected === 3 ? scSDSetting : scSetting,
            lraSc: startingMethodSelected === 3 ? scSDSettingLRA : scSettingLRA,
            // lraSctolerance: {scSettingLRAUB - scSettingLRAUB},
            lraSctolerance:
                startingMethodSelected === 3
                    ? scSDSettingLRALB.toFixed(4) + "-" + scSDSettingLRAUB.toFixed(4)
                    : scSettingLRALB.toFixed(4) + " - " + scSettingLRAUB.toFixed(4),
            scpdletThrough:
                startingMethodSelected === 3
                    ? letThroughOfSDSCPDVal.toFixed(4)
                    : letThroughOfSCPDVal.toFixed(4),
            isScpdhighercurrent:
                startingMethodSelected === 3
                    ? isSDSCPDhighercurrent === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo
                    : isScpdhighercurrent === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo,
            isOltztlrahigher:
                startingMethodSelected === 3
                    ? ""
                    : isOltztlrahigher === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo,
            isSclowerBand:
                startingMethodSelected === 3
                    ? isSDSCLowerBand === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo
                    : isSclowerBand === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo,
            scpdbothCondition:
                startingMethodSelected === 3
                    ? isSDSCPDhighercurrent && isSDSCLowerBand
                        ? myConstClass.displayCorrect
                        : myConstClass.displayIncorrect
                    : isScpdhighercurrent === true &&
                        isOltztlrahigher === true &&
                        isSclowerBand === true
                        ? myConstClass.displayCorrect
                        : myConstClass.displayIncorrect,

            // Contactor Line/Delta Selection
            contactorSiriusRatingMaster:
                startingMethodSelected === 3
                    ? contactorSDSiriusMBCPRating
                    : contactorSiriusRatingMaster,
            contactorMlfb:
                startingMethodSelected === 3
                    ? sdSelectedContactormlfb
                    : selectedContactorMaster,
            contactorRating:
                startingMethodSelected === 3
                    ? contactorSiriusLineDeltaRating
                    : contactorRatingMaster,
            contactorFrameSize:
                startingMethodSelected === 3
                    ? contactorLineDeltaSizeMaster
                    : contactorSizeMaster,
            thermalRatingCurrent:
                startingMethodSelected === 3
                    ? SDthermalRatingMaster
                    : thermalRatingMaster,
            letThrough:
                startingMethodSelected === 3
                    ? letThroughOfSDContactor.toFixed(4)
                    : letThroughOfContactor.toFixed(4),
            safetyFactorValue:
                startingMethodSelected === 3
                    ? letThroughOfSDContactorSafetyFac.toFixed(4)
                    : letThroughOfContactorSafetyFac.toFixed(4),
            isContactorRatedcurrent:
                startingMethodSelected === 3
                    ? isContactorSDRatedcurrent === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo
                    : isContactorRatedcurrent === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo,
            isContactor10sRating:
                startingMethodSelected === 3
                    ? isSDContactor10sRating === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo
                    : isContactor10sRating == true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo,
            isContactorLetThrough:
                startingMethodSelected === 3
                    ? isSDContactorletthrough === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo
                    : isContactorLetThrough == true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo,

            // Contactor Star Selection
            contactorSelectionMpcb:
                startingMethodSelected === 3 ? contactorStarSiriusMBCPRating : "",
            starMlfb5: startingMethodSelected === 3 ? selectedContactorStarmlfb : "",
            starContactorRating5:
                startingMethodSelected === 3 ? contactorSiriusStarRating : "",
            starContactorFrameSize:
                startingMethodSelected === 3 ? contactorStarSizeMaster : "",
            starThermalRatingCurrent:
                startingMethodSelected === 3 ? StarthermalRatingMaster : "",
            isContactorGreater:
                startingMethodSelected === 3
                    ? isContactorStarRatedcurrent === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo
                    : "",
            isContactorGreaterLra:
                startingMethodSelected === 3
                    ? isSDContactorStar10sRating === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo
                    : "",

            // Overload Realy Selection
            olrcondition:
                startingMethodSelected === 3
                    ? SDOLRMinRange.toFixed(4) + "-" + SDOLRMaxRange.toFixed(4)
                    : OLRMinRange.toFixed(4) + "-" + OLRMaxRange.toFixed(4),
            olrrating:
                startingMethodSelected === 3 ? SDOLRRatingMaster : OLRRatingMaster,
            olrRange:
                startingMethodSelected === 3
                    ? SDOLRMinRangeMaster.toFixed(4) +
                    "-" +
                    SDOLRMaxRangeMaster.toFixed(4)
                    : OLRMinRangeMaster.toFixed(4) + "-" + OLRMaxRangeMaster.toFixed(4),
            olrmlfb:
                startingMethodSelected === 3
                    ? selectedSDOLRMlfbMaster
                    : selectedOLRMlfbMaster,
            olTrippingZoneLra:
                startingMethodSelected === 3
                    ? SDolTrippingZTLRAOLR.toFixed(4)
                    : olTrippingZTLRAOLR.toFixed(4),
            olrtrippingTolerance:
                startingMethodSelected === 3
                    ? SDolTrippingZTLRALBOLR.toFixed(4) +
                    "-" +
                    SDolTrippingZTLRAUBOLR.toFixed(4)
                    : olTrippingZTLRALBOLR.toFixed(4) +
                    "-" +
                    olTrippingZTLRAUBOLR.toFixed(4),
            isOlrhighercurrent:
                startingMethodSelected === 3
                    ? isSDOLRhighercurrent === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo
                    : isOlrhighercurrent === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo,
            isOlrtztlrahigher:
                startingMethodSelected === 3
                    ? isSDOLRTZTLRAhigher === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo
                    : isOlrtztlrahigher === true
                        ? myConstClass.displayYes
                        : myConstClass.displayNo,
            olrbothCondition:
                startingMethodSelected === 3
                    ? isSDOLRCorrect && isSDOLRTZTLRAhigher
                        ? myConstClass.displayCorrect
                        : myConstClass.displayIncorrect
                    : isOlrhighercurrent === true && isOlrtztlrahigher === true
                        ? myConstClass.displayCorrect
                        : myConstClass.displayIncorrect,
        };

        let rowDataToAdd = [...matTableData];
        rowDataToAdd.push(newlyCreatedRow);

        setMatTableData(rowDataToAdd);
        dispatch(matTableDataAction(rowDataToAdd));

        // if (startingMethodSelected == 1) {
        //     let dolRowDataToAdd = [...dolTableData];
        //     dolRowDataToAdd.push(newlyCreatedRow);
        //     setDolTableData(dolRowDataToAdd);
        //     dispatch(dolTableDataAction(dolTableData));
        // }

        switch (startingMethodSelected) {
            case 1:
                let dolRowDataToAdd = [...dolTableData];
                dolRowDataToAdd.push(newlyCreatedRow);
                setDolTableData(dolRowDataToAdd);
                console.log("DOL table", dolRowDataToAdd);
                dispatch(dolTableDataAction(dolRowDataToAdd));
                break;
            case 2:
                let rdolRowDataToAdd = [...rdolTableData];
                rdolRowDataToAdd.push(newlyCreatedRow);
                setRdolTableData(rdolRowDataToAdd);
                console.log("RDOl", rdolRowDataToAdd);
                dispatch(rdolTableDataAction(rdolRowDataToAdd));
                break;
            case 3:
                let starDeltaRowDataToAdd = [...starDeltaTableData];
                starDeltaRowDataToAdd.push(newlyCreatedRow);
                setStarDeltaTableData(starDeltaRowDataToAdd);
                console.log("starde", starDeltaRowDataToAdd);
                dispatch(starDeltaTableDataAction(starDeltaRowDataToAdd));
                break;
            default:
                return;
        }

        handleClose();
        handleSnackOpen("Added Successfully", "success");
    }

    // edit row
    async function handleUpdateClick() {
        console.log("Row Update Clicked");

        // mandatory fields check
        if (motorRatingSelected === "") {
            handleSnackOpen("Select Motor Rating", "error");
            return;
        }
        if (efficiencyTypeSelected === "") {
            handleSnackOpen("Select Efficiency Class", "error");
            return;
        }
        if (serviceFactor === "") {
            handleSnackOpen("Select Motor Service Factor", "error");
            return;
        }
        if (startingMethodSelected === "") {
            handleSnackOpen("Select Starting Method", "error");
            return;
        }
        if (fuseTypeSelected === "") {
            handleSnackOpen("Select Feeder Type", "error");
            return;
        }
        if (coordinationSelected === "") {
            handleSnackOpen("Select Coordination Type", "error");
            return;
        }
        if (scpdFuseTypeSelected === "") {
            handleSnackOpen("Select SCPD Fuse", "error");
            return;
        }
        if (contactorSelected === "") {
            handleSnackOpen("Select Contactor", "error");
            return;
        }
        if (overloadRelaySelected === "") {
            handleSnackOpen("Select OLPD", "error");
            return;
        }
        if (motorFLC === "") {
            handleSnackOpen("Motor FLC can't be empty", "error");
            return;
        }
        if (motorLRR === "") {
            handleSnackOpen("Motor LRR can't be empty", "error");
            return;
        }
        if (motorEfficiency === "") {
            handleSnackOpen("Motor Efficiency can't be empty", "error");
            return;
        }
        if (powerFactor === "") {
            handleSnackOpen("Power Factor can't be empty", "error");
            return;
        }
        if (serviceFactor === "") {
            handleSnackOpen("Service Factor can't be empty", "error");
            return;
        }
        if (motorStartingTimeVal === "") {
            handleSnackOpen("Motor Starting Time can't be empty", "error");
            return;
        }
        if ((role == 1 || role == 5) && enquiryType === "") {
            handleSnackOpen("Selection Type can't be empty", "error");
            return;
        }

        if (role == 2) {
            // Efficiency Class - IE2, IE3, IE4 : 1,2,3
            // SCPD - 3RV : 1
            // CONTACTOR - 3TF, 3TS : 2,3
            // OLR - Int, 3UA, 3US : 1, 4, 5
            if (
                (contactorSelected == 2 || contactorSelected == 3) &&
                (overloadRelaySelected == 1 ||
                    overloadRelaySelected == 4 ||
                    overloadRelaySelected == 5)
            ) {
                // Recommended
                enquiryTypeVar = 2;
                setEnquiryType(2);
            }

            // CONTACTOR - 3RT : 1
            // OLR - Int, 3RU, 3RB : 1, 2, 3
            if (
                contactorSelected == 1 &&
                (overloadRelaySelected == 1 ||
                    overloadRelaySelected == 2 ||
                    overloadRelaySelected == 3)
            ) {
                // Tested
                enquiryTypeVar = 1;
                setEnquiryType(1);
            }
        }

        setLoading(true);
        let configutationId = parseInt(editRowData.id);
        if (configutationId > 0) {
            let updatedconfigurationRequest = {};
            let updatedconfigurationRequestDB = {};

            if (
                (startingMethodSelected === 1 || startingMethodSelected === 2) &&
                motorFLC !== "" &&
                motorLRR !== ""
            ) {
                await calculateDOL(motorFLC, motorLRR);
            } else if (
                startingMethodSelected === 3 &&
                motorFLC !== "" &&
                motorLRR !== ""
            ) {
                await calculateSD(motorFLC, motorLRR, enquiryTypeVar);
            }

            updatedconfigurationRequestDB = {
                userid: userDetails.id,
                id: editRowData.id,
                systemInfeedId: editRowData.systemInfeedId,
                motorRating: motorRatingSelected,
                efficiency: motorEfficiency,
                motorFlc: motorFLC,
                motorLrr: motorLRR,
                class: efficiencyTypeSelected,
                startingMethod: startingMethodSelected,
                typesOfCoordination: coordinationSelected,
                fuseType: fuseTypeSelected,
                scpd: scpdFuseTypeSelected,
                contactor: contactorSelected,
                overloadRelay: overloadRelaySelected,
                coolingFanMotorCurrent: coolingFanMotorCurrent,
                powerFactor: powerFactor,
                serviceFactor: serviceFactor,
                adminEnquiryType: role !== 2 ? enquiryType : enquiryTypeVar,
                mlfbMasterData:
                    startingMethodSelected === 3 ? sdScpdMLFB : mlfbMasterDataOP,
                mlfbMasterRatingData:
                    startingMethodSelected === 3
                        ? scpdSDRatingMaster
                        : mlfbMasterRatingOP,
                siriusChartContactorData:
                    startingMethodSelected === 3
                        ? sdContactorMLFB
                        : siriusChartContactorData,
                siriusChartContactorRatingData:
                    startingMethodSelected === 3
                        ? sdContactorMLFBRating
                        : siriusChartContactorRatingData,
                olrMlfbData:
                    startingMethodSelected === 3 ? sdOverloadRelayMLFB : OLRMLFBDataOP,
                olrMlfbRatingData:
                    startingMethodSelected === 3
                        ? sdOverloadRelayRating
                        : OLRMlfbRatingOP,

                // only for SD output
                sdScpdMLFB: sdScpdMLFB,
                sdScpdRating: scpdSDRatingMaster,
                sdContactorMLFB: sdContactorMLFB,
                sdContactorMLFBRating: sdContactorMLFBRating,
                sdStarContactorMLFB: sdStarContactorMLFB,
                sdStarContactorRating: sdStarContactorRating,
                sdOverloadRelayMLFB: sdOverloadRelayMLFB,
                sdOverloadRelayRating: sdOverloadRelayRating,
                // selection: 'tested' -- check

                // for Info Display
                // SwitchGear Data
                flaline: startingMethodSelected === 3 ? SDflaLine : flaline,
                flaphase: SDflaphase, // only for SD

                // Show calculated motor time only when user not edited the motor starting time field
                // motorStartingTime: startingMethodSelected === 3 ? (SDmotorStartingTime) : (motorStartingTime),
                // motorStartingTime:
                //   fixedMotorStartingTime == 6
                //     ? startingMethodSelected === 3
                //       ? SDmotorStartingTime.toFixed(4)
                //       : motorStartingTime.toFixed(4)
                //     : Number(fixedMotorStartingTime.toFixed(4)),
                motorStartingTime: motorStartingTimeValUI,
                lra: startingMethodSelected === 3 ? SDlradol.toFixed(4) : "",
                lraForContactor:
                    startingMethodSelected === 3
                        ? SDlracontactor.toFixed(4)
                        : lraContactor.toFixed(4),
                lraForOlselection:
                    startingMethodSelected === 3
                        ? SDLOlChar.toFixed(4)
                        : lraOverload.toFixed(4),
                starCurrent:
                    startingMethodSelected === 3 ? SDStarCurrent.toFixed(4) : "",

                //SCPD Selection
                flalinetolerance:
                    startingMethodSelected === 3
                        ? SCPDSDvalue.toFixed(4)
                        : scpdDOLMinRange.toFixed(4) + " - " + scpdDOLMaxRange.toFixed(4),
                scpdratingCurrent:
                    startingMethodSelected === 3 ? scpdSDRatingMaster : scpdRatingMaster,
                scpdmlfb:
                    startingMethodSelected === 3
                        ? selectedSDMlfbMagOnlyMaster
                        : selectedMlfbMaster,
                scpdsize:
                    startingMethodSelected === 3 ? scpdSDSizeMaster : scpdSizeMaster,
                scpdka: startingMethodSelected === 3 ? scpdSDKaMaster : scpdKaMaster,
                scfactor: startingMethodSelected === 3 ? scSDSetting : scSetting,
                lraSc: startingMethodSelected === 3 ? scSDSettingLRA : scSettingLRA,
                // lraSctolerance: {scSettingLRAUB - scSettingLRAUB},
                lraSctolerance:
                    startingMethodSelected === 3
                        ? scSDSettingLRALB.toFixed(4) + "-" + scSDSettingLRAUB.toFixed(4)
                        : scSettingLRALB.toFixed(4) + " - " + scSettingLRAUB.toFixed(4),
                scpdletThrough:
                    startingMethodSelected === 3
                        ? letThroughOfSDSCPDVal.toFixed(4)
                        : letThroughOfSCPDVal.toFixed(4),
                isScpdhighercurrent:
                    startingMethodSelected === 3
                        ? isSDSCPDhighercurrent === true
                            ? 1
                            : 0
                        : isScpdhighercurrent === true
                            ? 1
                            : 0,
                isOltztlrahigher:
                    startingMethodSelected === 3 ? "" : isOltztlrahigher === true ? 1 : 0,
                isSclowerBand:
                    startingMethodSelected === 3
                        ? isSDSCLowerBand === true
                            ? 1
                            : 0
                        : isSclowerBand === true
                            ? 1
                            : 0,
                scpdbothCondition:
                    startingMethodSelected === 3
                        ? isSDSCPDhighercurrent && isSDSCLowerBand
                            ? myConstClass.displayCorrect
                            : myConstClass.displayIncorrect
                        : isScpdhighercurrent === true &&
                            isOltztlrahigher === true &&
                            isSclowerBand === true
                            ? myConstClass.displayCorrect
                            : myConstClass.displayIncorrect,

                // Contactor Line/Delta Selection
                contactorSiriusRatingMaster:
                    startingMethodSelected === 3
                        ? contactorSDSiriusMBCPRating
                        : contactorSiriusRatingMaster,
                contactorMlfb:
                    startingMethodSelected === 3
                        ? sdSelectedContactormlfb
                        : selectedContactorMaster,
                contactorRating:
                    startingMethodSelected === 3
                        ? contactorSiriusLineDeltaRating
                        : contactorRatingMaster,
                contactorFrameSize:
                    startingMethodSelected === 3
                        ? contactorLineDeltaSizeMaster
                        : contactorSizeMaster,
                thermalRatingCurrent:
                    startingMethodSelected === 3
                        ? SDthermalRatingMaster
                        : thermalRatingMaster,
                letThrough:
                    startingMethodSelected === 3
                        ? letThroughOfSDContactor.toFixed(4)
                        : letThroughOfContactor.toFixed(4),
                safetyFactorValue:
                    startingMethodSelected === 3
                        ? letThroughOfSDContactorSafetyFac.toFixed(4)
                        : letThroughOfContactorSafetyFac.toFixed(4),
                isContactorRatedcurrent:
                    startingMethodSelected === 3
                        ? isContactorSDRatedcurrent === true
                            ? 1
                            : 0
                        : isContactorRatedcurrent === true
                            ? 1
                            : 0,
                isContactor10sRating:
                    startingMethodSelected === 3
                        ? isSDContactor10sRating === true
                            ? 1
                            : 0
                        : isContactor10sRating == true
                            ? 1
                            : 0,
                isContactorLetThrough:
                    startingMethodSelected === 3
                        ? isSDContactorletthrough === true
                            ? 1
                            : 0
                        : isContactorLetThrough == true
                            ? 1
                            : 0,

                // Contactor Star Selection
                contactorSelectionMpcb:
                    startingMethodSelected === 3 ? contactorStarSiriusMBCPRating : "",
                starMlfb6:
                    startingMethodSelected === 3 ? selectedContactorStarmlfb : "",
                starContactorRating6:
                    startingMethodSelected === 3 ? contactorSiriusStarRating : "",
                starContactorFrameSize:
                    startingMethodSelected === 3 ? contactorStarSizeMaster : "",
                starThermalRatingCurrent:
                    startingMethodSelected === 3 ? StarthermalRatingMaster : "",
                //thermalRatingCurrent:
                //startingMethodSelected === 3 ? StarthermalRatingMaster : "",
                isContactorGreater:
                    startingMethodSelected === 3
                        ? isContactorStarRatedcurrent === true
                            ? 1
                            : 0
                        : "",
                isContactorGreaterLra:
                    startingMethodSelected === 3
                        ? isSDContactorStar10sRating === true
                            ? 1
                            : 0
                        : "",

                // Overload Realy Selection
                olrcondition:
                    startingMethodSelected === 3
                        ? SDOLRMinRange.toFixed(4) + "-" + SDOLRMaxRange.toFixed(4)
                        : OLRMinRange.toFixed(4) + "-" + OLRMaxRange.toFixed(4),
                olrrating:
                    startingMethodSelected === 3 ? SDOLRRatingMaster : OLRRatingMaster,
                olrRange:
                    startingMethodSelected === 3
                        ? SDOLRMinRangeMaster.toFixed(4) +
                        "-" +
                        SDOLRMaxRangeMaster.toFixed(4)
                        : OLRMinRangeMaster.toFixed(4) + "-" + OLRMaxRangeMaster.toFixed(4),
                olrmlfb:
                    startingMethodSelected === 3
                        ? selectedSDOLRMlfbMaster
                        : selectedOLRMlfbMaster,
                olTrippingZoneLra:
                    startingMethodSelected === 3
                        ? SDolTrippingZTLRAOLR.toFixed(4)
                        : olTrippingZTLRAOLR.toFixed(4),
                olrtrippingTolerance:
                    startingMethodSelected === 3
                        ? SDolTrippingZTLRALBOLR.toFixed(4) +
                        "-" +
                        SDolTrippingZTLRAUBOLR.toFixed(4)
                        : olTrippingZTLRALBOLR.toFixed(4) +
                        "-" +
                        olTrippingZTLRAUBOLR.toFixed(4),
                isOlrhighercurrent:
                    startingMethodSelected === 3
                        ? isSDOLRhighercurrent === true
                            ? 1
                            : 0
                        : isOlrhighercurrent === true
                            ? 1
                            : 0,
                isOlrtztlrahigher:
                    startingMethodSelected === 3
                        ? isSDOLRTZTLRAhigher === true
                            ? 1
                            : 0
                        : isOlrtztlrahigher === true
                            ? 1
                            : 0,
                olrbothCondition:
                    startingMethodSelected === 3
                        ? isSDOLRCorrect && isSDOLRTZTLRAhigher
                            ? myConstClass.displayCorrect
                            : myConstClass.displayIncorrect
                        : isOlrhighercurrent === true && isOlrtztlrahigher === true
                            ? myConstClass.displayCorrect
                            : myConstClass.displayIncorrect,
            };

            updatedconfigurationRequest = {
                userid: userDetails.id,
                id: editRowData.id,
                systemInfeedId: editRowData.systemInfeedId,
                motorRating: motorRatingSelected,
                efficiency: motorEfficiency,
                motorFlc: motorFLC,
                motorLrr: motorLRR,
                class: efficiencyTypeSelected,
                startingMethod: startingMethodSelected,
                typesOfCoordination: coordinationSelected,
                fuseType: fuseTypeSelected,
                scpd: scpdFuseTypeSelected,
                contactor: contactorSelected,
                overloadRelay: overloadRelaySelected,
                coolingFanMotorCurrent: coolingFanMotorCurrent,
                powerFactor: powerFactor,
                serviceFactor: serviceFactor,
                adminEnquiryType: role !== 2 ? enquiryType : enquiryTypeVar,
                mlfbMasterData:
                    startingMethodSelected === 3 ? sdScpdMLFB : mlfbMasterDataOP,
                mlfbMasterRatingData:
                    startingMethodSelected === 3
                        ? scpdSDRatingMaster
                        : mlfbMasterRatingOP,
                siriusChartContactorData:
                    startingMethodSelected === 3
                        ? sdContactorMLFB
                        : siriusChartContactorData,
                siriusChartContactorRatingData:
                    startingMethodSelected === 3
                        ? sdContactorMLFBRating
                        : siriusChartContactorRatingData,
                olrMlfbData:
                    startingMethodSelected === 3 ? sdOverloadRelayMLFB : OLRMLFBDataOP,
                olrMlfbRatingData:
                    startingMethodSelected === 3
                        ? sdOverloadRelayRating
                        : OLRMlfbRatingOP,

                // only for SD output
                // sdScpdMLFB: sdScpdMLFB,
                // sdScpdRating: scpdSDRatingMaster,
                // sdContactorMLFB: sdContactorMLFB,
                // sdContactorMLFBRating: sdContactorMLFBRating,
                starMlfb7: sdStarContactorMLFB,
                starContactorRating7: sdStarContactorRating,
                // sdOverloadRelayMLFB: sdOverloadRelayMLFB,
                // sdOverloadRelayRating: sdOverloadRelayRating,
                // selection: 'tested' -- check

                // for Info Display
                // SwitchGear Data
                flaline: startingMethodSelected === 3 ? SDflaLine : flaline,
                flaphase: SDflaphase, // only for SD

                // Show calculated motor time only when user not edited the motor starting time field
                // motorStartingTime: startingMethodSelected === 3 ? (SDmotorStartingTime) : (motorStartingTime),
                // motorStartingTime:
                //   fixedMotorStartingTime == 6
                //     ? startingMethodSelected === 3
                //       ? SDmotorStartingTime.toFixed(4)
                //       : motorStartingTime.toFixed(4)
                //     : Number(fixedMotorStartingTime.toFixed(4)),
                motorStartingTime: motorStartingTimeValUI,
                lra: startingMethodSelected === 3 ? SDlradol.toFixed(4) : "",
                lraForContactor:
                    startingMethodSelected === 3
                        ? SDlracontactor.toFixed(4)
                        : lraContactor.toFixed(4),
                lraForOlselection:
                    startingMethodSelected === 3
                        ? SDLOlChar.toFixed(4)
                        : lraOverload.toFixed(4),
                starCurrent:
                    startingMethodSelected === 3 ? SDStarCurrent.toFixed(4) : "",

                //SCPD Selection
                flalinetolerance:
                    startingMethodSelected === 3
                        ? SCPDSDvalue.toFixed(4)
                        : scpdDOLMinRange.toFixed(4) + " - " + scpdDOLMaxRange.toFixed(4),
                scpdratingCurrent:
                    startingMethodSelected === 3 ? scpdSDRatingMaster : scpdRatingMaster,
                scpdmlfb:
                    startingMethodSelected === 3
                        ? selectedSDMlfbMagOnlyMaster
                        : selectedMlfbMaster,
                scpdsize:
                    startingMethodSelected === 3 ? scpdSDSizeMaster : scpdSizeMaster,
                scpdka: startingMethodSelected === 3 ? scpdSDKaMaster : scpdKaMaster,
                scfactor: startingMethodSelected === 3 ? scSDSetting : scSetting,
                lraSc: startingMethodSelected === 3 ? scSDSettingLRA : scSettingLRA,
                // lraSctolerance: {scSettingLRAUB - scSettingLRAUB},
                lraSctolerance:
                    startingMethodSelected === 3
                        ? scSDSettingLRALB.toFixed(4) + "-" + scSDSettingLRAUB.toFixed(4)
                        : scSettingLRALB.toFixed(4) + " - " + scSettingLRAUB.toFixed(4),
                scpdletThrough:
                    startingMethodSelected === 3
                        ? letThroughOfSDSCPDVal.toFixed(4)
                        : letThroughOfSCPDVal.toFixed(4),
                isScpdhighercurrent:
                    startingMethodSelected === 3
                        ? isSDSCPDhighercurrent === true
                            ? myConstClass.displayYes
                            : myConstClass.displayNo
                        : isScpdhighercurrent === true
                            ? "yes"
                            : myConstClass.displayNo,
                isOltztlrahigher:
                    startingMethodSelected === 3
                        ? ""
                        : isOltztlrahigher === true
                            ? myConstClass.displayYes
                            : myConstClass.displayNo,
                isSclowerBand:
                    startingMethodSelected === 3
                        ? isSDSCLowerBand === true
                            ? myConstClass.displayYes
                            : myConstClass.displayNo
                        : isSclowerBand === true
                            ? myConstClass.displayYes
                            : myConstClass.displayNo,
                scpdbothCondition:
                    startingMethodSelected === 3
                        ? isSDSCPDhighercurrent && isSDSCLowerBand
                            ? myConstClass.displayCorrect
                            : myConstClass.displayIncorrect
                        : isScpdhighercurrent === true &&
                            isOltztlrahigher === true &&
                            isSclowerBand === true
                            ? myConstClass.displayCorrect
                            : myConstClass.displayIncorrect,

                // Contactor Line/Delta Selection
                contactorSiriusRatingMaster:
                    startingMethodSelected === 3
                        ? contactorSDSiriusMBCPRating
                        : contactorSiriusRatingMaster,
                contactorMlfb:
                    startingMethodSelected === 3
                        ? sdSelectedContactormlfb
                        : selectedContactorMaster,
                contactorRating:
                    startingMethodSelected === 3
                        ? contactorSiriusLineDeltaRating
                        : contactorRatingMaster,
                contactorFrameSize:
                    startingMethodSelected === 3
                        ? contactorLineDeltaSizeMaster
                        : contactorSizeMaster,
                thermalRatingCurrent:
                    startingMethodSelected === 3
                        ? SDthermalRatingMaster
                        : thermalRatingMaster,
                letThrough:
                    startingMethodSelected === 3
                        ? letThroughOfSDContactor.toFixed(4)
                        : letThroughOfContactor.toFixed(4),
                safetyFactorValue:
                    startingMethodSelected === 3
                        ? letThroughOfSDContactorSafetyFac.toFixed(4)
                        : letThroughOfContactorSafetyFac.toFixed(4),
                isContactorRatedcurrent:
                    startingMethodSelected === 3
                        ? isContactorSDRatedcurrent === true
                            ? myConstClass.displayYes
                            : myConstClass.displayNo
                        : isContactorRatedcurrent === true
                            ? myConstClass.displayYes
                            : myConstClass.displayNo,
                isContactor10sRating:
                    startingMethodSelected === 3
                        ? isSDContactor10sRating === true
                            ? myConstClass.displayYes
                            : myConstClass.displayNo
                        : isContactor10sRating == true
                            ? myConstClass.displayYes
                            : myConstClass.displayNo,
                isContactorLetThrough:
                    startingMethodSelected === 3
                        ? isSDContactorletthrough === true
                            ? myConstClass.displayYes
                            : myConstClass.displayNo
                        : isContactorLetThrough == true
                            ? myConstClass.displayYes
                            : myConstClass.displayNo,

                // Contactor Star Selection
                contactorSelectionMpcb:
                    startingMethodSelected === 3 ? contactorStarSiriusMBCPRating : "",
                starMlfb8:
                    startingMethodSelected === 3 ? selectedContactorStarmlfb : "",
                starContactorRating8:
                    startingMethodSelected === 3 ? contactorSiriusStarRating : "",
                starContactorFrameSize:
                    startingMethodSelected === 3 ? contactorStarSizeMaster : "",
                //thermalRatingCurrent:
                starThermalRatingCurrent:
                    startingMethodSelected === 3 ? StarthermalRatingMaster : "",
                isContactorGreater:
                    startingMethodSelected === 3
                        ? isContactorStarRatedcurrent === true
                            ? myConstClass.displayYes
                            : myConstClass.displayNo
                        : "",
                isContactorGreaterLra:
                    startingMethodSelected === 3
                        ? isSDContactorStar10sRating === true
                            ? myConstClass.displayYes
                            : myConstClass.displayNo
                        : "",

                // Overload Realy Selection
                olrcondition:
                    startingMethodSelected === 3
                        ? SDOLRMinRange.toFixed(4) + "-" + SDOLRMaxRange.toFixed(4)
                        : OLRMinRange.toFixed(4) + "-" + OLRMaxRange.toFixed(4),
                olrrating:
                    startingMethodSelected === 3 ? SDOLRRatingMaster : OLRRatingMaster,
                olrRange:
                    startingMethodSelected === 3
                        ? SDOLRMinRangeMaster.toFixed(4) +
                        "-" +
                        SDOLRMaxRangeMaster.toFixed(4)
                        : OLRMinRangeMaster.toFixed(4) + "-" + OLRMaxRangeMaster.toFixed(4),
                olrmlfb:
                    startingMethodSelected === 3
                        ? selectedSDOLRMlfbMaster
                        : selectedOLRMlfbMaster,
                olTrippingZoneLra:
                    startingMethodSelected === 3
                        ? SDolTrippingZTLRAOLR.toFixed(4)
                        : olTrippingZTLRAOLR.toFixed(4),
                olrtrippingTolerance:
                    startingMethodSelected === 3
                        ? SDolTrippingZTLRALBOLR.toFixed(4) +
                        "-" +
                        SDolTrippingZTLRAUBOLR.toFixed(4)
                        : olTrippingZTLRALBOLR.toFixed(4) +
                        "-" +
                        olTrippingZTLRAUBOLR.toFixed(4),
                isOlrhighercurrent:
                    startingMethodSelected === 3
                        ? isSDOLRhighercurrent === true
                            ? myConstClass.displayYes
                            : myConstClass.displayNo
                        : isOlrhighercurrent === true
                            ? myConstClass.displayYes
                            : myConstClass.displayNo,
                isOlrtztlrahigher:
                    startingMethodSelected === 3
                        ? isSDOLRTZTLRAhigher === true
                            ? myConstClass.displayYes
                            : myConstClass.displayNo
                        : isOlrtztlrahigher === true
                            ? myConstClass.displayYes
                            : myConstClass.displayNo,
                olrbothCondition:
                    startingMethodSelected === 3
                        ? isSDOLRCorrect && isSDOLRTZTLRAhigher
                            ? myConstClass.displayCorrect
                            : myConstClass.displayIncorrect
                        : isOlrhighercurrent === true && isOlrtztlrahigher === true
                            ? myConstClass.displayCorrect
                            : myConstClass.displayIncorrect,
            };
            console.log("Updated Details", updatedconfigurationRequestDB);
            try {
                const response = await api.put(
                    "/updateSystemInfeed",
                    updatedconfigurationRequestDB
                );
                console.log("Motor Details", response.data);
                //   setTableDataFromAPI(response.data.configurationRequests);
                //    generatedRowID = response.data.configurationRequests[0].id;
                // setGenerateRowID(response.data.configurationRequests[0].id);
                //setMotorEfficiency(response.data.motorEfficiency);
                //setMotorLRR(response.data.motorLrr);
                //setmotorFLC(response.data.motorFlc);
                //setPowerFactor(response.data.powerFactor);
            } catch (error) {
                console.log(error);
            }

            //const dataDelete = [...matTableData];
            //const index = oldTableData.tableData.id;
            //dataDelete.splice(index, 1);
            //setData([...dataDelete]);

            const dataEdit = [...matTableData];
            const index = dataEdit.findIndex((x) => x.id === configutationId);

            // dataEdit.splice(index, 1);
            // dataEdit.replace
            // arr[index]=pushObj;
            dataEdit[index] = updatedconfigurationRequest;
            setMatTableData([...dataEdit]);
            dispatch(matTableDataAction(dataEdit));
            console.log("matTableData", matTableData);

            dispatch(dolTableDataAction(""));
            dispatch(rdolTableDataAction(""));
            dispatch(starDeltaTableDataAction(""));

            let dolTableData = [];
            let rdolTableData = [];
            let sdTableData = [];

            for (let i = 0; i < dataEdit.length; i++) {
                if (dataEdit[i].startingMethod === 1) {
                    let rowToPush = dataEdit[i];
                    dolTableData.push(rowToPush);
                } else if (dataEdit[i].startingMethod === 2) {
                    let rowToPush = dataEdit[i];
                    rdolTableData.push(rowToPush);
                } else {
                    let rowToPush = dataEdit[i];
                    sdTableData.push(rowToPush);
                }
            }

            dispatch(dolTableDataAction(dolTableData));
            setDolTableData(dolTableData);
            dispatch(rdolTableDataAction(rdolTableData));
            setRdolTableData(rdolTableData);
            dispatch(starDeltaTableDataAction(sdTableData));
            setStarDeltaTableData(sdTableData);

            handleClose();
        }
        handleSnackOpen("Updated Successfully", "success");
    }

    return (
        <>
            <div className="matTableClass">
                <MaterialTable
                    title="User List"
                    columns={tableColumns}
                    // data={tableDataToDisplay}
                    data={matTableData}
                    // data={tableDataFromAPI}
                    icons={tableIcons}
                    options={tableOptions}
                    editable={{
                        onRowDelete: (oldTableData) =>
                            new Promise((resolve, reject) => {
                                handleRowDelete(oldTableData, resolve, reject);
                            }),
                    }}
                    actions={[
                        {
                            icon: AddBox,
                            tooltip: "Add Row",
                            isFreeAction: true,
                            onClick: () => {
                                setDialogTitleText("Enter Motor & Feeder Details");
                                {
                                    draftActiveData.isDraftEnquiry
                                        ? handleDraftClickOpen()
                                        : handleClickOpen();
                                }
                            },
                        },
                        {
                            icon: () => <EditIcon />,
                            tooltip: "Edit Row",
                            onClick: (event, rowData) => {
                                console.log("Row Data", rowData);

                                handleEditDialogOpen(rowData);
                            },
                        },
                    ]}
                />
            </div>

            {/* Form Dialog */}
            <div className="infeedFormDialog">
                <Dialog
                    maxWidth="xl"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    disableEscapeKeyDown
                >
                    <div>
                        <DialogTitle id="responsive-dialog-title">
                            {dialogTitleText}
                        </DialogTitle>
                    </div>
                    <div style={{ position: "absolute", right: "1%" }}>
                        <Tooltip title="Close">
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <DialogContent>
                        {/* System Infeed Form Starts */}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={3}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel id="motorRating-label">
                                        Motor Rating (kW)
                  </InputLabel>
                                    <Select
                                        name="motorRating"
                                        label="demo-simple-select-helper-label"
                                        id="motorRating"
                                        value={motorRatingSelected}
                                        onChange={(e, id) => handleMotorRatingChange(e, id)}
                                    >
                                        <MenuItem value="" disabled>
                                            Motor Rating
                    </MenuItem>
                                        {motorRating.map((item, index) => (
                                            <MenuItem
                                                key={index}
                                                id={item.value}
                                                value={item.id}
                                                disabled={item.motorRating > 55 ? true : false}
                                            >
                                                {item.motorRating}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel id="efficiency-label">
                                        Efficiency Class
                  </InputLabel>
                                    <Select
                                        name="efficiencyClass"
                                        label="demo-simple-select-helper-label"
                                        id="efficiencyClass"
                                        value={efficiencyTypeSelected}
                                        onChange={handleEfficiencyFieldChange}
                                    >
                                        <MenuItem value="" disabled>
                                            Efficiency Type
                    </MenuItem>
                                        {efficiencyType.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>
                                                {item.efficiencyType}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <hr className="dashed"></hr>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    name="motorFLC"
                                    label="Motor FLC (A)"
                                    variant="filled"
                                    // type="number"
                                    InputProps={{ inputProps: { min: "0", step: "0.01" } }}
                                    value={isNaN(motorFLCUI) ? "" : motorFLCUI}
                                    onChange={handlemotorFLCFieldChange}
                                    onBlur={handlemotorFLCFieldBlur}
                                    id="motorFLC"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    name="motorLRR"
                                    // type="number"
                                    InputProps={{
                                        inputProps: { min: "1", max: "8.9", step: "0.1" },
                                    }}
                                    label="Motor Locked Rotor Ratio"
                                    variant="filled"
                                    value={isNaN(motorLRRUI) ? "" : motorLRRUI}
                                    onChange={handlemotorLRRFieldChange}
                                    onBlur={handlemotorLRRFieldBlur}
                                    id="motorLRR"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    name="motorEfficiency"
                                    // type="number"
                                    InputProps={{
                                        inputProps: { min: "0.1", max: "0.99", step: "0.01" },
                                    }}
                                    label="Motor Efficiency (&#951;)"
                                    variant="filled"
                                    value={isNaN(motorEfficiencyUI) ? "" : motorEfficiencyUI}
                                    onBlur={handlemotorEfficiencyFieldChangeBlur}
                                    onChange={handlemotorEfficiencyFieldChange}
                                    id="motorEfficiency"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    name="powerFactor"
                                    // type="number"
                                    InputProps={{
                                        inputProps: { min: "0.1", max: "0.99", step: "0.01" },
                                    }}
                                    label="Power Factor (cos &#934;)"
                                    variant="filled"
                                    value={powerFactorUI}
                                    onChange={handlePowerFactorChange}
                                    onBlur={handlePowerFactorChangeBlur}
                                    id="powerFactor"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    name="serviceFactor"
                                    // type="number"
                                    InputProps={{
                                        inputProps: { min: "1", max: "1.3", step: "0.01" },
                                    }}
                                    label="Motor Service Factor"
                                    variant="filled"
                                    value={serviceFactorUI}
                                    onChange={serviceFactorChange}
                                    onBlur={serviceFactorChangeBlur}
                                    id="serviceFactor"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    name="motorStartingTimeVal"
                                    // type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    label="Motor Starting Time (sec)"
                                    variant="filled"
                                    // value={motorStartingTimeVal}
                                    value={motorStartingTimeValUI}
                                    onChange={motorStartingTimeChange}
                                    onBlur={motorStartingTimeChangeBlur}
                                    id="motorStartingTimeVal"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    name="startingMethodClass"
                                    label="Trip Class"
                                    variant="filled"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={startingMethodClass}
                                    id="startingMethodClass"
                                />
                            </Grid>
                        </Grid>
                        <hr className="dashed"></hr>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={3}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel id="efficiency-label">Starting Method</InputLabel>
                                    <Select
                                        name="startingMethod"
                                        label="demo-simple-select-helper-label"
                                        id="startingMethod"
                                        value={startingMethodSelected}
                                        onChange={handleStartingMethodFieldChange}
                                    >
                                        <MenuItem value="" disabled>
                                            Starting Method
                    </MenuItem>
                                        {startingMethod.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>
                                                {item.method}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel id="efficiency-label">Feeder Type</InputLabel>
                                    <Select
                                        name="fuseType"
                                        label="demo-simple-select-helper-label"
                                        id="fuseType"
                                        value={fuseTypeSelected}
                                        onChange={handleFuseTypeFieldChange}
                                    >
                                        <MenuItem value="" disabled>
                                            Feeder Type
                    </MenuItem>
                                        {fuseType.map((item, index) => (
                                            <MenuItem
                                                key={index}
                                                value={item.id}
                                                disabled={item.fuseType == "Fuse" ? true : false}
                                            >
                                                {item.fuseType}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel id="efficiency-label">
                                        Types of Co-ordination
                  </InputLabel>
                                    <Select
                                        name="coordination"
                                        label="demo-simple-select-helper-label"
                                        id="coordination"
                                        value={coordinationSelected}
                                        onChange={handleCoordinationFieldChange}
                                    >
                                        <MenuItem value="" disabled>
                                            Types of Co-ordination
                    </MenuItem>
                                        {coordination.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>
                                                {item.coOrdinationType}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    name="coolingFanMotorCurrent"
                                    // type="number"
                                    label="Cooling Fan Motor Current (A)"
                                    variant="filled"
                                    value={coolingFanMotorCurrentUI}
                                    onChange={coolingFanMotorCurrentChange}
                                    onBlur={coolingFanMotorCurrentChangeBlur}
                                    id="coolingFanMotorCurrent"
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={3}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel id="efficiency-label">SCPD</InputLabel>
                                    <Select
                                        name="scpdFuseType"
                                        label="demo-simple-select-helper-label"
                                        id="scpdFuseType"
                                        value={scpdFuseTypeSelected}
                                        disabled={efficiencyTypeSelected == ""}
                                        onChange={handleScpdFuseTypeFieldChange}
                                    >
                                        <MenuItem value="" disabled>
                                            SCPD
                    </MenuItem>
                                        {fuseTypeSelected == 1
                                            ? scpdFuseType.map((item, index) => (
                                                <MenuItem key={index} value={item.id}>
                                                    {item.productType}
                                                </MenuItem>
                                            ))
                                            : scpdFuseLessType.map((item, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={item.id}
                                                    disabled={item.productType == "3RV" ? false : true}
                                                >
                                                    {item.productType}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel id="efficiency-label">Contactor</InputLabel>
                                    <Select
                                        name="contactor"
                                        label="demo-simple-select-helper-label"
                                        id="contactor"
                                        value={contactorSelected}
                                        onChange={handleContactorFieldChange}
                                        disabled={scpdFuseTypeSelected == ""}
                                    >
                                        <MenuItem value="" disabled>
                                            Contactor
                    </MenuItem>
                                        {userDetails.roleId == 2
                                            ? salesEngineerContactor.map((item, index) => (
                                                <MenuItem key={index} value={item.id}>
                                                    {item.productType}
                                                </MenuItem>
                                            ))
                                            : startingMethodClass == myConstClass.C20
                                                ? class20Contactor.map((item, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={item.id}
                                                    // disabled={
                                                    //   efficiencyTypeSelected == 3 &&
                                                    //   item.productType == "3TS"
                                                    //     ? true
                                                    //     : false
                                                    // }
                                                    >
                                                        {item.productType}
                                                    </MenuItem>
                                                ))
                                                : contactor.map((item, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={item.id}
                                                        disabled={
                                                            efficiencyTypeSelected == 3 &&
                                                                item.productType == "3TS"
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        {item.productType}
                                                    </MenuItem>
                                                ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel id="efficiency-label">OLPD</InputLabel>
                                    <Select
                                        name="overloadRelay"
                                        label="demo-simple-select-helper-label"
                                        id="overloadRelay"
                                        value={overloadRelaySelected}
                                        onChange={handleOverloadRelayFieldChange}
                                        disabled={
                                            scpdFuseTypeSelected == "" || contactorSelected == ""
                                                ? true
                                                : false
                                        }
                                    >
                                        <MenuItem value="" disabled>
                                            OLPD
                    </MenuItem>
                                        {startingMethodClass == myConstClass.C20
                                            ? startingMethodSelected == 3
                                                ? SDClass20OLR.map((item, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={item.id}
                                                        disabled={
                                                            contactorSelected == 2 &&
                                                                item.productType == "3RB"
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        {item.productType}
                                                    </MenuItem>
                                                ))
                                                : class20OLR.map((item, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={item.id}
                                                        disabled={
                                                            contactorSelected == 2 &&
                                                                item.productType == "3RB"
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        {item.productType}
                                                    </MenuItem>
                                                ))
                                            : overloadRelay.map((item, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={item.id}
                                                    disabled={
                                                        item.productType == "Integrated with SCPD" &&
                                                            startingMethodSelected == 3
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    {item.productType}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Clarify - Do we need to show this for all users ? Earlier it is
              only for Admin Users */}
                            {role !== 2 ? (
                                <Grid item xs={12} sm={4} md={3}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel id="efficiency-label">Selection</InputLabel>
                                        <Select
                                            name=""
                                            label="demo-simple-select-helper-label"
                                            id="enquiryType"
                                            value={enquiryType}
                                            disabled={overloadRelaySelected == ""}
                                            onChange={handleEnquiryTypeFieldChange}
                                        >
                                            <MenuItem value="" disabled>
                                                Selection
                      </MenuItem>

                                            {showBothEnqType &&
                                                enquiryTypeData.map((item, index) => (
                                                    <MenuItem key={index} value={item.id}>
                                                        {item.value}
                                                    </MenuItem>
                                                ))}
                                            {showOnlyTested &&
                                                enquiryTypeData.map((item, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={item.id}
                                                        disabled={item.value == 2 ? true : false}
                                                    >
                                                        {item.value}
                                                    </MenuItem>
                                                ))}
                                            {showOnlyRecommended &&
                                                enquiryTypeData.map((item, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={item.id}
                                                        disabled={item.id == 1 ? true : false}
                                                    >
                                                        {item.value}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            ) : (
                                    <></>
                                )}
                        </Grid>
                        {/* System Infeed Form Ends */}
                    </DialogContent>
                    <DialogActions>
                        <Grid container spacing={2} className="jc-center d-flex mt-1">
                            {/*{dialogTitleText === 'Enter System Infeed Details' ?*/}
                            {dialogTitleText === "Enter Motor & Feeder Details" ? (
                                <>
                                    <Grid item xs={10} sm={4} md={3}>
                                        <Button
                                            fullWidth
                                            onClick={handleSaveClick}
                                            className="pt-button--primary mt-1"
                                        >
                                            Save
                    </Button>
                                    </Grid>
                                    <Grid item xs={10} sm={4} md={3}>
                                        <Button
                                            fullWidth
                                            onClick={() => {
                                                handleClearDetails();
                                                setFixedMotorStartingTime(6);
                                            }}
                                            className="pt-button--secondary mt-1"
                                        >
                                            Clear
                    </Button>
                                    </Grid>
                                </>
                            ) : (
                                    <Grid container spacing={2} className="jc-center d-flex mt-1">
                                        <Grid item xs={10} sm={4} md={3}>
                                            <Button
                                                fullWidth
                                                onClick={handleUpdateClick}
                                                className="pt-button--primary mt-1"
                                            >
                                                Update
                    </Button>
                                        </Grid>
                                    </Grid>
                                )}
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>

            {/* Info Dialog  */}
            <div className="infeedFormDialog">
                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={infoDialogOpen}
                    onClose={handleInfoDialogClose}
                    aria-labelledby="responsive-dialog-title-info"
                    disableEscapeKeyDown
                >
                    <DialogContent>
                        <InfoIconDialog
                            selectedRowData={selectedRowData}
                            startMethod={startMethod}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Grid container spacing={2} className="jc-center d-flex mt-1">
                            <Grid item xs={10} sm={4} md={3}>
                                <Button
                                    fullWidth
                                    onClick={handleInfoDialogClose}
                                    className="pt-button--secondary mt-1"
                                >
                                    Close
                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>

            <SnackbarCustom
                open={snackOpen}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleSnackClose}
                classes={classes.snackbar}
            />

            {loading ? <Loading /> : <></>}
        </>
    );
}
